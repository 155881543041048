import React, { useState } from 'react'
import { CubitYesNoRadio } from 'common/cubit-yes-no-radio/cubit-yes-no-radio'
import { Grid, styled } from '@mui/material'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { tr } from 'translations/translate'
import { TEXT_HAVE_CASE_NUMBER, TEXT_CASE_NUMBER } from 'translations/keys'
import { Field } from 'redux-form'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { useSessionKey } from '../../app/app-selectors'

export const HAS_CASE_NUMBER = 'hasCaseNumber'
export const CASE_NUMBER = 'caseNumber'
const Wrapper = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}))
export const CaseNumberSelect: React.FC = () => {
    const [hasCaseNumber, setHasCaseNumber] = useState(false)
    const sessionKey = useSessionKey()
    const handleSelectionChange = (value: boolean): void => {
        setHasCaseNumber(value)
    }
    if (!sessionKey) return null
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
                <Wrapper container>
                    <Grid item xs={12}>
                        <CubitYesNoRadio
                            name={HAS_CASE_NUMBER}
                            label={tr(TEXT_HAVE_CASE_NUMBER)}
                            onChange={(value) => handleSelectionChange(value)}
                            validate={ValidatorRequired}
                        />
                    </Grid>
                    {hasCaseNumber && (
                        <Grid item xs={12}>
                            <Field
                                name={CASE_NUMBER}
                                label={tr(TEXT_CASE_NUMBER)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={ValidatorRequired}
                                autoComplete="off"
                                required
                            />
                        </Grid>
                    )}
                </Wrapper>
            </Grid>
        </Grid>
    )
}
