import {Audit, AuditStatus, AuditType} from "./audit";
import {tr} from "../../translations/translate";


export const getAuditType = (audit:Audit) => {
    switch (audit.type) {
        case AuditType.FIRE_OWNER : {
            return tr(AuditType.FIRE_OWNER)
        }
        case AuditType.FIRE_USER : {
            return tr(AuditType.FIRE_USER)
        }
        case AuditType.MAPPING : {
            return tr(AuditType.MAPPING)
        }
        case AuditType.REVISION : {
            return tr(AuditType.REVISION)
        }
        case AuditType.EL_REVISION : {
            return tr(AuditType.EL_REVISION)
        }
        case AuditType.OTHER_CASE : {
            return tr(AuditType.OTHER_CASE)
        }
        default:
            return audit.type
    }
}

export const getAuditStatus = (audit:Audit) => {
    switch (audit.status) {
        case AuditStatus.ACTIVE : {
            return tr(AuditStatus.ACTIVE)
        }
        case AuditStatus.APPROVED : {
            return tr(AuditStatus.APPROVED)
        }
        default:
            return audit.status
    }
}
