import React from 'react'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { tr } from 'translations/translate'
import {
    TEXT_PRODUCT_NAME,
    TEXT_PRODUCT_NAME_IS_THE_MAKE_AND_MODEL_OF_THE_FIREPLACE,
    TEXT_WOOD,
    TEXT_LIQUID_AND_GAS,
    TEXT_FUEL_TYPE,
    TEXT_INSTALLATION_DATE, TEXT_FUEL_TYPE_BIO_OIL,
} from 'translations/keys'
import { Grid } from '@mui/material'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import CubitDatePickerAdapter from 'common/cubit-inputs/react-form-adapters/cubit-date-picker-adapter'
import { FireplaceInstallationFormFieldName } from './fireplace-installation-form-field-name.enum'
import { FireFormName } from 'fire/enums/form-name.enum'
import {FuelType} from "../scrap-value/fuel-type.enum";

const Form: React.FC<InjectedFormProps> = props => {
    const { handleSubmit } = props

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={12} md={6}>
                    <Field
                        name={FireplaceInstallationFormFieldName.ProductName}
                        label={tr(TEXT_PRODUCT_NAME)}
                        component={CubitTextFieldAdapter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        validate={ValidatorRequired}
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    {tr(TEXT_PRODUCT_NAME_IS_THE_MAKE_AND_MODEL_OF_THE_FIREPLACE)}
                </Grid>

                <Grid item xs={12}>
                    <Field
                        name={FireplaceInstallationFormFieldName.FuelType}
                        label={tr(TEXT_FUEL_TYPE)}
                        component={CubitRadioGroupAdapter}
                        options={[
                            {
                                label: tr(TEXT_WOOD),
                                value: FuelType.Wood,
                            },
                            {
                                label: tr(TEXT_LIQUID_AND_GAS),
                                value: FuelType.LiquidAndGas,
                            },
                            {
                                label: tr(TEXT_FUEL_TYPE_BIO_OIL),
                                value: FuelType.BioOil,
                            }
                        ]}
                        validate={ValidatorRequired}
                    />
                </Grid>

                <Grid item>
                    <Field
                        name={FireplaceInstallationFormFieldName.InstallationDate}
                        component={CubitDatePickerAdapter}
                        label={tr(TEXT_INSTALLATION_DATE)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        validate={ValidatorRequired}
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export const FireplaceInstallationForm2 = reduxForm({
    form: FireFormName.FireplaceInstallation,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
