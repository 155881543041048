import React from 'react'
import { ButtonOutlined } from 'common/button-outlined/button-outlined'
import { tr } from 'translations/translate'
import { TEXT_UPLOAD_DOCUMENTATION, TEXT_UPLOAD } from 'translations/keys'
import Dropzone from 'react-dropzone'
import { Box, Grid, FormControl, FormGroup } from '@mui/material'
import { FilePreview } from './file-preview'
import { arrayRemoveAtIndex } from 'utils'

type DropzoneAdapterProps = { acceptedFiles: string; buttonTextKey?: string }
export const DropzoneAdapter: React.FC<DropzoneAdapterProps> = (props: any) => {
    const {
        input: { name, value, onChange },
        acceptedFiles,
        buttonTextKey,
    } = props

    const files: File[] = value

    const handleFileRemove = (index: number): void => {
        onChange(arrayRemoveAtIndex(files, index))
    }

    const uploadButtonText = buttonTextKey
        ? `${tr(TEXT_UPLOAD)} ${tr(buttonTextKey).toLowerCase()}`
        : tr(TEXT_UPLOAD_DOCUMENTATION)

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            {files &&
                Array.isArray(files) &&
                files.map((file, i) => (
                    <Grid item xs={12} key={i}>
                        <FilePreview index={i} file={file} onRemove={handleFileRemove} />
                    </Grid>
                ))}

            <Grid item>
                <FormControl>
                    <FormGroup row>
                        <Dropzone
                            accept={acceptedFiles}
                            onDrop={(files: any) => {
                                onChange(files)
                            }}
                        >
                            {({ getRootProps, getInputProps }) => {
                                return (
                                    <Box {...getRootProps()}>
                                        <ButtonOutlined color="primary" style={{ minWidth: '230px' }}>
                                            <input {...getInputProps()} name={name} />
                                            {uploadButtonText}
                                        </ButtonOutlined>
                                    </Box>
                                )
                            }}
                        </Dropzone>
                    </FormGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}
