import React from 'react'
import { DateTime } from 'luxon'
import { FormControl, TextField, TextFieldProps } from '@mui/material'
import { InputType } from '../input-type.enum'
import { DateTimePicker } from '@mui/x-date-pickers'

export type CubitDateTimePickerAdapterProps = { type: InputType.DateRange | InputType.Date }

const CubitDateTimePickerAdapter: React.FC<CubitDateTimePickerAdapterProps> = (props: any) => {
    const { id, name, input, field, label, style, variant, meta, ...rest } = props

    const inputFormat = 'dd.MM.yyyy HH:mm'
    const value = input.value || null
    const touched = meta.touched
    const errorText = meta.error

    return (
        <FormControl component="fieldset" style={style}>
            <DateTimePicker
                id={id || name || label}
                margin="none"
                label={label}
                placeholder={label}
                autoOk
                renderInput={(props: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...props} />}
                inputFormat={inputFormat}
                inputVariant={variant}
                value={value ? new Date(value) : null}
                onChange={(date: any) => input.onChange(date?.toString())}
                error={touched && errorText && errorText.length > 0}
                helperText={touched && errorText && errorText.length > 0 ? errorText : null}
                invalidDateMessage="Ugyldig datoformat"
                {...rest}
            />
        </FormControl>
    )
}

export default CubitDateTimePickerAdapter
