import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import { tr } from 'translations/translate'
import {useSelector} from "react-redux";
import {AppState} from "../app/app-store";
import {actionStoreTenantId} from "../app/app-actions";

// Use this component as child of react-router-dom to dynamically update document title
const Title = (props: any) => {
  const data = props.data || []
  const tenantId = useSelector((state:AppState) => state.appData.tenantId);
  const tenantData = useSelector((state:AppState) => state.appData.tenantData);
  const { pathname } = useLocation() || {}


  const current = data.find((p: any) => p.route === pathname)

  const { name } = tenantData || { name: 'Skjema' }
  const title = current && tr(current.labelTranslationKey) || name
  useEffect(() => {
      document.title = title
  }, [title])

  document.title = title
  return null
}

export default Title
