import React from 'react'
import { Grid } from '@mui/material'
import { InputLikeValue } from 'common/input-like-value/input-like-value'
import { tr } from 'translations/translate'
import { TEXT_ADDRESS, TEXT_ZIP_CODE } from 'translations/keys'
import { HousingUnitAutocompleteObject } from 'fire/types/housing-unit-autocomplete-object.type'
import { getHousingUnitNumber } from 'utils'

export const HousingUnitInfo: React.FC<{ housingUnitObject: HousingUnitAutocompleteObject | null }> = props => {
    const { housingUnitObject } = props

    return (
        housingUnitObject && (
            <Grid container spacing={4}>
                <Grid item>
                    <InputLikeValue
                        upperValue="GNR/BNR"
                        lowerValue={getHousingUnitNumber(housingUnitObject.cadastreUnit)}
                    />
                </Grid>
                <Grid item>
                    <InputLikeValue
                        upperValue={tr(TEXT_ADDRESS)}
                        lowerValue={housingUnitObject.housingUnit.address.addressText}
                    />
                </Grid>
                <Grid item>
                    <InputLikeValue
                        upperValue={tr(TEXT_ZIP_CODE)}
                        lowerValue={housingUnitObject.housingUnit.address.postalCode}
                    />
                </Grid>
            </Grid>
        )
    )
}
