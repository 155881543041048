import React, { useEffect } from 'react'
import { TEXT_INSTALLATION_LOCATION, TEXT_THE_NEW_FIREPLACE, TEXT_THE_INSTALLATION } from 'translations/keys'
import { CubitStepper } from 'common/cubit-stepper/cubit-stepper'
import { CubitStepperStep } from 'common/cubit-stepper/cubit-stepper-step.type'
import { useDispatch } from 'react-redux'
import { actionFireSubmitForm, actionFireClearCadastreUnit } from 'fire/fire-actions'
import { FireplaceInstallationForm1 } from './fireplace-installation-form-1'
import { startSubmit } from 'redux-form'
import { FireplaceInstallationForm2 } from './fireplace-installation-form-2'
import { FireplaceInstallationForm3 } from './fireplace-installation-form-3'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'

export const FireplaceInstallation: React.FC<FireFormPageProps> = (props) => {
    const dispatch = useDispatch()
    const { formDescriptor } = props

    useEffect(() => {
        return () => {
            dispatch(actionFireClearCadastreUnit())
        }
    }, [dispatch])

    const handleSubmit = (formValue: any) => {
        console.warn({ formValue })

        dispatch(startSubmit(formDescriptor.name))
        dispatch(actionFireSubmitForm(formDescriptor.name, formValue))
    }

    const fireplaceInstallationSteps: CubitStepperStep[] = [
        {
            stepLabelTranslationKey: TEXT_INSTALLATION_LOCATION,
            component: <FireplaceInstallationForm1 initialValues={{ type: formDescriptor.type }} />,
        },
        {
            stepLabelTranslationKey: TEXT_THE_NEW_FIREPLACE,
            component: <FireplaceInstallationForm2 />,
        },
        {
            stepLabelTranslationKey: TEXT_THE_INSTALLATION,
            component: <FireplaceInstallationForm3 onSubmit={handleSubmit} />,
        },
    ]

    return <CubitStepper formName={formDescriptor.name} steps={fireplaceInstallationSteps} />
}
