import React from 'react'
import { reduxForm, Field, formValueSelector, InjectedFormProps } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'

import { AppState } from 'app/app-store'
import { actionFireGetCadastreUnit } from 'fire/fire-actions'

import { tr } from 'translations/translate'
import * as k from 'translations/keys'

import { ConcernCaseFormFieldName as FieldName } from './form-field-name.enum'
import { FireFormName } from 'fire/enums/form-name.enum'
import {
    CubitTextFieldAdapter,
    CubitTextarea,
    CubitYesNoRadio,
    CubitGeosuggestAdapter,
    CubitFileUpload,
    ContentSectionHeading,
    ContentSectionContainer,
} from 'common'
import { ValidatorRequired } from 'utils/validators/validator-required'

import { Grid } from '@mui/material'
import { OtherFormName } from '../../enums/form-name.enum'
import { GridContainerOverride } from 'app/app'

const Row = ({ children }: any) => {
    return (
        <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={6}>
                {children}
            </Grid>
        </Grid>
    )
}

const TextField = (props: any) => {
    return (
        <Row>
            <Field
                name={props.name}
                label={props.label}
                placeholder={props.placeholder || ''}
                component={CubitTextFieldAdapter}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="filled"
            />
        </Row>
    )
}

const Form: React.FC<InjectedFormProps> = (props) => {
    const { handleSubmit } = props
    const dispatch = useDispatch()

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.ConcernCase)

    const isAnonymous = selector(state, FieldName.IsAnonymous)

    return (
        <form onSubmit={handleSubmit}>
            <CubitYesNoRadio
                name={FieldName.IsAnonymous}
                label={tr(k.TEXT_SEND_AS_ANONYMOUS)}
                validate={ValidatorRequired}
            />

            {isAnonymous === false && (
                <>
                    <Grid item xs={12}>
                        <Grid container spacing={4} style={GridContainerOverride}>
                            <Grid item xs={12}>
                                <TextField
                                    name={FieldName.SenderName}
                                    label={tr(k.TEXT_SENDER)}
                                    placeholder={tr(k.TEXT_SENDER)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4} style={GridContainerOverride}>
                            <Grid item xs={12}>
                                <TextField
                                    name={FieldName.SenderEmail}
                                    label={tr(k.TEXT_EMAIL)}
                                    placeholder={tr(k.TEXT_EMAIL)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4} style={GridContainerOverride}>
                            <Grid item xs={12}>
                                <TextField
                                    name={FieldName.SenderPhone}
                                    label={tr(k.TEXT_CONTACT_PHONE)}
                                    placeholder={tr(k.TEXT_CONTACT_PHONE)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}

            <Row>
                <Grid item xs={12}>
                    <Grid container spacing={4} style={GridContainerOverride}>
                        <Grid item xs={12}>
                            <Field
                                name={FieldName.Address}
                                label={tr(k.TEXT_SEARCH_BY_ADDRESS_OR_BUILDING)}
                                component={CubitGeosuggestAdapter}
                                validate={ValidatorRequired}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Row>

            <Row>
                <Grid item xs={12}>
                    <Grid container spacing={4} style={GridContainerOverride}>
                        <Grid item xs={12}>
                            <CubitTextarea
                                name={FieldName.Description}
                                label={tr(k.TEXT_CONCERN_DESCRIPTION)}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Row>

            <ContentSectionHeading variant="subtitle1">{tr(k.TEXT_FILES)}</ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload name={FieldName.Files} />
            </ContentSectionContainer>
        </form>
    )
}

export const ConcernCaseForm = reduxForm({
    form: OtherFormName.CreateConcern,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
