import React, { useEffect } from 'react'
import { reduxForm, formValueSelector, InjectedFormProps, Field } from 'redux-form'
import { tr } from 'translations/translate'
import {
    TEXT_WHAT_IS_THE_ADDRESS,
    TEXT_TANK_DID_LEAK,
    TEXT_TANK_PIPES_REMOVED,
    TEXT_TANK_REMOVAL_DOCUMENTATION,
    TEXT_DATE_FROM,
    TEXT_EXECUTIVE_COMPANY,
    TEXT_ADDRESS,
    TEXT_ZIP_CODE,
    TEXT_NAME,
    TEXT_BUSINESS,
    TEXT_NO_REGISTERED_TANKS,
} from 'translations/keys'
import { Grid } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'app/app-store'
import { find } from 'lodash'
import { HousingUnit } from 'tax/types/housing-unit.type'
import { HousingUnitSelect } from 'fire/form-components/housing-unit-select'
import { FormFieldName } from '../form-field-name.enum'
import { HousingUnitTankSelect } from 'fire/form-components/housing-unit-tank-select'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'
import { TankRemovalFormFieldName } from './tank-removal-form-field-name.enum'
import { CubitYesNoRadio } from 'common/cubit-yes-no-radio/cubit-yes-no-radio'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { FireFormName } from 'fire/enums/form-name.enum'
import { actionFireGetCadastreUnit } from 'fire/fire-actions'
import { useFireCadastreUnit } from 'fire/fire-selectors'
import { OwnerSelect } from '../../form-components/owner-select'
import { FireplaceInstallationFormFieldName } from '../fireplace-installation/fireplace-installation-form-field-name.enum'
import { CaseNumberSelect } from '../../form-components/case-number-select'
import CubitDatePickerAdapter from '../../../common/cubit-inputs/react-form-adapters/cubit-date-picker-adapter'
import { GridContainerOverride } from 'app/app'

const Form: React.FC<InjectedFormProps> = (props) => {
    const { handleSubmit } = props
    const dispatch = useDispatch()

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.TankRemoval)
    const ownerId = selector(state, TankRemovalFormFieldName.OwnerId)

    useEffect(() => {
        ownerId && dispatch(actionFireGetCadastreUnit(ownerId))
    }, [dispatch, ownerId])

    const cadastreUnit = useFireCadastreUnit()

    const housingUnits = cadastreUnit?.housingUnits ?? []
    const tanks = cadastreUnit?.tanks ?? []

    const selectedHousingUnitId: any = selector(state, FormFieldName.HousingUnitId)
    const selectedHousingUnit: HousingUnit | undefined = find(housingUnits, { id: selectedHousingUnitId })

    return (
        <form onSubmit={handleSubmit}>
            <CaseNumberSelect />
            <OwnerSelect name={FireplaceInstallationFormFieldName.OwnerId} formName={FireFormName.TankRemoval} />

            <Grid container spacing={4} style={GridContainerOverride}>
                <Grid item xs={12} sm={12} md={6}>
                    <HousingUnitSelect
                        disabled={!cadastreUnit || !housingUnits.length || !tanks.length}
                        housingUnits={housingUnits}
                        label={tr(TEXT_WHAT_IS_THE_ADDRESS)}
                    />
                </Grid>

                {selectedHousingUnit && tanks.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <HousingUnitTankSelect
                                housingUnit={selectedHousingUnit}
                                name={TankRemovalFormFieldName.TankId}
                                tanks={tanks}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CubitYesNoRadio
                                name={TankRemovalFormFieldName.DidLeak}
                                label={tr(TEXT_TANK_DID_LEAK)}
                                validate={ValidatorRequired}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CubitYesNoRadio
                                name={TankRemovalFormFieldName.PipesRemoved}
                                label={tr(TEXT_TANK_PIPES_REMOVED)}
                                validate={ValidatorRequired}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={4} alignItems="center">
                                <Grid item>
                                    <Field
                                        name={TankRemovalFormFieldName.RemovalDate}
                                        component={CubitDatePickerAdapter}
                                        label={tr(TEXT_DATE_FROM)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                        validate={ValidatorRequired}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name={TankRemovalFormFieldName.CompanyName}
                                        label={tr(TEXT_EXECUTIVE_COMPANY)}
                                        placeholder={`${tr(TEXT_NAME)} / ${tr(TEXT_BUSINESS)}`}
                                        component={CubitTextFieldAdapter}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name={TankRemovalFormFieldName.CompanyAddress}
                                        label={tr(TEXT_ADDRESS)}
                                        component={CubitTextFieldAdapter}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Field
                                        name={TankRemovalFormFieldName.CompanyZipCode}
                                        label={tr(TEXT_ZIP_CODE)}
                                        component={CubitTextFieldAdapter}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="filled"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            {tr(TEXT_TANK_REMOVAL_DOCUMENTATION)}
                        </Grid>

                        <Grid item xs={12}>
                            <CubitFileUpload name={TankRemovalFormFieldName.RemovalDocumentation} />
                        </Grid>
                    </>
                )}
            </Grid>

            {!!housingUnits.length && !tanks?.length && (
                <Grid container spacing={4} style={GridContainerOverride}>
                    <Grid item xs={12}>
                        {tr(TEXT_NO_REGISTERED_TANKS)}
                    </Grid>
                </Grid>
            )}
        </form>
    )
}

export const TankRemovalForm = reduxForm({
    form: FireFormName.TankRemoval,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
