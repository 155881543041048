import React from 'react'
import { Grid } from '@mui/material'
import { Company } from 'tax/types/company.type'
import { InputLikeValue } from 'common/input-like-value/input-like-value'
import {tr} from "../../translations/translate";
import {TEXT_ADDRESS, TEXT_NAME, TEXT_ZIP_CODE} from "../../translations/keys";
export const getCompanyFullAddress = (company:Company):string => {
   return  `${company.postalAddress.addressText ?? company.visitingAddress.addressText}, ${company.postalAddress.postalCode ?? company.visitingAddress.postalCode}`
}
export const CompanyInfo: React.FC<{ company: Company | null }> = props => {
    const { company } = props

    return (
        company && (
            <Grid container spacing={4}>
                <Grid item>
                    <InputLikeValue upperValue={tr(TEXT_NAME)} lowerValue={company.name} />
                </Grid>
                <Grid item>
                    <InputLikeValue upperValue={tr(TEXT_ADDRESS)} lowerValue={company.postalAddress.addressText ?? company.visitingAddress.addressText} />
                </Grid>
                <Grid item>
                    <InputLikeValue upperValue={tr(TEXT_ZIP_CODE)} lowerValue={company.postalAddress.postalCode ?? company.visitingAddress.postalCode} />
                </Grid>
            </Grid>
        )
    )
}
