import React from 'react'
import classes from './layout-default.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../app/app-store'
import { actionSetTenantSelectorVisible } from '../../app/app-actions'
import { Grid, Link, styled } from '@mui/material'

const ChildrenWrapper = styled(Grid)(({ theme }) => ({
    maxWidth: '960px',
    width: '100%',
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
    },
}))

type LayoutComponentProps = {
    logo: React.ReactElement
    rightElement?: React.ReactElement
    maxWidth?: string
}

const LayoutDefault: React.FC<LayoutComponentProps> = (props) => {
    const { logo, rightElement, maxWidth = '960px', children } = props
    const dispatch = useDispatch()
    const stateTenantId = useSelector((state: AppState) => state.appData.tenantId)
    const sessionKey = useSelector((state: AppState) => state.appData.sessionKey)
    return (
        <>
            <Grid container justifyContent="center" className={classes.headerContainer}>
                <Grid item style={{ maxWidth }} className={classes.headerContent}>
                    <Grid container alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item className={classes.headerLogo}>
                            {logo}
                        </Grid>
                        {rightElement && (
                            <Grid item>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {stateTenantId && !sessionKey && (
                                        <div style={{ marginRight: 16 }}>
                                            <Link
                                                component="button"
                                                variant="body2"
                                                underline={'always'}
                                                onClick={() => {
                                                    dispatch(actionSetTenantSelectorVisible(true))
                                                }}
                                            >
                                                Endre kommune
                                            </Link>
                                        </div>
                                    )}
                                    {rightElement}
                                </div>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <ChildrenWrapper>{children}</ChildrenWrapper>
            </Grid>
        </>
    )
}

export default LayoutDefault
