import React, { useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, LinearProgress, styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../app/app-store'
import { actionLoadEnabledTenants, actionSetTenantSelectorVisible, actionStoreTenantId } from '../../app/app-actions'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

const SaveButton = styled(Button)(({ theme }) => ({
    marginRight: 16,
}))

const ButtonDiv = styled('div')(({ theme }) => ({
    marginRight: 16,
}))
export const TenantSelector = () => {
    const [selectedTenant, setSelectedTenant] = React.useState<any>(null)
    const dispatch = useDispatch()
    const stateTenantId = useSelector((state: AppState) => state.appData.tenantId)
    const tenantSelectorVisible = useSelector((state: AppState) => state.appData.tenantSelectorVisible)
    const enabledTenants = useSelector((state: AppState) => state.appData.enabledTenants)

    const isOpen =
        tenantSelectorVisible || stateTenantId === undefined || stateTenantId === null || stateTenantId === ''
    const handleClose = () => {
        dispatch(actionSetTenantSelectorVisible(false))
    }
    useEffect(() => {
        if (enabledTenants.length === 0) {
            dispatch(actionLoadEnabledTenants())
        }
    }, [dispatch, enabledTenants])
    const municipalitiesWithTenantInfo = enabledTenants.flatMap((tenant: any) =>
        tenant.municipalities.map((municipality: any) => ({
            municipalityName: municipality.name,
            municipalityCode: municipality.code,
            tenantName: tenant.name,
            tenantId: tenant.id,
        })),
    )
    return (
        <Dialog onClose={handleClose} open={isOpen} PaperProps={{ square: true }} fullWidth={true} maxWidth="sm">
            <DialogTitle>Velg kommunen din i nedtrekksmenyen under.</DialogTitle>
            <DialogContent>
                {enabledTenants && enabledTenants.length > 0 ? (
                    <Autocomplete
                        id="combo-box-demo"
                        onChange={(event, item) => {
                            setSelectedTenant(item.tenantId)
                        }}
                        disableClearable={true}
                        options={municipalitiesWithTenantInfo}
                        getOptionLabel={(option) => `${option.municipalityName}`}
                        fullWidth={true}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                ) : (
                    <LinearProgress />
                )}
                <ButtonDiv>
                    {selectedTenant && (
                        <SaveButton
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                dispatch(actionStoreTenantId(selectedTenant))
                                window.location.reload()
                            }}
                        >
                            Lagre
                        </SaveButton>
                    )}
                    {tenantSelectorVisible && (
                        <Button variant="text" onClick={handleClose}>
                            Avbryt
                        </Button>
                    )}
                </ButtonDiv>
            </DialogContent>
        </Dialog>
    )
}
