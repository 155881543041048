import React from 'react'
import { Field, WrappedFieldArrayProps } from 'redux-form'
import { Table, TableBody, TableHead, TableRow, TableCell, InputAdornment } from '@mui/material'
import { FormField } from 'common/cubit-inputs/cubit-form-field.types'
import { InputType } from 'common/cubit-inputs/input-type.enum'
import { CubitSelectAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-select-adapter'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { tr } from 'translations/translate'
import { TEXT_FLOOR, TEXT_USE, TEXT_AREA } from 'translations/keys'

// 3000     Garasje    GA
// 4000     Industri, p-hus    IN
// 7000     Næringsformål    NE
// 9000     Boligformål    BO
// 12000    Leiligheter    LE
// 9000     Fritidsformål    FR
// 750      Plasthall    PH
// 0        Takoverbygg    TB

const usage = (): FormField<InputType.Select> => ({
    type: InputType.Select,
    props: {
        name: '',
        label: '',
        component: CubitSelectAdapter,
        valueIsObject: false,
        options: [
            { label: 'Garasje', value: 'Garasje' },
            { label: 'Industri, p-hus', value: 'Industri, p-hus' },
            { label: 'Næringsformål', value: 'Næringsformål' },
            { label: 'Boligformål', value: 'Boligformål' },
            { label: 'Leiligheter', value: 'Leiligheter' },
            { label: 'Fritidsformål', value: 'Fritidsformål' },
            { label: 'Plasthall', value: 'Plasthall' },
            { label: 'Takoverbygg', value: 'Takoverbygg' },
        ],
    },
})

export const ComplaintsPageFormBuildingFloors: React.FC<WrappedFieldArrayProps> = props => {
    const { fields } = props

    return fields.length ? (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>{tr(TEXT_FLOOR)}</TableCell>
                    <TableCell>{tr(TEXT_USE)}</TableCell>
                    <TableCell>{tr(TEXT_AREA)}</TableCell>
                    <TableCell>{tr(TEXT_USE)}</TableCell>
                    <TableCell>{tr(TEXT_AREA)}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {fields.map((field, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCell>{fields.get(index).floorNumber}</TableCell>
                            <TableCell>{fields.get(index).usage}</TableCell>
                            <TableCell>{fields.get(index).area}</TableCell>
                            <TableCell>
                                <Field {...usage().props} name={`${field}.correctUsage`} variant="filled" />
                            </TableCell>
                            <TableCell>
                                <Field
                                    name={`${field}.correctArea`}
                                    component={CubitTextFieldAdapter}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">m²</InputAdornment>,
                                    }}
                                    style={{ minWidth: '120px' }}
                                    type="number"
                                    variant="filled"
                                />
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    ) : (
        <></>
    )
}
