import { Language } from 'common/enums/language.enum'

export const Config = {
    apiUrl: process.env.REACT_APP_API_URL,
    lanternUrl: process.env.REACT_APP_LANTERN_URL,
    plantsUrl: process.env.REACT_APP_PLANTS_URL,
    // auditsUrl: process.env.REACT_APP_AUDIT_URL,
    auditsUrl: 'http://localhost:56319/api',
    companiesUrl: process.env.REACT_APP_COMPANIES_URL,
    sessionKey: process.env.REACT_APP_SESSION_KEY,
    language: Language.Norwegian,
    localStorageLangKey: 'LANGUAGE',
}
