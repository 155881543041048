import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { FireFormName } from 'fire/enums/form-name.enum'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import {
    TEXT_HEREBY_CONFIRMED,
    TEXT_DATE,
    TEXT_LAST_INTERNAL_CONTROL_DATE,
    TEXT_PYRO_SALE_15,
    TEXT_PYRO_SALE_8,
    TEXT_PYRO_SALE_9,
    TEXT_PYRO_SALE_10,
    TEXT_PYRO_SALE_11,
    TEXT_PYRO_SALE_12,
    TEXT_PYRO_SALE_13,
    TEXT_PYRO_SALE_14,
} from 'translations/keys'
import { tr } from 'translations/translate'
import { ContentSectionContainer } from 'common/content-section-container/content-section-container'
import { PyrotechnicsSaleFormFieldName } from './pyrotechnics-sale-form-field-name.enum'
import { CubitCheckboxGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-checkbox-group-adapter'
import { ValidatorRequired } from 'utils/validators/validator-required'
import CubitDatePickerAdapter from 'common/cubit-inputs/react-form-adapters/cubit-date-picker-adapter'
import { Grid } from '@mui/material'

const Form: React.FC<InjectedFormProps<any>> = () => {
    const fireHydrantChecklistOptions = [
        {
            label: tr(TEXT_PYRO_SALE_8),
            value: 0,
        },
        {
            label: tr(TEXT_PYRO_SALE_9),
            value: 1,
        },
        {
            label: tr(TEXT_PYRO_SALE_10),
            value: 2,
        },
        {
            label: tr(TEXT_PYRO_SALE_11),
            value: 3,
        },
        {
            label: tr(TEXT_PYRO_SALE_12),
            value: 4,
        },
        {
            label: tr(TEXT_PYRO_SALE_13),
            value: 5,
        },
        {
            label: tr(TEXT_PYRO_SALE_14),
            value: 6,
        },
        {
            label: tr(TEXT_PYRO_SALE_15),
            value: 7,
        },
    ]

    return (
        <form>
            <ContentSectionHeading variant="subtitle1"> {tr(TEXT_HEREBY_CONFIRMED)}:</ContentSectionHeading>

            <ContentSectionContainer>
                <Field
                    name={PyrotechnicsSaleFormFieldName.ConfirmationChecklist}
                    component={CubitCheckboxGroupAdapter}
                    valueIsObject={false}
                    row={false}
                    options={fireHydrantChecklistOptions}
                    autoFocus
                />
            </ContentSectionContainer>

            <ContentSectionHeading variant="subtitle2">{tr(TEXT_LAST_INTERNAL_CONTROL_DATE)}</ContentSectionHeading>

            <ContentSectionContainer>
                <Grid container>
                    <Grid item>
                        <Field
                            name={PyrotechnicsSaleFormFieldName.LastInternalControlDate}
                            component={CubitDatePickerAdapter}
                            label={tr(TEXT_DATE)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                            validate={ValidatorRequired}
                            required
                        />
                    </Grid>
                </Grid>
            </ContentSectionContainer>
        </form>
    )
}

export const PyrotechnicsSaleForm3 = reduxForm<{}>({
    form: FireFormName.PyrotechnicsSale,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
