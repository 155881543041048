import React from 'react'

import styles from '../cubit-checkbox/cubit-checkbox.module.css'

import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { InputType } from '../input-type.enum'

import classnames from 'classnames'

export type CubitCheckboxAdapterProps = { type: InputType.Checkbox }

export const CubitCheckboxAdapter: React.FC<CubitCheckboxAdapterProps> = (props: any) => {
    const { input, disabled } = props

    const renderCheckboxIcon = (isChecked: boolean, isDisabled: boolean): any => {
        return isChecked ? <CheckBoxIcon className={styles.checkboxSelected} /> : <CheckBoxOutlineBlankIcon />
    }

    return (
        <div
            className={classnames(styles.checkboxContainer, {
                [styles.checkboxDisabled]: disabled,
            })}
        >
            <span className={styles.checkbox}>{renderCheckboxIcon(input.value, disabled)}</span>
            <input
                checked={input.value}
                className={styles.checkboxInput}
                onChange={input.onChange}
                onClick={input.onClick}
                disabled={disabled}
                type="checkbox"
            />
        </div>
    )
}
