import React from 'react'
import {useLocation} from 'react-router-dom'
import {Button, Grid} from '@mui/material'
import {tr} from 'translations/translate'
import {TEXT_AUTHENTICATION_REQUIRED, TEXT_LOGIN_WITH_ID_PORTEN} from 'translations/keys'
import LockIcon from '@mui/icons-material/Lock'
import {lanternUrl} from "../common/services/http-service";
import {AppState} from "../app/app-store";
import {useSelector} from "react-redux";

const AUTOMATIC_LOGIN_REDIRECT = false

export const Authorization: React.FC = () => {
    const location = useLocation()
    const tenantId = useSelector((state:AppState) => state.appData.tenantId)
    const handleLogin = () => {
        //openid/loginOnBehalfOf?redirectUrl=https://innbygger.cubit.no/openid/5b5197fb394c3c43ac9d8215&tenant=5b5197fb394c3c43ac9d8215
        window.location.replace(lanternUrl('/openid/loginOnBehalfOf?redirectUrl='+window.location.origin + '/open-id-login?redirect=' + encodeURI(location.pathname) +`&tenant=${tenantId}`))
    }

    const LoginContainer = () => (
        <div style={{ padding: '16px' }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>
                    <LockIcon />
                </Grid>
                <Grid item>{tr(TEXT_AUTHENTICATION_REQUIRED)}</Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <Button onClick={handleLogin} variant="contained" color="primary">
                        {tr(TEXT_LOGIN_WITH_ID_PORTEN)}
                    </Button>
                </Grid>
            </Grid>
        </div>
    )

    if (AUTOMATIC_LOGIN_REDIRECT) {
        handleLogin()
        return null
    } else {
        return <LoginContainer />
    }
}
