import * as keys from '../keys'
import { AuditStatus, AuditType } from '../../common/audit/audit'
import { nb } from './nb'

export const en: { [index: string]: string } = {}

en[keys.TEXT_SUBMIT] = 'Submit'
en[keys.TEXT_COMMENT] = 'Comment'
en[keys.TEXT_COMMENT_DESCRIPTION] = 'Comment / desription'
en[keys.TEXT_DESCRIPTION] = 'Desription'
en[keys.TEXT_DESCRIBE_REASON_FOR_APPLICATION] = 'Describe the reason for the application'
en[keys.TEXT_UPLOAD] = 'Upload'
en[keys.TEXT_UPLOAD_DOCUMENTATION] = 'Upload documentation'
en[keys.TEXT_NEXT] = 'Next'
en[keys.TEXT_BACK] = 'Back'
en[keys.TEXT_CANCEL] = 'Cancel'
en[keys.TEXT_YES] = 'Yes'
en[keys.TEXT_NO] = 'No'
en[keys.TEXT_NAME] = 'Name'
en[keys.TEXT_BUSINESS] = 'Business'
en[keys.TEXT_COMPANY] = 'Company'
en[keys.TEXT_EXECUTIVE_COMPANY] = 'Executive company'
en[keys.TEXT_ADDRESS] = 'Address'
en[keys.TEXT_ZIP_CODE] = 'Zip code'
en[keys.TEXT_DATE_FROM] = 'Date from'
en[keys.TEXT_DATE_TO] = 'Date to'
en[keys.TEXT_DATE_TIME] = 'Time'
en[keys.TEXT_EMAIL] = 'Email'
en[keys.TEXT_NO_RESULTS] = 'No results'
en[keys.TEXT_LOADING] = 'Loading'
en[keys.TEXT_OWNER] = 'Owner'
en[keys.TEXT_REMOVE] = 'Remove'

//#region TAX
en[keys.TEXT_RESIDENTIAL_PROPERTY] = 'Residential property'
en[keys.TEXT_LEISURE_PROPERTY] = 'Leisure Property'
en[keys.TEXT_FARMING_FORESTRY_WITH_HOUSING] = 'Farming / forestry with housing'
en[keys.TEXT_FARM_USE_FORESTRY_WITH_LEISURE] = 'Farm use / forestry with leisure'
en[keys.TEXT_FARMS_OR_FORESTRY] = 'Farms or forestry'
en[keys.TEXT_NOT_SELECTED] = 'Not selected'
en[keys.TEXT_COMBINED_BUILT_PLOT] = 'Combined built plot'
en[keys.TEXT_COMMERCIAL] = 'Commercial'
en[keys.TEXT_UNDEVELOPED_PLOT_HOUSING] = 'Undeveloped plot, housing'
en[keys.TEXT_UNDEVELOPED_PLOT_LEISURE] = 'Undeveloped plot, leisure'
en[keys.TEXT_UNDEVELOPED_PLOT_INDUSTRY] = 'Undeveloped plot, industry'
en[keys.TEXT_UNDEVELOPED_PLOT] = 'Undeveloped plot'
en[keys.TEXT_CORRECTION_OF_FACTS_AND_SUBMISSION_OF_APPEAL_AGAINST_PROPERTY_TAX] =
    'Correction of facts and submission of appeal against property tax'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_1] = 'Plot area is not correct'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_2] = 'Plot type is not correct'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_3] = 'The plot and buildings have been given an unreasonably good location'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_4] = 'Building has reached an unreasonably high standard'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_5] = 'Incorrect area of building'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_6] = 'Photos are from wrong property'
en[keys.TEXT_TAX_COMPLAINT_CAUSE_7] = 'Other corrections/complaints'
en[keys.TEXT_REGISTERED_SITE_USE] = 'Registered site use'
en[keys.TEXT_PROPER_SITE_USE] = 'Proper site use'
en[keys.TEXT_REGISTERED_AREA] = 'Registered area'
en[keys.TEXT_CORRECT_AREA] = 'Correct area'
en[keys.TEXT_CHOOSE_BUILDING] = 'Choose building'
en[keys.TEXT_ADD_BUILDING] = 'Add building'
en[keys.TEXT_LOOK_AT_MAP] = 'Look at map'
en[keys.TEXT_SELECT_PHOTOS_THAT_ARE_FROM_WRONG_PROPERTY] = 'Select photos that are from wrong property'
en[keys.TEXT_FLOOR] = 'Floor'
en[keys.TEXT_USE] = 'Use'
en[keys.TEXT_AREA] = 'Area'
en[keys.TEXT_REQUIRED] = 'Required'
en[keys.TEXT_VALID_EMAIL] = 'Valid E-mail required'
en[keys.TEXT_VALID_PHONE] = 'Valid Norwegian Phone Number required'
//#endregion

//#region FIRE
en[keys.TEXT_FIREPLACE_INSTALLATION_NOTICE] = 'Fireplace installation notification'
en[keys.TEXT_FIREPLACE_REMOVAL_NOTICE] = 'Fireplace removal notification'
en[keys.TEXT_APPLICATION_FOR_SCRAP_VALUE] = 'Application for scrap value'
en[keys.TEXT_NOTICE_OF_REHABILITATION_NEW_CHIMNEY] = 'Notification of rehabilitation of chimney / new chimney'
en[keys.TEXT_APPLICATION_FOR_TANK_REMOVAL_EXEMPTION] = 'Application for an oil tank removal exemption'
en[keys.TEXT_NOTICE_OF_TANK_REMOVAL] = 'Notification of oil tank removal'
en[keys.TEXT_NOTICE_OF_ACCOMMODATION] = 'Notification of accommodation'
en[keys.TEXT_NOTICE_OF_EVENT] = 'Notification of event'
en[keys.TEXT_APPLICATION_FOR_USE_OF_PYROTECHNICS] = 'Application for use of pyrotechnics'
en[keys.TEXT_APPLICATION_FOR_SALE_OF_PYROTECHNICS] = 'Application for sale of pyrotechnics'
en[keys.TEXT_APPLICATION_FOR_STORAGE_OF_SMOKELESS_GUNPOWDER] = 'Application for storage of smokeless gunpowder'
en[keys.TEXT_APPLICATION_FOR_CONTROL_OF_FIREHYDRANT] = 'Application for control of fire hydrant'
en[keys.TEXT_INSTALLATION_LOCATION] = 'Installation location'
en[keys.TEXT_REMOVAL_LOCATION] = 'Removal location'
en[keys.TEXT_THE_NEW_FIREPLACE] = 'The new fireplace'
en[keys.TEXT_THE_INSTALLATION] = 'The installation'
en[keys.TEXT_PRODUCT_NAME] = 'Product name'
en[keys.TEXT_PRODUCT_NAME_IS_THE_MAKE_AND_MODEL_OF_THE_FIREPLACE] =
    'Product name is the make and model of the fireplace'
en[keys.TEXT_FUEL_TYPE] = 'Fuel type'
en[keys.TEXT_WOOD] = 'Wood'
en[keys.TEXT_INSTALLATION_DATE] = 'Installation date'
en[keys.TEXT_WHICH_ADDRESS_APPLIES_TO_THE_INSTALLATION_SITE] = 'Which address applies to the installation site?'
en[keys.TEXT_ADDRESS_WHERE_FIREPLACE_IS_REMOVED] = 'Address where fireplace is removed'
en[keys.TEXT_WHICH_CHIMNEY_IS_THE_FIREPLACE_ASSOCIATED_WITH] = 'Which chimney is the fireplace associated with?'
en[keys.TEXT_ASSOCIATED_FIREPLACE] = 'Associated fireplace'
en[keys.TEXT_OTHER_CHIMNEY_UNCERTAIN] = 'Other chimney / Uncertain'
en[keys.TEXT_ARE_FIREPLACES_REMOVED] = 'Are fireplaces removed?'
en[keys.TEXT_HAVE_YOU_INSTALLED_THE_FIREPLACE_YOURSELF] = 'Have you installed the fireplace yourself?'
en[keys.TEXT_NEW_FIREPLACE_INSTALLATION_2] =
    'Is the fireplace approved for use in Norway and installed in accordance with current regulations?'
en[keys.TEXT_CHIMNEY] = 'Chimney'
en[keys.TEXT_FIREPLACE_NAME] = 'Fireplace name'
en[keys.TEXT_CHIMNEY_ASSOCIATION_TEXT] = 'Describe to which chimney the fireplace is associated with'
en[keys.TEXT_LIQUID_AND_GAS] = 'Liquid and gas'

en[keys.TEXT_WE_HAVE_FOLLOWING_FIREPLACES] = 'We have the following fireplaces registered at your address:'
en[keys.TEXT_WHICH_FIREPLACE_IS_REMOVED] = 'Which fireplace is removed?'
en[keys.TEXT_ASSOCIATED_CHIMNEY] = 'Associated chimney'
en[keys.TEXT_WHICH_FIREPLACE_APPLIES_TO_WRECKAGE] = 'Which fireplace applies to the wreckage?'
en[keys.TEXT_NEW_HEATING_SOURCE] = 'New heating source'
en[keys.TEXT_DOCUMENTATION] = 'Documentation'
en[keys.TEXT_SELECT_THE_HEATING_SOURCE] = 'Select the heating source'

en[keys.TEXT_CLEAN_BURNING_FIREPLACE] = 'Clean-burning fireplace'
en[keys.TEXT_HEAT_PUMP] = 'Heat pump'
en[keys.TEXT_SOLAR] = 'Solar'
en[keys.TEXT_DISTRICT_HEATING] = 'District heating'

en[keys.TEXT_INSTALLER] = 'Installer'
en[keys.TEXT_MOBILE_NUMBER] = 'Mobile number'
en[keys.TEXT_INSTALLERS_ADDRESS] = "Installer's address"
en[keys.TEXT_NORWEGIAN_ADDRESS] = 'Norwegian address'
en[keys.TEXT_FOREIGN_ADDRESS] = 'Foreign address'

en[keys.TEXT_PROOF_OF_REFUND] = 'Proof of refund'
en[keys.TEXT_CHECKLIST_AND_VERIFICATION_STATEMENT_BY_QUALIFIED_INSPECTOR] =
    'Checklist and verification statement by qualified inspector'

en[keys.TEXT_LOCATION] = 'Location'
en[keys.TEXT_ABOUT_EVENT] = 'About the event'
en[keys.TEXT_RISK_ASSESSMENT] = 'Risk assessment'
en[
    keys.TEXT_EVENT_1
] = `In the event of a large event / event with increased risk, it is necessary to implement measures for preventive and emergency preparedness.`
en[keys.TEXT_EVENT_1_2] = `
If the event is held somewhere that is not normally used for this type of event, the form must be submitted at least 4 weeks prior to the event.`
en[
    keys.TEXT_EVENT_2
] = `The announcer will be the contact person for Bergen Fire Department in connection with the event`
en[keys.TEXT_EVENT_2_1] = `The announcer will be the contact person for`
en[keys.TEXT_EVENT_2_3] = `Department in connection with the event`
en[keys.TEXT_ORGANIZER] = 'Organizer'
en[keys.TEXT_COMPANY_NAME] = 'Company name'
en[keys.TEXT_SEARCH_BY_ADDRESS_OR_BUILDING] = 'Search by address or building name'
en[keys.TEXT_DESCRIBE_LOCATION] = 'Describe location'
en[keys.TEXT_DESCRIBE_ARRANGEMENT] = 'Describe arrangement / type of arrangement'
en[keys.TEXT_IS_PYROTECHNICS_WILL_BE_USED] = 'Should stage smoke / pyrotechnic effects be used?'
en[keys.TEXT_IS_FIRE_SERVICE_SAFEGUARDED] = 'Is accessibility to the fire service safeguarded?'
en[keys.TEXT_EVENT_3] =
    // eslint-disable-next-line no-template-curly-in-string
    'Shutdown of the fire alarm system is NOT allowed. If pyrotechnic effects are to be used, an application must be sought for ${tenantName}.'
en[keys.TEXT_APPLICATION_FOR_FIREWORKS_PYROTECHNICS] = 'Application for use of fireworks / pyrotechnic effects'
en[keys.TEXT_RISK_ASSESSMENT_FOR_EVENT] = 'Risk assessment for the event'

en[keys.TEXT_ACCOMMODATION] = 'Accommodation'
en[keys.TEXT_ABOUT_ACCOMMODATION] = 'About the accommodation'
en[keys.TEXT_FIRE_SAFETY] = 'Fire safety'
en[keys.TEXT_IS_SOMEONE_RESPONSIBLE_FOR_REPORTING] = 'Is someone else in charge responsible for reporting?'
en[keys.TEXT_NUMBER_OF_PEOPLE] = 'Number of people'
en[keys.TEXT_ACCOMMODATION_1] =
    'Describe accommodation: Background for accommodation, areas (room designation and floor) used'
en[keys.TEXT_ACCOMMODATION_2] =
    'Are there written plans and instructions in place to safeguard all the security of the accommodation?'

en[keys.TEXT_ACCOMMODATION_CONFIRM] = 'I confirm that the following will be in place before the night:'
en[keys.TEXT_OWNER_CONSENT] = 'The owner of the building is informed and has given consent'
en[keys.TEXT_RESPONSIBLE_CONSENT] = 'Responsible people know about the activity and have given consent'
en[keys.TEXT_RESPONSIBLE_CAN_FIREGUARD] =
    'The person responsible is able to take care of the fire safety of the accommodation'
en[keys.TEXT_AWAKE_NIGHT_WATCH] = 'Awake night watch'
en[keys.TEXT_SUFFICIENT_NUMBER_OF_GUARDS] = 'Sufficient number of guards'
en[keys.TEXT_NO_FLAMMABLE_ACTIVITY] = 'Flammable activity must not occur'
en[keys.TEXT_EVERYONE_KNOWS_ESCAPE_ROUTES] = 'Everyone must know the escape routes before lodging'
en[keys.TEXT_ESCAPE_ROUTES_TIDY_AND_ACCESSIBLE] = 'Escape routes must be tidy and easily accessible'
en[keys.TEXT_FIRE_ALARM_SYSTEMS_CHECKED_AND_FUNCTIONING] =
    'Fire alarm systems must be controlled and functioning. (If there are smoke detectors these must be checked)'
en[keys.TEXT_EXTINGUISHING_EQUIPMENT_CHECKED_AND_FUNCTIONING] =
    'Extinguishing equipment must be controlled and functioning'
en[keys.TEXT_CONTROL_SYSTEM_CHECKED_AND_FUNCTIONING] = 'Control system must be controlled and functioning'
en[keys.TEXT_SPRINKLER_SYSTEMS_CHECKED_AND_FUNCTIONING] =
    'Sprinkler systems must be controlled and functioning if fitted'
en[keys.TEXT_ACCOMMODATION_LIMITED_TO_AGREED_BUILDING_PART] =
    'Accommodation is limited to an agreed part of the building'

en[keys.TEXT_APPLICATION_RECEIVED] = 'The application has been received'
en[keys.TEXT_MESSAGE_RECEIVED] = 'The message has been received'
en[keys.TEXT_FIREPLACE_REMOVED] = 'Fireplace removed'
en[keys.TEXT_APPLY_FOR_PYROTECHNICS] = 'Here you can apply for the use of pyrotechnics'
en[keys.TEXT_FIREPLACE_INSTALL_SUCCESS_1] =
    'Information about a new fireplace will be taken up as soon as the message is processed.'
en[keys.TEXT_ACCOMMODATION_SUCCESS_1] =
    'If we need more information to ensure fire safety during the night, we will contact you for further follow-up'
en[keys.TEXT_EVENT_SUCCESS_1] =
    'If we need more information to safeguard fire safety during the event, we will contact you for further follow-up'
en[keys.TEXT_SUCCESS_1] = 'You will receive feedback on the application as soon as it is processed.'
en[keys.TEXT_SUCCESS_2] = 'If we need more information to process it, we will contact you.'
en[keys.TEXT_THANK_YOU_FOR_USING_OUR_SERVICES] = 'Thank you for using our digital services'

en[keys.TEXT_WHAT_IS_THE_ADDRESS] = 'What is the address'
en[keys.TEXT_ACCOUNT_NUMBER] = 'Account number'
en[keys.TEXT_PAYOUT_ACCOUNT] = 'Payout account'

en[keys.TEXT_TYPE] = 'Type'
en[keys.TEXT_CONTENTS] = 'Contents'
en[keys.TEXT_VOLUME] = 'Volume'

en[keys.TEXT_TANK_EXEMPTION_1] =
    'Only oil tanks where it is disproportionately complicated and / or disproportionately expensive to remove can apply for an exemption. It is therefore important that you double the reason for the application.'
en[keys.TEXT_TANK_EXEMPTION_2] = 'Documentation of cause. For example, images of the affected area'
en[keys.TEXT_TANK_EXEMPTION_REASON] = 'Reason for application for exemption from oil tank removal'

en[keys.TEXT_TANK_DID_LEAK] =
    'Did any traces of oil, odor or anything else indicate leakage from the tank during excavation?'
en[keys.TEXT_TANK_PIPES_REMOVED] =
    'All pipes attached to the tank are removed or rendered useless so that unintentional filling cannot occur.'
en[keys.TEXT_TANK_REMOVAL_DOCUMENTATION] = 'Documentation of oil tank removal. For example, pictures.'
en[keys.TEXT_NOTIFICATION_OF_TANK_REMEDIATION] = 'Notification of oil tank remediation'
en[keys.TEXT_TANK_REMEDIATION_DESCRIPTION] = 'Remediation description'
en[keys.TEXT_TANK_REMEDIATION_DOCUMENTATION] = 'Documentation of remediation. For example, images of the affected area'

en[keys.TEXT_NO_REGISTERED_TANKS] = 'No tank registered on this property'

en[keys.TEXT_REQUIRED_INFORMATION] = 'Required information'
en[keys.TEXT_CONTACT_INFORMATION] = 'Contact information'
en[keys.TEXT_VA_PLANT] = 'Va plant'
en[keys.TEXT_VA_IS_CONFIRMED] = 'It is confirmed that'
en[keys.TEXT_VA_LID_AVAILABLE] = 'The capsule lid is available (not covered by soil, gravel, etc.)'
en[keys.TEXT_VA_CORRECT_COVER_FITTED] = 'The right capsule cover is fitted (oval opening, not pointed hole)'
en[keys.TEXT_VA_VALVE_PROTECTION_REMOVED] =
    'Fire valve protection is removed from the valve, possibly with a valve rod mounted'
en[keys.TEXT_VA_WATER_ON_VALVE] = 'There is water on the valve'
en[keys.TEXT_VA_DISTANCE] = 'The distance between the fireball and the cap is between 70-90 cm'
en[keys.TEXT_VA_WIRED_CAP] = 'Protective cap is fitted with wires'
en[keys.TEXT_ATTACH_MAP_SECTION] = 'Attach map section'
en[keys.TEXT_MAP_SECTIONS] = 'Map sections'
en[keys.TEXT_AS_BUILT_DRAWINGS] = 'AS BUILT drawings'
en[keys.TEXT_DRAWINGS] = 'Drawings'
en[keys.TEXT_VA_OUTLET_NAME] = 'Name of extinguishing water outlet'

en[keys.TEXT_CHOOSE_ORGANIZATION] = 'Choose your organization'
en[keys.TEXT_COMPANY_ORG_NUMBER] = 'Company org number'
en[keys.TEXT_CONTACT_EMAIL] = 'Contact email'
en[keys.TEXT_CONTACT_PHONE] = 'Contact phone number'
en[keys.TEXT_INVOICE_ADDRESS_IF_DIFFERENT] = 'Invoice address, if different from postal address'
en[keys.TEXT_EVENTUAL_NOTES_INVOICE] = 'Any notes invoice'

en[keys.TEXT_RESPONSIBLE] = 'Responsible'
en[keys.TEXT_PLACE_OF_SALE] = 'Sale location'
en[keys.TEXT_HMS_TRAINING] = 'HMS declaration'
en[keys.TEXT_CONTACT_PERSON] = 'Contact person'
en[keys.TEXT_SEARCH_BY_ORG_ADDRESS_OR_NAME] = 'Search by org number, address or business name'
en[keys.TEXT_PYRO_SALE_1] = 'Is someone else in charge responsible for reporting?'
en[keys.TEXT_PYRO_SALE_2] =
    'The company must have hired a responsible person, and possibly a deputy, with a valid certificate of competence.'
en[keys.TEXT_PYRO_SALE_3] =
    'It is hereby confirmed that a responsible person will be employed in the business all the time the business handles fireworks (from the first item arrives, until any returned goods are delivered by the end of January)'
en[keys.TEXT_PYRO_SALE_PLACE] = 'Where should the fireworks sale take place'
en[keys.TEXT_PYRO_USE_PLACE] = 'Where should the fireworks use take place'

en[keys.TEXT_PYRO_SALE_INDOORS] = 'Indoors'
en[keys.TEXT_PYRO_SALE_OUTDOORS] = 'Outdoors'
en[keys.TEXT_PYRO_SALE_4] =
    'If the sale is to take place INDOOR, a fire technical sketch is required that clearly shows the location of the sales desk, evacuation and that safety distances are in accordance with the requirements of the regulations. See example here.'
en[keys.TEXT_PYRO_SALE_5] =
    'If the sale is to take place OUTDOORS, a sketch is required that clearly shows the location of the point of sale, and that safety distances are in accordance with the requirements of the regulations. See example here.'
en[keys.TEXT_OUTLET_LOCATION_SKETCH] = 'Sketch for location of outlet'
en[keys.TEXT_SKETCH] = 'Sketch'
en[keys.TEXT_PYRO_KEPT] = 'Our fireworks should be kept'
en[keys.TEXT_PYRO_APPROVED_CONTAINER] = 'In approved container'
en[keys.TEXT_PYRO_OWN_WAREHOUSE] = 'In own warehouse'
en[keys.TEXT_PYRO_SALE_6] =
    'If the fireworks are stored in the APPROVED CONTAINER, a sketch is required that clearly shows the location of the fireworks and that the safety distances comply with the requirements of the regulations. See example here.'
en[keys.TEXT_PYRO_SALE_7] =
    'If the fireworks are stored in their own warehouse in connection with the trading room, a fire technical sketch is required showing the location of the warehouse, evacuation and that safety distances are in accordance with the regulations.'
en[keys.TEXT_SKETCH_OF_CONTAINER_LOCATION] = 'Sketch of container location'
en[keys.TEXT_PYRO_COMPANY_TEST] = 'Company test from the Business Register'
en[keys.TEXT_HEREBY_CONFIRMED] = 'It is hereby confirmed that the company has'
en[keys.TEXT_DATE] = 'Date'
en[keys.TEXT_LAST_INTERNAL_CONTROL_DATE] = `Date of last review of the company's internal control`
en[keys.TEXT_PYRO_SALE_8] =
    '1. Ensure that the health, safety and environmental laws and regulations that apply to the business are available and have an overview of the requirements that are of particular importance to the business.'
en[keys.TEXT_PYRO_SALE_9] =
    '2. Ensure that employees have adequate knowledge and skills in the systematic health, environmental and safety work, including information on changes.'
en[keys.TEXT_PYRO_SALE_10] =
    '3. Ensure that the employees contribute so that the combined knowledge and experience are utilized.'
en[keys.TEXT_PYRO_SALE_11] = '4. Set goals for health, environment and safety'
en[keys.TEXT_PYRO_SALE_12] =
    '5. Overview of the organization of the business, including how responsibilities, tasks and authority for the work on health, environment and safety are distributed.'
en[keys.TEXT_PYRO_SALE_13] =
    '6. Identified hazards and problems and on this basis assessed risk, as well as prepared associated plans and measures to reduce risk conditions.'
en[keys.TEXT_PYRO_SALE_14] =
    '7. Implemented procedures to detect, rectify and prevent violations of requirements laid down in or pursuant to health, environmental and safety laws.'
en[keys.TEXT_PYRO_SALE_15] =
    '8. Conducted systematic monitoring and review of internal control to ensure that it functions as intended'

en[keys.TEXT_WE_WILL_CONTACT_YOU] = 'We will contact you to schedule the check'
en[keys.TEXT_WE_WILL_CONTACT_YOU_INSTALLATION] = 'Du vil bli kontaktet når søknaden er ferdig behandlet'

en[keys.TEXT_APPLICANT] = 'Applicant'
en[keys.TEXT_CONSTRUCTION_SITE] = 'Construction Site'
en[keys.TEXT_APPLICATION_FOR_CHIMNEY_CONSTRUCTION] = 'Application for chimney construction'
en[keys.TEXT_LIABILITY_DOCUMENTATION] = 'Attach documentation of liability'

en[keys.TEXT_CASE_NUMBER] = 'Case number'
en[keys.TEXT_HAVE_CASE_NUMBER] = 'Have case number?'
en[keys.TEXT_FIREPLACE_ALREADY_REPROTED] = 'Have you reported this fireplace already?'
en[keys.TEXT_INSTALLED_FIREPLACE] = 'Pick which fireplace is already installed'
en[keys.TEXT_OTHER_FIREPLACE_UNCERTAIN] = 'Other fireplace'
en[keys.TEXT_CLEAN_BURNING] = 'Clean burning'
en[keys.TEXT_NON_CLEAN_BURNING] = 'Not clean burning'

en[keys.TEXT_LOGIN_WITH_ID_PORTEN] = 'Login with ID-porten'
en[keys.TEXT_AUTHENTICATION_REQUIRED] = 'Authentication required'
en[keys.TEXT_PERSONAL_NR] = 'Personal nr'
en[keys.TEXT_USER_NOT_FOUND] = 'User not found'
en[keys.TEXT_TANK_REMOVAL_EXEMPTION_APPROVED_ON] = 'Application for an oil tank removal exemption was approved'
en[keys.TEXT_LOGGED_OUT] = 'You are now signed out of ID-porten'

en[keys.TEXT_JOB_APPLIES] = 'The job applies'
en[keys.TEXT_REHABILITATION_OF_EXISTING_CHIMNEY] = 'Rehabilitation of existing chimney'
en[keys.TEXT_REHABILITATION_CONSTRUCTION_OF_CHIMNEY] = 'Construction of a new chimney'

en[keys.TEXT_TANK_REMOVAL_EXEMPTION_APPROVED_ON] = 'Application for an oil tank removal exemption was approved'
en[keys.TEXT_SEARCH_BY_ADDRESS_GNR_BNR] = 'Search by address or gnr / bnr'

en[keys.TEXT_ROOF_TILE] = 'Roof tile'
en[keys.TEXT_ELEMENT] = 'Element'
en[keys.TEXT_STEEL] = 'Steel'

en[keys.TEXT_CHIMNEY_BEFORE_REHAB] = 'Chimney before rehabilitation'
en[keys.TEXT_CHIMNEY_AFTER_REHAB] = 'Chimney after rehabilitation'

en[keys.TEXT_NUMBER_OF_RACES] = 'Number of races'
en[keys.TEXT_SINGLE_RACES] = 'Single race'
en[keys.TEXT_DOUBLE_RACES] = 'Double race'

en[keys.TEXT_CROSS_SECTION] = 'Cross section'
en[keys.TEXT_CROSS_SECTION_CM] = 'Cross section (cm)'
en[keys.TEXT_OLD_CROSS_SECTION_CM] = 'Old cross section (cm)'
en[keys.TEXT_NEW_CROSS_SECTION_CM] = 'New cross section (cm)'
en[keys.TEXT_NUMBER_OF_RUNNING_METERS] = 'Number of running meters'
en[keys.TEXT_BY_THE_METER] = 'By the meter'
en[keys.TEXT_PLACEMENT] = 'Placement'
en[keys.TEXT_FOR_EXAMPLE_RIGHT_LEFT_NORTH_SOUTH] = 'For example, Right, Left, North, South'
en[keys.TEXT_TECHNICAL_APPROVAL_NUMBER_CE_MARKING] = 'Technical approval number / CE marking'
en[keys.TEXT_APPROVAL] = 'Approval'
en[keys.TEXT_TERMS_OF_INSTALLATION] = 'Terms of installation'
en[keys.TEXT_CM_10_DISTANCE] = '10 cm distance'
en[keys.TEXT_DIRECTLY_THROUGH_FLOOR_DIVIDERS] = 'Directly through floor dividers'
en[keys.TEXT_DIRECTLY_ON_COMBUSTIBLE_WALL] = 'Directly on combustible wall'

en[keys.TEXT_HOME_OWNER_CONTACT_PERSON] = 'Choose owner who is the contact person'
en[keys.TEXT_WHICH_CHIMNEY_REHAB] = 'Which chimney should be rehabilitated?'
en[keys.TEXT_NEWLY_CONSTRUCTED_CHIMNEY] = 'Newly constructed chimney'

en[keys.TEXT_TARGETED_SKETCH] = 'Targeted sketch'
en[keys.TEXT_MOUNTING_DESCRIPTION] = 'Mounting description'
en[keys.TEXT_NOTIFICATION_TO_NEIGHBORS] = 'Notification to neighbors'
en[keys.TEXT_CENTRAL_APPROVAL] = 'Central approval or application for local approval.'
en[keys.TEXT_CENTRAL_APPROVAL_MUST_BE_UPLOADED] = 'When applying for local approval, the following must be uploaded:'
en[keys.TEXT_CENTRAL_APPROVAL_UPLOADS] =
    'Application for liability, professional certificate / svennebret, other relevant competence'
en[keys.TEXT_IS_APPLICANT_OWNER] = 'Applicant is owner'

en[AuditType.FIRE_OWNER] = 'Fire owner audit'
en[AuditType.FIRE_USER] = 'Fire user audit'
en[AuditType.MAPPING] = 'Mapping'
en[AuditStatus.ACTIVE] = 'Created'
en[AuditStatus.APPROVED] = 'Approved'
en[AuditType.EL_REVISION] = 'El revision'
en[AuditType.REVISION] = 'Revision'
en[AuditType.OTHER_CASE] = 'Generic case'
en[keys.TEXT_STEP] = 'Step'

en[keys.ERROR_ONLY_ONE_APPLICATION_PER_HOUSING_UNIT] = 'Det er allerede levert søknad for denne bruksenheten'
en[keys.ERROR_NO_HOUSING_UNITS] = 'Du har ingen bruksenhet som er gyldig for denne søknaden'
en[keys.TEXT_FUEL_TYPE_SOLID] = 'Solid'
en[keys.TEXT_FUEL_TYPE_COMBO] = 'Combination'
en[keys.TEXT_FUEL_TYPE_BIO_OIL] = 'Bio oil'

en[keys.TEXT_CONCERN_CASE] = 'Concern'
en[keys.TEXT_SENDER] = 'Sender'
en[keys.TEXT_SEND_AS_ANONYMOUS] = 'Anonymous?'
en[keys.TEXT_FILES] = 'Attachments'
en[keys.TEXT_CONCERN_DESCRIPTION] = 'Reason for concern'
en[keys.TEXT_SEARCH_ORG_NR] = 'Search for org.nr'
//#endregion FIRE
