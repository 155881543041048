import React from 'react'
import { Grid, IconButton, styled, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import ImageIcon from '@mui/icons-material/Image'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import { DateTime } from 'luxon'
import { includes } from 'lodash'

import { TEXT_REMOVE } from 'translations/keys'
import { tr } from 'translations/translate'

const FileContainer = styled('div')({
    position: 'relative',
    transition: 'background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    fontSize: 0,
})

const FileTypeIcon = styled('div')(({ theme }) => ({
    fontSize: theme.spacing(8),
    padding: theme.spacing(2),
}))

const FileText = styled('div')(({ theme }) => ({
    fontSize: theme.spacing(2),
}))

export const FilePreview: React.FC<{ index: number; file: File; onRemove: (index: number) => void }> = (props) => {
    const { index, file, onRemove } = props

    const fileName = file.name.split('.').slice(0, -1).join('.')

    const fileLastModified = DateTime.fromMillis(file.lastModified)
        .setLocale('nb-no')
        .toLocaleString(DateTime.DATE_SHORT)

    const getFileTypeIcon = (fileType: string): JSX.Element => {
        if (includes(fileType, 'image')) {
            return <ImageIcon />
        } else if (includes(fileType, 'pdf')) {
            return <PictureAsPdfIcon />
        } else {
            return <InsertDriveFileIcon />
        }
    }

    return (
        <FileContainer>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <FileTypeIcon>{getFileTypeIcon(file.type)}</FileTypeIcon>
                        </Grid>
                        <Grid item xs>
                            <FileText>
                                <Typography noWrap>{fileName}</Typography>
                            </FileText>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <FileText>
                                <Typography>{fileLastModified}</Typography>
                            </FileText>
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={() => onRemove(index)}
                                color="primary"
                                aria-label={`${tr(TEXT_REMOVE)} ${file.name}`}
                                size="large"
                            >
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FileContainer>
    )
}
