import React from 'react'
import { change, Field } from 'redux-form'
import {
    AutocompleteAdapterProps,
    CubitAutocompleteAdapter,
} from 'common/cubit-autocomplete/cubit-autocomplete-adapter'
import { Owner, OwnerType } from 'tax/types/owner.type'
import { TEXT_OWNER } from 'translations/keys'
import { tr } from 'translations/translate'
import { useDispatch, useSelector } from 'react-redux'
import { actionFireClearCadastreUnit } from 'fire/fire-actions'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { FireFormName } from 'fire/enums/form-name.enum'
import { FormFieldName } from 'fire/forms/form-field-name.enum'
import { useSessionKey } from '../../app/app-selectors'
import { plantsProductionUrl } from '../../common/services/http-service'
import { AppState } from '../../app/app-store'

export const OWNER_AUTO_COMPLETE_NAME = 'ownerAutocomplete'
export const OwnerAutocomplete: React.FC<{ name: string; formName: FireFormName }> = (props) => {
    const { name, formName } = props
    const dispatch = useDispatch()
    const tenantId = useSelector((state: AppState) => state.appData.tenantId)
    const sessionKey = useSessionKey()

    const getOptionSelected = (option: Owner, value: Owner) => option.id === value.id

    const getOptionLabel = (option: Owner) =>
        option.type === OwnerType.Private ? `${option.firstName} ${option.lastName}` : `${option.name} ${option.number}`

    const renderOption = (option: Owner) => {
        return (
            <span>
                {option.type === OwnerType.Private ? (
                    `${option.firstName} ${option.lastName}`
                ) : (
                    <span style={{ fontWeight: 600 }}>{`${option.name} ${option.number}`}</span>
                )}
            </span>
        )
    }
    const compare = (a: Owner, b: Owner) => {
        const aOwnerName = a.type === OwnerType.Private ? `${a.firstName} ${a.lastName}` : a.name
        const bOwnerName = b.type === OwnerType.Private ? `${b.firstName} ${b.lastName}` : b.name
        if (aOwnerName > bOwnerName) return 1
        if (aOwnerName < bOwnerName) return -1
        return 0
    }

    const handleOwnerChange = (v: any): void => {
        dispatch(change(formName, FormFieldName.HousingUnitId, ''))
        dispatch(actionFireClearCadastreUnit())
    }

    const autocompleteProps: AutocompleteAdapterProps<Owner> = {
        label: tr(TEXT_OWNER),
        autoCompleteName: OWNER_AUTO_COMPLETE_NAME,
        getOptionSelected,
        getOptionLabel,
        renderOption,
        valueChanged: handleOwnerChange,
        valueSetter: (value) => value.id,
        compare,
        fetchUrl: (inputText: string) => plantsProductionUrl(`/owner/search/${tenantId}?q=${inputText}`),
    }

    return (
        <Field name={name} component={CubitAutocompleteAdapter} validate={ValidatorRequired} {...autocompleteProps} />
    )
}
