import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { Grid } from '@mui/material'
import { FireFormName } from 'fire/enums/form-name.enum'
import { AccommodationNotificationFormFieldName } from './accommodation-notification-field-name.enum'
import { tr } from 'translations/translate'
import { TEXT_NUMBER_OF_PEOPLE, TEXT_ACCOMMODATION_1, TEXT_ACCOMMODATION_2 } from 'translations/keys'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { CubitYesNoRadio } from 'common/cubit-yes-no-radio/cubit-yes-no-radio'
import { CubitTextarea } from 'common/cubit-textarea/cubit-textarea'

type AccommodationNotificationFormProps = {}

const Form: React.FC<InjectedFormProps<any, AccommodationNotificationFormProps> &
    AccommodationNotificationFormProps> = props => {
    const { handleSubmit } = props

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
                <Grid item>
                    <Field
                        name={AccommodationNotificationFormFieldName.NumberOfPeople}
                        label={tr(TEXT_NUMBER_OF_PEOPLE)}
                        component={CubitTextFieldAdapter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="number"
                        variant="filled"
                        validate={ValidatorRequired}
                        required
                        autoFocus
                    />
                </Grid>

                <Grid item xs={12}>
                    <CubitTextarea
                        name={AccommodationNotificationFormFieldName.AccommodationDescription}
                        label={tr(TEXT_ACCOMMODATION_1)}
                        required
                    />
                </Grid>

                <Grid item xs={12}>
                    <CubitYesNoRadio
                        name={AccommodationNotificationFormFieldName.SafeguardInstructionsExist}
                        label={tr(TEXT_ACCOMMODATION_2)}
                        validate={ValidatorRequired}
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export const AccommodationNotificationForm2 = reduxForm<{}, AccommodationNotificationFormProps>({
    form: FireFormName.AccommodationNotification,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
