import React, { useState, useMemo } from 'react'
import { ButtonOutlined } from 'common/button-outlined/button-outlined'
import { tr } from 'translations/translate'
import { TEXT_LOOK_AT_MAP, TEXT_CANCEL } from 'translations/keys'
import { Dialog, DialogContent, DialogActions } from '@mui/material'
import { IframeMap } from 'common/iframe-map/iframe-map'
import { LatLong } from 'common/types/lat-long.type'

export const ShowMapDialog: React.FC<{ propertyCoords: LatLong | null }> = props => {
    const { propertyCoords } = props

    const [dialogOpen, setDialogOpen] = useState(false)

    const lat = propertyCoords && propertyCoords.lat
    const long = propertyCoords && propertyCoords.long

    const Map = useMemo(() => (lat && long ? <IframeMap coords={{ lat, long }} /> : <></>), [lat, long])

    const handleClose = () => setDialogOpen(false)
    const handleOpen = () => setDialogOpen(true)
    return (
        <>
            <ButtonOutlined color="primary" onClick={handleOpen}>
                {tr(TEXT_LOOK_AT_MAP)}
            </ButtonOutlined>

            <Dialog
                onClose={handleClose}
                open={dialogOpen}
                PaperProps={{ square: true }}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogContent style={{ padding: 0 }}>{Map}</DialogContent>
                <DialogActions>
                    <ButtonOutlined color="primary" onClick={handleClose}>
                        {tr(TEXT_CANCEL)}
                    </ButtonOutlined>
                </DialogActions>
            </Dialog>
        </>
    )
}
