import React, { useEffect } from 'react'
import { ContentContainer } from 'common/content-container/content-container'
import { tr } from 'translations/translate'
import { TEXT_SUBMIT } from 'translations/keys'
import LayoutDefault from 'common/layout-default/layout-default'
import { ContentSection } from 'common/content-section/content-section'
import { useDispatch, useSelector } from 'react-redux'
import { isSubmitting, isValid, submit } from 'redux-form'
import { FireLogo } from 'fire/fire-logo/fire-logo'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'
import { PostalAuditForm } from './postal-audit-form'
import { OtherFormName } from '../../enums/form-name.enum'
import { CircularProgress, Grid } from '@mui/material'
import { ButtonContained } from '../../../common/button-contained/button-contained'
import { PostalAuditInfo } from './postal-audit-info'
import { useParams } from 'react-router-dom'
import { actionPostPostalAudit } from '../../other-actions'
import { convertObjectToFormData } from '../../../utils'
import { AppState } from '../../../app/app-store'
import { PostalForm } from './postal-form'
import { actionStoreTenantId } from '../../../app/app-actions'
import { getFormData, getFormValuesFromAudit, isGenericCase, isSecondStageGenericCase } from './postal-audit-helper'
interface RouteParams {
    pin: string
    auditId: string
    tenantId: string
}
export const PostalAudit: React.FC<FireFormPageProps> = (props) => {
    const dispatch = useDispatch()
    const isFormSubmitting = useSelector(isSubmitting(OtherFormName.PostalAudit))
    const isFormValid = useSelector(isValid(OtherFormName.PostalAudit))
    const { tenantId, auditId, pin } = useParams<RouteParams>()
    const audit = useSelector((state: AppState) => state.other.postalAudit)
    const formStuff = useSelector((state: AppState) => state.form)

    useEffect(() => {
        if (tenantId) {
            dispatch(actionStoreTenantId(encodeURIComponent(tenantId)))
        }
    }, [dispatch, tenantId])
    const convertToArray = (obj: any) => {
        return Object.keys(obj).map((key) => {
            return { field: key, value: obj[key] }
        })
    }
    const handleSubmit = (formValue: any) => {
        //results are answers
        let results = []
        if (audit && audit.metaData && audit.metaData.forms) {
            results = audit.metaData.forms.map((form: any, index: number) => {
                if (!isSecondStageGenericCase(audit) && isGenericCase(audit) && index === 1) {
                    return { formId: form.id, values: getFormValuesFromAudit(audit, form.id) }
                }
                if (isSecondStageGenericCase(audit) && index === 0) {
                    return { formId: form.id, values: getFormValuesFromAudit(audit, form.id) }
                }
                return { formId: form.id, values: convertToArray(formStuff[form.id].values) }
            })
        }
        const formData = { ...formValue, pinCode: Number(pin), tenantId, auditId, results: results }
        if ((!formData.documentation || formData.documentation.length === 0) && !isGenericCase(audit)) {
            alert('You must upload documents')
            return
        }
        dispatch(actionPostPostalAudit(convertObjectToFormData(formData, {})))
        //dispatch(actionPostPostalAudit(tenantId, formData))
    }

    return (
        <LayoutDefault logo={<FireLogo />}>
            <ContentContainer>
                <PostalAuditInfo />
                <ContentSection>
                    <PostalAuditForm onSubmit={handleSubmit} />
                </ContentSection>
                <div>
                    {getFormData(audit).map((form: any) => (
                        <PostalForm key={form.id} form={form.id} formData={form} />
                    ))}
                </div>
                <ContentSection>
                    <Grid item>
                        <Grid container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <ButtonContained
                                    color="secondary"
                                    onClick={() => dispatch(submit(OtherFormName.PostalAudit))}
                                    disabled={!isFormValid || isFormSubmitting}
                                >
                                    {isFormSubmitting && (
                                        <CircularProgress
                                            size="16px"
                                            style={{ position: 'absolute', left: 0, right: 0, margin: 'auto' }}
                                        />
                                    )}
                                    <span style={isFormSubmitting ? { opacity: 0.5 } : {}}>{tr(TEXT_SUBMIT)}</span>
                                </ButtonContained>
                            </Grid>
                        </Grid>
                    </Grid>
                </ContentSection>
            </ContentContainer>
        </LayoutDefault>
    )
}
