import React from 'react'
import { useHistory } from 'react-router'
import { TEXT_CANCEL } from 'translations/keys'
import { Button } from '@mui/material'
import { tr } from 'translations/translate'

export const ButtonGoBack: React.FC = () => {
    const history = useHistory()

    const handleGoBack = () => {
        history.goBack()
    }

    return <Button onClick={handleGoBack}>{tr(TEXT_CANCEL)}</Button>
}
