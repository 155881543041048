import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Router, useLocation } from 'react-router-dom'
import { ComplaintsPage } from 'tax/complaints/complaints-page'
import { FireFormsListPage } from 'fire/fire-forms-list-page/fire-forms-list-page'
import { TaxRoute } from './enums/tax-route.enum'
import { FireRoute } from './enums/fire-route.enum'
import history from './history'
import { FormDescriptor } from 'fire/types/form-descriptor.type'
import { getFireFormDescriptorsArray } from 'fire/forms/fire-form-descriptors-map'
import { LoginPage } from 'login-page/login-page'
import { OpenIdLoginPage } from '../login-page/open-id-login-page'
import { UserNotFound } from '../login-page/user-not-found'
import { GoodBye } from '../login-page/good-bye'
import { usePopup } from './app-selectors'
import Popup from 'common/cubit-popup/cubit-popup'
import { AppRoute } from './enums/app-route.enum'
import { PostalAudit } from '../other/forms/postal-audit/postal-audit'
import { MappingCase } from '../other/forms/mapping-case/mapping-case'
import { ConcernCase } from '../other/forms/concern-case/concern-case'
import ConcernCaseThankYou from '../other/forms/concern-case/thank-you'
import { ThankYou } from '../common/thank-you-page/thank-you-page'
import Title from 'common/title'

import LayoutDefault from 'common/layout-default/layout-default'
import { FireLogo } from 'fire/fire-logo/fire-logo'
import { FireSignOut } from 'fire/fire-sign-out/fire-sign-out'
import { useIsAuthenticated } from 'app/app-selectors'
import { Authorization } from 'login-page/authorization'
import { ContentDivider } from 'common/content-divider/content-divider'
import { ContentHeading } from 'common/content-heading/content-heading'
import { ContentContainer } from 'common/content-container/content-container'
import { tr } from 'translations/translate'

import { useInterval } from './hooks'
import { actionRefreshAccessToken, actionStoreTenantId } from './app-actions'
import { TenantLoginPage } from '../login-page/tenant-login-page'
import { Project } from '../other/forms/project/project'
import Footer from './footer'
import { AppState } from './app-store'
import { TenantSelector } from '../common/tenant-selector/tenant-selector'
import { createTheme, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const refreshTokenInterval = 1000 * 90 // miliseconds

const appTheme = createTheme({
    typography: { htmlFontSize: 14 },
    palette: {
        primary: {
            main: '#353D3E',
            light: '#e6ecf3',
            dark: '#353D3E',
        },
        secondary: {
            main: '#d62942',
        },
        common: {
            black: '#2e2f2e',
        },
        error: {
            main: '#b52424',
        },
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#2e2f2e',
                },
            },
        },
    },
})
export const GridContainerOverride = { marginTop: '-16px' }

export const renderFireFormRoute = (formDescriptor: FormDescriptor, index: number) => {
    return (
        <Route
            key={index}
            path={formDescriptor.route}
            render={({ match: { url } }) => (
                <>
                    <Route path={`${url}/`} component={() => formDescriptor.component()} exact />
                    <Route
                        path={`${url}${formDescriptor.successRoute}`}
                        component={() => formDescriptor.submitSuccessComponent}
                    />
                </>
            )}
        />
    )
}

const Layout = () => {
    const [hop, show_hop] = useState(false)
    const dispatch = useDispatch()
    const { pathname } = useLocation() || {}
    const tenantId = useSelector((state: AppState) => state.appData.tenantId)
    useEffect(() => {
        //dont call tenant data from state if its a login action
        if (tenantId && (!pathname.includes('login') || pathname.includes('tenant'))) {
            dispatch(actionStoreTenantId(encodeURIComponent(tenantId)))
        }
    }, [dispatch, tenantId])
    const fireFormDescriptorsArray = getFireFormDescriptorsArray()

    const titles = fireFormDescriptorsArray.map((p: any) => ({
        labelTranslationKey: p.labelTranslationKey,
        route: p.route,
    }))

    const form = fireFormDescriptorsArray.find((p: any) => pathname.startsWith(p.route))

    const authenticated = useIsAuthenticated()

    useEffect(() => {}, [])

    const hop_id = 'innhold'

    const onHopFocus = () => {
        show_hop(true)
    }

    const test = pathname.indexOf('/fire/') !== -1

    if (test && form)
        return (
            <>
                <div
                    className="hop-to-content"
                    style={{
                        zIndex: hop ? 0 : -1,
                        position: hop ? 'static' : 'absolute',
                    }}
                >
                    <a
                        className="hop-to-content-link"
                        href={`#${hop_id}`}
                        onFocus={onHopFocus}
                        onBlur={() => show_hop(false)}
                    >
                        Hopp til innhold
                    </a>
                </div>
                <LayoutDefault logo={<FireLogo />} rightElement={<FireSignOut />}>
                    <ContentContainer>
                        <div id={`${hop_id}`}>
                            <ContentHeading>{tr(`${form.labelTranslationKey}`)}</ContentHeading>
                            <ContentDivider />
                            <TenantSelector />
                            {authenticated && <>{fireFormDescriptorsArray.map((d, i) => renderFireFormRoute(d, i))}</>}

                            {!authenticated && <Authorization />}
                        </div>
                    </ContentContainer>
                </LayoutDefault>

                <Footer />
            </>
        )

    return (
        <>
            <Title data={titles} />
            <Route exact path="/" component={FireFormsListPage} />
            <Route exact path="/login/:sessionKey/:pathToProject/:pathToForm/:tenantId" component={LoginPage} />
            <Route exact path="/tenant/:pathToProject/:pathToForm/:tenantId" component={TenantLoginPage} />
            <Route exact path="/open-id-login" component={OpenIdLoginPage} />

            <Route exact path={FireRoute.NotFound} component={UserNotFound} />
            <Route exact path={FireRoute.LoggedOut} component={GoodBye} />
            <Route exact path={FireRoute.ThankYou} component={ThankYou} />
            <Route exact path={`${TaxRoute.Complaint}/:taxationId`} component={ComplaintsPage} />
            <Route exact path={`${AppRoute.PostalAudit}/:tenantId/:auditId/:pin`} component={PostalAudit} />
            <Route exact path={`${AppRoute.Project}/:tenantId/:projectId`} component={Project} />
            <Route exact path={`${AppRoute.MappingCase}/:tenantId/:auditId/:pin/:targetId`} component={MappingCase} />
            <Route exact path={`${AppRoute.ConcernCase}/:tenantId`} component={ConcernCase} />
            <Route
                exact
                path={`${AppRoute.ConcernCase}/:tenantId${FireRoute.ThankYou}`}
                component={ConcernCaseThankYou}
            />

            <Route exact path={FireRoute.FormList} component={FireFormsListPage} />
            {fireFormDescriptorsArray.map((d, i) => renderFireFormRoute(d, i))}
        </>
    )
}

export const App: React.FC = () => {
    const popup = usePopup()

    const dispatch = useDispatch()

    useInterval(() => {
        dispatch(actionRefreshAccessToken())
    }, refreshTokenInterval)

    return (
        <ThemeProvider theme={appTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} locale={'nb-no'}>
                <>
                    {popup && <Popup {...popup} />}
                    <Router history={history}>
                        <Layout />
                    </Router>
                </>
            </LocalizationProvider>
        </ThemeProvider>
    )
}
