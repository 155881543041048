import React from 'react'
import { InputType } from '../input-type.enum'
import { FormControl, TextField, TextFieldProps } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/nb'

export type CubitDatePickerAdapterProps = { type: InputType.DateRange | InputType.Date }

const CubitDatePickerAdapter: React.FC<CubitDatePickerAdapterProps> = (props: any) => {
    const { id, name, input, field, label, style, variant, meta, ...rest } = props

    const value = input.value || null
    const touched = meta.touched
    const errorText = meta.error

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nb'}>
            <FormControl component="fieldset" style={style}>
                <DatePicker
                    id={id || name || label}
                    margin="none"
                    label={label}
                    placeholder={label}
                    autoOk
                    renderInput={(props: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...props} />}
                    inputVariant={variant}
                    value={value ? new Date(value) : null}
                    onChange={(date: any) =>
                        input.onChange(
                            date?.set('hour', 12).set('minute', 0).set('second', 0).set('millisecond', 0).toISOString(),
                        )
                    }
                    error={touched && errorText && errorText.length > 0}
                    helperText={touched && errorText && errorText.length > 0 ? errorText : null}
                    invalidDateMessage="Ugyldig datoformat"
                    {...rest}
                />
            </FormControl>
        </LocalizationProvider>
    )
}

export default CubitDatePickerAdapter
