import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { reduxForm, InjectedFormProps, Field, formValueSelector } from 'redux-form'
import { Grid } from '@mui/material'
import { FireFormName } from 'fire/enums/form-name.enum'
import { ContentSectionContainer } from 'common/content-section-container/content-section-container'
import { ChimneyInstallationFormFieldName } from './chimney-installation-form-field-name.enum'
import { tr } from 'translations/translate'
import {
    TEXT_CHIMNEY_BEFORE_REHAB,
    TEXT_CHIMNEY_AFTER_REHAB,
    TEXT_NUMBER_OF_RACES,
    TEXT_SINGLE_RACES,
    TEXT_DOUBLE_RACES,
    TEXT_OLD_CROSS_SECTION_CM,
    TEXT_NEW_CROSS_SECTION_CM,
    TEXT_NUMBER_OF_RUNNING_METERS,
    TEXT_PLACEMENT,
    TEXT_TECHNICAL_APPROVAL_NUMBER_CE_MARKING,
    TEXT_PRODUCT_NAME,
    TEXT_CM_10_DISTANCE,
    TEXT_DIRECTLY_THROUGH_FLOOR_DIVIDERS,
    TEXT_DIRECTLY_ON_COMBUSTIBLE_WALL,
    TEXT_TERMS_OF_INSTALLATION,
    TEXT_NEWLY_CONSTRUCTED_CHIMNEY,
    TEXT_CROSS_SECTION_CM,
} from 'translations/keys'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { ChimneyRaceType } from './chimney-race-type.enum'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { CubitCheckboxGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-checkbox-group-adapter'
import { ChimneyInstallationTerm } from './chimney-installation-terms.enum'

import { AppState } from 'app/app-store'
import { ChimneyJobType } from './chimney-job-type.enum'
import { loadChimneyTypes } from 'fire/fire-actions'

type ChimneyInstallationFormProps = {}

const installationTermsOptions = [
    {
        label: tr(TEXT_CM_10_DISTANCE),
        value: ChimneyInstallationTerm.DistanceOf10Cm,
    },
    {
        label: tr(TEXT_DIRECTLY_THROUGH_FLOOR_DIVIDERS),
        value: ChimneyInstallationTerm.ThroughFloorDividers,
    },
    {
        label: tr(TEXT_DIRECTLY_ON_COMBUSTIBLE_WALL),
        value: ChimneyInstallationTerm.OnCombustibleWall,
    },
]

const Form: React.FC<InjectedFormProps<any, ChimneyInstallationFormProps> & ChimneyInstallationFormProps> = (props) => {
    const { handleSubmit } = props

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadChimneyTypes())
    }, [])

    const chimneyRacesOptions = [
        {
            label: tr(TEXT_SINGLE_RACES),
            value: ChimneyRaceType.SingleRace,
        },
        {
            label: tr(TEXT_DOUBLE_RACES),
            value: ChimneyRaceType.DoubleRace,
        },
    ]

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.ChimneyInstallation)
    const chimneyJobType = selector(state, ChimneyInstallationFormFieldName.ConstructionType)

    const { plantListOptions } = state.fire
    const chimneyTypes = plantListOptions.chimneyType || []
    const chimneyDimensions = plantListOptions.chimneyDimension || []
    const chimneyLocations = plantListOptions.chimneyLocation || []

    const chimneyTypeLabel =
        chimneyJobType === ChimneyJobType.NewConstruction
            ? tr(TEXT_NEWLY_CONSTRUCTED_CHIMNEY)
            : tr(TEXT_CHIMNEY_BEFORE_REHAB)
    const chimneyTypeName =
        chimneyJobType === ChimneyJobType.NewConstruction
            ? ChimneyInstallationFormFieldName.ChimneyTypeNewConstruction
            : ChimneyInstallationFormFieldName.ChimneyTypeBeforeRehab

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <ContentSectionContainer>
                <Grid container spacing={4} direction="column">
                    <Grid item xs={12} sm={12} md={6}>
                        <Field
                            name={chimneyTypeName}
                            label={chimneyTypeLabel}
                            component={CubitRadioGroupAdapter}
                            options={chimneyTypes}
                            validate={ValidatorRequired}
                            required
                            autoFocus
                        />
                    </Grid>

                    {chimneyJobType === ChimneyJobType.Rehabilitation && (
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={ChimneyInstallationFormFieldName.ChimneyTypeAfterRehab}
                                label={tr(TEXT_CHIMNEY_AFTER_REHAB)}
                                component={CubitRadioGroupAdapter}
                                options={chimneyTypes}
                                validate={ValidatorRequired}
                                required
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={6}>
                        <Field
                            name={ChimneyInstallationFormFieldName.NumberOfRaces}
                            label={tr(TEXT_NUMBER_OF_RACES)}
                            component={CubitRadioGroupAdapter}
                            options={chimneyRacesOptions}
                            validate={ValidatorRequired}
                            required
                        />
                    </Grid>

                    {chimneyJobType === ChimneyJobType.NewConstruction && (
                        <Grid item xs={12} sm={6}>
                            <Field
                                name={ChimneyInstallationFormFieldName.CrossSectionDiameter}
                                label={tr(TEXT_CROSS_SECTION_CM)}
                                component={CubitRadioGroupAdapter}
                                options={chimneyDimensions}
                                validate={ValidatorRequired}
                                required
                            />
                        </Grid>
                    )}

                    {chimneyJobType === ChimneyJobType.Rehabilitation && (
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name={ChimneyInstallationFormFieldName.OldCrossSectionDiameter}
                                        label={tr(TEXT_OLD_CROSS_SECTION_CM)}
                                        component={CubitRadioGroupAdapter}
                                        options={chimneyDimensions}
                                        validate={ValidatorRequired}
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name={ChimneyInstallationFormFieldName.NewCrossSectionDiameter}
                                        label={tr(TEXT_NEW_CROSS_SECTION_CM)}
                                        component={CubitRadioGroupAdapter}
                                        options={chimneyDimensions}
                                        validate={ValidatorRequired}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Field
                                    name={ChimneyInstallationFormFieldName.NumberOfRunningMeters}
                                    label={tr(TEXT_NUMBER_OF_RUNNING_METERS)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    type="number"
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <Field
                            name={ChimneyInstallationFormFieldName.Placement}
                            label={tr(TEXT_PLACEMENT)}
                            component={CubitRadioGroupAdapter}
                            options={chimneyLocations}
                            validate={ValidatorRequired}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Field
                                    name={ChimneyInstallationFormFieldName.TechnicalApprovalMarking}
                                    label={tr(TEXT_TECHNICAL_APPROVAL_NUMBER_CE_MARKING)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6}>
                                <Field
                                    name={ChimneyInstallationFormFieldName.ProductName}
                                    label={tr(TEXT_PRODUCT_NAME)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <label>{tr(TEXT_TERMS_OF_INSTALLATION)}</label>
                        <Field
                            name={ChimneyInstallationFormFieldName.InstallationTerms}
                            component={CubitCheckboxGroupAdapter}
                            valueIsObject={false}
                            row={false}
                            options={installationTermsOptions}
                            validate={ValidatorRequired}
                            required
                        />
                    </Grid>
                </Grid>
            </ContentSectionContainer>
        </form>
    )
}

export const ChimneyInstallationForm3 = reduxForm<{}, ChimneyInstallationFormProps>({
    form: FireFormName.ChimneyInstallation,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
