import * as React from 'react'
import TextField from '@mui/material/TextField'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { Grid } from '@mui/material'
import 'dayjs/locale/nb'

export const localizeFormatDateTime = (date: Dayjs | null, template: string): string =>
    date ? dayjs(date).locale('nb').format(template) : ''

type DateTimeRangePickerProps = {
    from?: Dayjs | null
    to?: Dayjs | null
    onChange?: (value: [Dayjs | null, Dayjs | null]) => void
}

export const DateTimeRangePicker: React.FC<DateTimeRangePickerProps> = ({
    from = null,
    to = null,
    onChange = () => {},
}) => {
    const [fromDateTime, setFromDateTime] = React.useState<Dayjs | null>(from)
    const [toDateTime, setToDateTime] = React.useState<Dayjs | null>(to)

    React.useEffect(() => {
        setFromDateTime(from)
    }, [from])

    React.useEffect(() => {
        setToDateTime(to)
    }, [to])

    const handleFromDateTimeChange = (newValue: Dayjs | null) => {
        setFromDateTime(newValue)
        onChange([newValue, toDateTime])
    }

    const handleToDateTimeChange = (newValue: Dayjs | null) => {
        setToDateTime(newValue)
        onChange([fromDateTime, newValue])
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'nb'}>
            <Grid item>
                <div style={{ display: 'flex', gap: '8px' }}>
                    <DateTimePicker
                        label="From"
                        value={fromDateTime}
                        onChange={handleFromDateTimeChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="From Date & Time"
                                value={fromDateTime ? localizeFormatDateTime(fromDateTime, 'L LT') : ''}
                            />
                        )}
                    />
                    <DateTimePicker
                        label="To"
                        value={toDateTime}
                        onChange={handleToDateTimeChange}
                        minDateTime={fromDateTime || undefined}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder="To Date & Time"
                                value={toDateTime ? localizeFormatDateTime(toDateTime, 'L LT') : ''}
                            />
                        )}
                    />
                </div>
            </Grid>
        </LocalizationProvider>
    )
}
