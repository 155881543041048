import React from 'react'
import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material'

const StyledContainer = styled('div')(({ theme }: { theme: Theme }) => ({
    width: '100%',
    background: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(2),
}))

export const ContentContainer: React.FC = (props) => {
    return <StyledContainer>{props.children}</StyledContainer>
}
