import React from 'react'
import { Field } from 'redux-form'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { HousingUnit } from 'tax/types/housing-unit.type'
import { Chimney } from 'fire/types/chimney.type'
import { Grid } from '@mui/material'
import { TEXT_CHIMNEY, TEXT_ASSOCIATED_FIREPLACE, TEXT_NO_RESULTS, TEXT_WHICH_CHIMNEY_REHAB } from 'translations/keys'
import { tr } from 'translations/translate'
import { filter } from 'lodash'
import { Fireplace } from 'tax/types/fireplace.type'
import { HousingUnitAutocompleteObject } from 'fire/types/housing-unit-autocomplete-object.type'

export const HousingUnitChimneySelect: React.FC<{
    name: string
    housingUnitObject: HousingUnitAutocompleteObject | null
}> = props => {
    const { name, housingUnitObject } = props

    const chimneys = housingUnitObject ? housingUnitObject.chimneys : []

    const housingUnitChimneyOptions = housingUnitObject
        ? chimneys.map(c => ({
              label: getChimneyLabel(housingUnitObject.housingUnit, c),
              value: c.id,
          }))
        : []

    return (
        <>
            <Field
                name={name}
                label={tr(TEXT_WHICH_CHIMNEY_REHAB)}
                component={CubitRadioGroupAdapter}
                options={housingUnitChimneyOptions}
                validate={ValidatorRequired}
                required
            />
            {housingUnitChimneyOptions.length === 0 && <span>{tr(TEXT_NO_RESULTS)}</span>}
        </>
    )
}

const getChimneyLabel = (
    housingUnit: HousingUnit | null,
    c: Chimney | null,
    fireplace: boolean = true,
): React.ReactNode => {
    return (
        <Grid container style={{ padding: '8px 0' }}>
            {c && (
                <>
                    <Grid item xs={12}>
                        {tr(TEXT_CHIMNEY)}: {c.type} {c.location && `, ${c.location}`}
                    </Grid>
                    {fireplace &&
                        getChimneyFireplaces(housingUnit, c.id).map((f, i) => (
                            <Grid key={i} item xs={12}>
                                {tr(TEXT_ASSOCIATED_FIREPLACE)} : {f.description}
                            </Grid>
                        ))}
                </>
            )}
        </Grid>
    )
}

const getChimneyFireplaces = (housingUnit: HousingUnit | null, chimneyId: string): Fireplace[] => {
    if (housingUnit) {
        return filter(housingUnit.fireplaces, f => f.chimneyId === chimneyId)
    } else {
        return []
    }
}
