import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'

import { FireFormName } from 'fire/enums/form-name.enum'

import {
    CubitFileUpload,
    CubitRadioGroupAdapter,
    CubitGeosuggestAdapter,
    CubitTextFieldAdapter,
    ContentSectionHeading,
    ContentSectionContainer,
} from 'common'

import { PyrotechnicsUseFormFieldName } from './pyrotechnics-use-form-field-name.enum'
import { Grid } from '@mui/material'

import { ValidatorRequired } from 'utils/validators/validator-required'

import { tr } from 'translations/translate'
import * as t from 'translations/keys'
import { DateTimeRangePicker } from 'common/cubit-inputs/react-form-adapters/date-time-range-adapter'
import { Dayjs } from 'dayjs'

const Form: React.FC<InjectedFormProps<any>> = ({ change }) => {
    const handleDateRangeChange = ([from, to]: [Dayjs | null, Dayjs | null]) => {
        change('dateFrom', from)
        change('dateTo', to)
    }
    return (
        <form>
            <ContentSectionContainer>
                <ContentSectionHeading variant="subtitle1">{tr(t.TEXT_PYROTECHNICS_PLACE)}</ContentSectionHeading>

                <Field
                    name={PyrotechnicsUseFormFieldName.Location}
                    label={tr(t.TEXT_SEARCH_BY_ADDRESS_OR_BUILDING)}
                    component={CubitGeosuggestAdapter}
                    validate={ValidatorRequired}
                    required
                    autoFocus
                />
            </ContentSectionContainer>

            <ContentSectionContainer>
                <Field
                    name={PyrotechnicsUseFormFieldName.LocationOutdoorsIndoors}
                    label={tr(t.TEXT_PYRO_USE_PLACE)}
                    component={CubitRadioGroupAdapter}
                    options={[
                        {
                            label: tr(t.TEXT_PYRO_SALE_OUTDOORS),
                            value: 'OUTDOORS',
                        },
                        {
                            label: tr(t.TEXT_PYRO_SALE_INDOORS),
                            value: 'INDOORS',
                        },
                    ]}
                    validate={ValidatorRequired}
                    required
                />
            </ContentSectionContainer>

            <Field
                name={PyrotechnicsUseFormFieldName.LocationDescription}
                label={tr(t.TEXT_PYROTECHNICS_LOCATION_DESCRIPTION)}
                component={CubitTextFieldAdapter}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="filled"
            />

            <div style={{ margin: '20px' }}></div>

            <ContentSectionHeading variant="subtitle1">{tr(t.TEXT_PYROTECHNICS_DATE)}</ContentSectionHeading>

            <Grid container spacing={4}>
                <Field
                    name="dateRange"
                    component={(props: any) => (
                        <DateTimeRangePicker
                            from={props.input.value?.from || null}
                            to={props.input.value?.to || null}
                            onChange={handleDateRangeChange}
                        />
                    )}
                />
            </Grid>

            <div style={{ margin: '40px' }}></div>

            <Field
                name={PyrotechnicsUseFormFieldName.Description}
                label={tr(t.TEXT_PYROTECHNICS_DESCRIPTION)}
                component={CubitTextFieldAdapter}
                InputLabelProps={{
                    shrink: true,
                }}
                variant="filled"
            />

            <div style={{ margin: '20px' }}></div>

            <ContentSectionHeading variant="subtitle1">{tr(t.TEXT_PYROTECHNICS_ATTACHMENTS)}</ContentSectionHeading>

            <ContentSectionContainer>
                <CubitFileUpload name={PyrotechnicsUseFormFieldName.Attachments} />
            </ContentSectionContainer>
        </form>
    )
}

export default reduxForm<{}>({
    form: FireFormName.PyrotechnicsUse,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
