import React from 'react'
import { styled } from '@mui/material'

const Section = styled('section')(({ theme }) => ({
    padding: theme.spacing(2, 5),
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5, 3),
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 2),
    },
}))

export const ContentSection: React.FC = (props) => {
    return <Section>{props.children}</Section>
}
