import React, { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actionSetStateTenantId } from 'app/app-actions'

interface RouteParams {
    pathToProject: string
    pathToForm: string
    tenantId: string
    id: string
}
export const TenantLoginPage: React.FC = () => {
    const { pathToProject, pathToForm, tenantId, id } = useParams<RouteParams>()
    const dispatch = useDispatch()

    useEffect(() => {
        if (tenantId) {
            //in login we just set to state, while action inside the app will take it from there and do network call with epic
            dispatch(actionSetStateTenantId(encodeURIComponent(tenantId)))
        }
    }, [dispatch, tenantId])

    if (pathToProject === 'tax') {
        return <Redirect to={`/${pathToProject}/complaint/${pathToForm}`} />
    }
    return <Redirect to={`/${pathToProject}/${pathToForm}/${id ? id : ''}`} />
}
