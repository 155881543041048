import React from 'react'
import Button from '@mui/material/Button'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import {useDispatch, useSelector} from 'react-redux'
import { AppState } from '../../app/app-store'
import {actionFireSignOut} from "../fire-actions";

export const FireSignOut: React.FC = () => {
    const accessToken = useSelector((state: AppState) => state.appData.accessToken)
    const dispatch = useDispatch()
    return accessToken ? (
        <Button variant={'text'} onClick={() => dispatch(actionFireSignOut())} startIcon={<ExitToAppIcon />}>
            Logg ut
        </Button>
    ) : null
}
