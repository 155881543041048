import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import TextField from '@mui/material/TextField'
import { InputType } from '../input-type.enum'

export type CubitTextFieldAdapterProps = { type: InputType.TextField } // type props are required to differenciate between different field adapters

const CubitTextFieldAdapter: React.FC<CubitTextFieldAdapterProps> = (props: any) => {
    const {
        input: { id, name, value, onChange, onBlur, onFocus },
        options,
        formcontrolprops,
        meta: { touched, error },
        ...rest
    } = props

    return (
        <FormControl>
            <FormGroup row>
                <TextField
                    id={id || name}
                    error={touched && error ? true : false}
                    helperText={touched && error}
                    {...rest}
                    required={false}
                    name={name}
                    onChange={(event: any) => onChange(event.target.value)}
                    onFocus={onFocus}
                    {...props.input}
                    value={value}
                />
            </FormGroup>
        </FormControl>
    )
}

export { CubitTextFieldAdapter }
