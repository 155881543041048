import React from 'react'
import { styled } from '@mui/material'

const SectionContainer = styled('section')(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(1),
    },
}))

export const ContentSectionContainer: React.FC = (props) => {
    return <SectionContainer>{props.children}</SectionContainer>
}
