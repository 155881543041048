import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { App } from 'app/app'
import { Provider } from 'react-redux'
import { createAppStore } from 'app/app-store'

const store = createAppStore()

const render = (AppComponent: React.ComponentType) => {
    return ReactDOM.render(
        <Provider store={store}>
            <AppComponent />
        </Provider>,
        document.getElementById('root'),
    )
}

render(App)

if ((module as any).hot) {
    (module as any).hot.accept('./app/app', () => {
        const NextApp = require('./app/app').default
        render(NextApp)
    })
}
