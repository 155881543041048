import React from 'react'
import { reduxForm, InjectedFormProps, Field } from 'redux-form'
import { Grid } from '@mui/material'
import { tr } from 'translations/translate'
import {
    TEXT_OWNER_CONSENT,
    TEXT_RESPONSIBLE_CONSENT,
    TEXT_RESPONSIBLE_CAN_FIREGUARD,
    TEXT_AWAKE_NIGHT_WATCH,
    TEXT_SUFFICIENT_NUMBER_OF_GUARDS,
    TEXT_NO_FLAMMABLE_ACTIVITY,
    TEXT_EVERYONE_KNOWS_ESCAPE_ROUTES,
    TEXT_ESCAPE_ROUTES_TIDY_AND_ACCESSIBLE,
    TEXT_FIRE_ALARM_SYSTEMS_CHECKED_AND_FUNCTIONING,
    TEXT_EXTINGUISHING_EQUIPMENT_CHECKED_AND_FUNCTIONING,
    TEXT_CONTROL_SYSTEM_CHECKED_AND_FUNCTIONING,
    TEXT_SPRINKLER_SYSTEMS_CHECKED_AND_FUNCTIONING,
    TEXT_ACCOMMODATION_LIMITED_TO_AGREED_BUILDING_PART,
    TEXT_ACCOMMODATION_CONFIRM,
} from 'translations/keys'
import { CubitCheckboxGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-checkbox-group-adapter'
import { AccommodationConfirmationOption } from './accommodation-confirmation-option.enum'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import { AccommodationNotificationFormFieldName } from './accommodation-notification-field-name.enum'
import { FireFormName } from 'fire/enums/form-name.enum'

type AccommodationNotificationFormProps = {}

const Form: React.FC<InjectedFormProps<any, AccommodationNotificationFormProps> &
    AccommodationNotificationFormProps> = props => {
    const { handleSubmit } = props

    const accommodationConfirmationOptions = [
        {
            label: tr(TEXT_OWNER_CONSENT),
            value: AccommodationConfirmationOption.OwnerConsent,
        },
        {
            label: tr(TEXT_RESPONSIBLE_CONSENT),
            value: AccommodationConfirmationOption.ResponsibleConsent,
        },
        {
            label: tr(TEXT_RESPONSIBLE_CAN_FIREGUARD),
            value: AccommodationConfirmationOption.ResponsibleCanFireguard,
        },
        {
            label: tr(TEXT_AWAKE_NIGHT_WATCH),
            value: AccommodationConfirmationOption.AwakeNightWatch,
        },
        {
            label: tr(TEXT_SUFFICIENT_NUMBER_OF_GUARDS),
            value: AccommodationConfirmationOption.SufficientNumberOfGuards,
        },
        {
            label: tr(TEXT_NO_FLAMMABLE_ACTIVITY),
            value: AccommodationConfirmationOption.NoFlammableActivity,
        },
        {
            label: tr(TEXT_EVERYONE_KNOWS_ESCAPE_ROUTES),
            value: AccommodationConfirmationOption.EveryoneKnowsEscapeRoutes,
        },
        {
            label: tr(TEXT_ESCAPE_ROUTES_TIDY_AND_ACCESSIBLE),
            value: AccommodationConfirmationOption.EscapeRoutesTidyAndAccessible,
        },
        {
            label: tr(TEXT_FIRE_ALARM_SYSTEMS_CHECKED_AND_FUNCTIONING),
            value: AccommodationConfirmationOption.FireAlarmSystemsCheckedAndFunctioning,
        },
        {
            label: tr(TEXT_EXTINGUISHING_EQUIPMENT_CHECKED_AND_FUNCTIONING),
            value: AccommodationConfirmationOption.ExtinguishingEquipmentCheckedAndFunctioning,
        },
        {
            label: tr(TEXT_CONTROL_SYSTEM_CHECKED_AND_FUNCTIONING),
            value: AccommodationConfirmationOption.ControlSystemCheckedAndFunctioning,
        },
        {
            label: tr(TEXT_SPRINKLER_SYSTEMS_CHECKED_AND_FUNCTIONING),
            value: AccommodationConfirmationOption.SprinklerSystemsCheckedAndFunctioning,
        },
        {
            label: tr(TEXT_ACCOMMODATION_LIMITED_TO_AGREED_BUILDING_PART),
            value: AccommodationConfirmationOption.AccommodationLimitedToAgreedBuildingPart,
        },
    ]

    return (
        <form onSubmit={handleSubmit}>
            <ContentSectionHeading>{tr(TEXT_ACCOMMODATION_CONFIRM)}</ContentSectionHeading>
            <Grid container spacing={4}>
                <Grid item>
                    <Field
                        name={AccommodationNotificationFormFieldName.FireSafetyChecklist}
                        component={CubitCheckboxGroupAdapter}
                        valueIsObject={false}
                        row={false}
                        options={accommodationConfirmationOptions}
                        autoFocus
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export const AccommodationNotificationForm3 = reduxForm<{}, AccommodationNotificationFormProps>({
    form: FireFormName.AccommodationNotification,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
