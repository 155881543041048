import React from 'react'
import { reduxForm, Field, InjectedFormProps, formValueSelector } from 'redux-form'
import { tr } from 'translations/translate'
import {
    TEXT_SEARCH_BY_ADDRESS_OR_BUILDING,
    TEXT_ACCOMMODATION,
    TEXT_IS_SOMEONE_RESPONSIBLE_FOR_REPORTING,
    TEXT_NAME,
    TEXT_MOBILE_NUMBER,
    TEXT_EMAIL,
} from 'translations/keys'
import { Grid } from '@mui/material'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { AccommodationNotificationFormFieldName } from './accommodation-notification-field-name.enum'
import { CubitGeosuggestAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-geosuggest-adapter'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import { useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { CubitYesNoRadio } from 'common/cubit-yes-no-radio/cubit-yes-no-radio'
import { FireFormName } from 'fire/enums/form-name.enum'
import dayjs, { Dayjs } from 'dayjs'
import { DateTimeRangePicker } from 'common/cubit-inputs/react-form-adapters/date-time-range-adapter'

type AccommodationNotificationFormProps = {}

const Form: React.FC<
    InjectedFormProps<any, AccommodationNotificationFormProps> & AccommodationNotificationFormProps
> = (props) => {
    const { handleSubmit, change } = props

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.AccommodationNotification)
    const isSomeoneResponsibleForReporting =
        selector(state, AccommodationNotificationFormFieldName.IsSomeoneElseResponsibleForReporting) === true
    const handleDateTimeRangeChange = (value: [Dayjs | null, Dayjs | null]) => {
        const [from, to] = value
        change(AccommodationNotificationFormFieldName.DateFrom, from)
        change(AccommodationNotificationFormFieldName.DateTo, to)
    }
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <CubitYesNoRadio
                        name={AccommodationNotificationFormFieldName.IsSomeoneElseResponsibleForReporting}
                        label={tr(TEXT_IS_SOMEONE_RESPONSIBLE_FOR_REPORTING)}
                        validate={ValidatorRequired}
                    />
                </Grid>
            </Grid>

            {isSomeoneResponsibleForReporting && (
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12}>
                            <Field
                                name={AccommodationNotificationFormFieldName.ResponsibleReporterName}
                                label={tr(TEXT_NAME)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={ValidatorRequired}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                name={AccommodationNotificationFormFieldName.ResponsibleReporterEmail}
                                label={tr(TEXT_EMAIL)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={ValidatorRequired}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                name={AccommodationNotificationFormFieldName.ResponsibleReporterMobile}
                                label={tr(TEXT_MOBILE_NUMBER)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={ValidatorRequired}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <br />

            <ContentSectionHeading>{tr(TEXT_ACCOMMODATION)}</ContentSectionHeading>

            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Field
                        name={AccommodationNotificationFormFieldName.Location}
                        label={tr(TEXT_SEARCH_BY_ADDRESS_OR_BUILDING)}
                        component={CubitGeosuggestAdapter}
                        validate={ValidatorRequired}
                        required
                    />
                </Grid>

                <Grid item>
                    <DateTimeRangePicker
                        from={selector(state, AccommodationNotificationFormFieldName.DateFrom) || dayjs()}
                        to={selector(state, AccommodationNotificationFormFieldName.DateTo) || dayjs()}
                        onChange={handleDateTimeRangeChange}
                    />
                </Grid>
            </Grid>
        </form>
    )
}

export const AccommodationNotificationForm1 = reduxForm<{}, AccommodationNotificationFormProps>({
    form: FireFormName.AccommodationNotification,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
