import React, { useState, useEffect } from 'react'
import { CubitStepperStep } from './cubit-stepper-step.type'
import { Stepper, Step, StepLabel, Button, Grid, CircularProgress } from '@mui/material'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import { tr } from 'translations/translate'
import { ContentDivider } from 'common/content-divider/content-divider'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import AdjustIcon from '@mui/icons-material/Adjust'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { ContentSection } from 'common/content-section/content-section'
import { TEXT_BACK, TEXT_NEXT, TEXT_SUBMIT, TEXT_STEP } from 'translations/keys'
import { ButtonContained } from 'common/button-contained/button-contained'
import { ButtonGoBack } from 'common/button-go-back/button-go-back'
import { isInvalid, submit, isSubmitting, destroy } from 'redux-form'
import { useSelector, useDispatch } from 'react-redux'
import { useFormRegisteredFields } from 'fire/fire-selectors'
import { useIsAuthenticated } from '../../app/app-selectors'
import { Authorization } from '../../login-page/authorization'
import { styled } from '@mui/system'

const DEFAULT_ACTIVE_STEP = 0

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
            opacity: 1,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
            opacity: 1,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.primary.main,
        borderTopWidth: 3,
        borderRadius: 1,
        opacity: 0.5,
    },
}))
const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.primary.main,
}))
const StyledAdjustIcon = styled(AdjustIcon)(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.primary.main,
}))
const StyledRadioButtonUncheckedIcon = styled(RadioButtonUncheckedIcon)(({ theme }) => ({
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.primary.main,
    opacity: 0.5,
}))
const StyledQontoRootContainer = styled('div')(({ theme }) => ({
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
}))
const StyledStepper = styled(Stepper)(({ theme }) => ({
    backgroundColor: 'transparent',
    padding: theme.spacing(2),
}))

const QontoStepIcon = (props: any) => {
    const { active, completed } = props

    return (
        <StyledQontoRootContainer>
            {completed ? (
                <StyledCheckCircleOutlineIcon />
            ) : active ? (
                <StyledAdjustIcon />
            ) : (
                <StyledRadioButtonUncheckedIcon />
            )}
        </StyledQontoRootContainer>
    )
}

export const CubitStepper: React.FC<{
    formName: string
    steps: CubitStepperStep[]
    withIdPortalAuthentication?: boolean
}> = (props) => {
    const { formName, steps, withIdPortalAuthentication = true } = props
    const dispatch = useDispatch()
    const fireError = useSelector((state) => (state as any).fire.error)

    useEffect(() => {
        return () => {
            dispatch(destroy(formName))
        }
    }, [dispatch, formName])

    const isFormInvalid = useSelector(isInvalid(formName))
    const isFormSubmitting = useSelector(isSubmitting(formName))
    const isFormWithFields = useFormRegisteredFields(formName)
    const authenticated = useIsAuthenticated()

    const nextDisabled = isFormInvalid || !isFormWithFields

    const [activeStep, setActiveStep] = useState(DEFAULT_ACTIVE_STEP)
    console.log(activeStep)

    const handleStepNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleStepPrevious = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const handleSubmitClick = () => {
        dispatch(submit(formName))
    }

    if (!authenticated && withIdPortalAuthentication) {
        return <Authorization />
    }

    return (
        <>
            <StyledStepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                            <div>
                                {index === activeStep && (
                                    <div
                                        role="progressbar"
                                        aria-valuenow={index}
                                        aria-valuemin={0}
                                        aria-valuetext={`${tr(TEXT_STEP)} ${index}: ${tr(
                                            step.stepLabelTranslationKey,
                                        )}`}
                                        aria-valuemax={steps.length - 1}
                                    >
                                        {tr(step.stepLabelTranslationKey)}
                                    </div>
                                )}
                                {index !== activeStep && <>{tr(step.stepLabelTranslationKey)}</>}
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </StyledStepper>

            <ContentDivider />

            <ContentSection>{activeStep < steps.length && steps[activeStep].component}</ContentSection>

            <ContentDivider />

            <ContentSection>
                <>
                    {fireError && (
                        <div className="error" role="alert" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {tr(fireError.message)}
                        </div>
                    )}
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <ButtonGoBack />
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    {activeStep !== 0 && <Button onClick={handleStepPrevious}>{tr(TEXT_BACK)}</Button>}
                                </Grid>
                                <Grid item>
                                    {activeStep === steps.length - 1 ? (
                                        <ButtonContained
                                            color="secondary"
                                            onClick={handleSubmitClick}
                                            disabled={isFormInvalid || isFormSubmitting}
                                        >
                                            {isFormSubmitting && (
                                                <CircularProgress
                                                    size="16px"
                                                    style={{ position: 'absolute', left: 0, right: 0, margin: 'auto' }}
                                                />
                                            )}
                                            <span style={isFormSubmitting ? { opacity: 0.5 } : {}}>
                                                {tr(TEXT_SUBMIT)}
                                            </span>
                                        </ButtonContained>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleStepNext}
                                            disabled={nextDisabled}
                                        >
                                            {tr(TEXT_NEXT)}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            </ContentSection>
        </>
    )
}
