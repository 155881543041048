import React from 'react'
import { Typography, styled } from '@mui/material'
import { TypographyProps } from '@mui/material/Typography'

const StyledHeading = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    color: theme.palette.common.black,
    padding: theme.spacing(2.5, 0, 2, 0),
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 0, 1.5, 0),
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5, 0, 1, 0),
    },
    '&:nth-of-type(1)': {
        paddingTop: 0,
    },
    fontSize: '1.2em',
}))

export const ContentSectionHeading: React.FC<TypographyProps> = (props) => {
    const { variant = 'h2', children } = props
    return <StyledHeading variant={variant}>{children}</StyledHeading>
}
