import React, { useState } from 'react'
import { FormControlLabel, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'

import { CubitCheckboxAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-checkbox-adapter'
import { Field } from 'redux-form'

const CheckboxContainer = styled(Grid)(({ theme }) => ({
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(1),
    },
}))

const ContentHidden = styled(Grid)(() => ({
    height: '0px',
    overflow: 'hidden',
    zIndex: -1,
}))

export const ConditionalFormContent: React.FC<{ label: string; fieldName: string }> = (props) => {
    const { label, fieldName, children } = props
    const [contentVisible, setContentVisible] = useState(false)

    const handleChange = (e: any) => {
        setContentVisible(e.target.checked)
    }
    return (
        <Grid container>
            <CheckboxContainer item xs={12}>
                <FormControlLabel
                    label={label}
                    control={<Field name={fieldName} component={CubitCheckboxAdapter} onChange={handleChange} />}
                />
            </CheckboxContainer>

            {contentVisible && (
                <Grid item xs={12}>
                    {children}
                </Grid>
            )}
            {!contentVisible && (
                <ContentHidden item xs={12}>
                    {children}
                </ContentHidden>
            )}
        </Grid>
    )
}
