import React from 'react'
import { Grid } from '@mui/material'
import { AdditionalComment } from 'common/additional-comment/additional-comment'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'

export const ComplaintCauseOther: React.FC<{ fieldName: string }> = props => {
    const { fieldName } = props
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <AdditionalComment name={`${fieldName}.comment`} required />
            </Grid>

            <Grid item xs={12}>
                <CubitFileUpload name={`${fieldName}.files`} />
            </Grid>
        </Grid>
    )
}
