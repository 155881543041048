import React from 'react'
import { Typography, styled } from '@mui/material'

const Heading = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black,
    padding: theme.spacing(3, 5),
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 3),
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.5, 2),
    },
    fontSize: '1.7142857142857142rem',
    fontWeight: 400,
}))

export const ContentHeading: React.FC = (props) => {
    return <Heading variant="h1">{props.children}</Heading>
}
