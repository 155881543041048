import React, { useState } from 'react'
import { reduxForm, Field, InjectedFormProps, change } from 'redux-form'
import { tr } from 'translations/translate'
import { TEXT_CONTACT_PHONE, TEXT_CONTACT_EMAIL, TEXT_LIABILITY_DOCUMENTATION } from 'translations/keys'
import { Grid } from '@mui/material'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { FireFormName } from 'fire/enums/form-name.enum'
import { ChimneyInstallationFormFieldName } from './chimney-installation-form-field-name.enum'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import { ContentSectionContainer } from 'common/content-section-container/content-section-container'
import { CompanyAutocomplete } from 'fire/form-components/company-autocomplete'
import { CompanyInfo } from 'fire/components/company-info'
import { Company } from 'tax/types/company.type'
import { useDispatch } from 'react-redux'
import { CaseNumberSelect } from '../../form-components/case-number-select'

type ChimneyInstallationFormProps = {}

const Form: React.FC<InjectedFormProps<any, ChimneyInstallationFormProps> & ChimneyInstallationFormProps> = (props) => {
    const { handleSubmit } = props

    const [company, setCompany] = useState<Company | null>(null)
    const dispatch = useDispatch()
    const handleCompanyChange = (company: Company) => {
        setCompany(company)
        dispatch(change(FireFormName.ChimneyInstallation, ChimneyInstallationFormFieldName.CompanyName, company.name))
        dispatch(
            change(
                FireFormName.ChimneyInstallation,
                ChimneyInstallationFormFieldName.CompanyOrgNr,
                company.organizationNumber.toString(),
            ),
        )
        dispatch(
            change(
                FireFormName.ChimneyInstallation,
                ChimneyInstallationFormFieldName.CompanyAddress,
                company.postalAddress.addressText ?? company.visitingAddress.addressText,
            ),
        )
        dispatch(
            change(
                FireFormName.ChimneyInstallation,
                ChimneyInstallationFormFieldName.CompanyPostalCode,
                company.postalAddress.postalCode ?? company.visitingAddress.postalCode,
            ),
        )
    }
    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <Field component={() => null} name={ChimneyInstallationFormFieldName.CompanyName} />
            <Field component={() => null} name={ChimneyInstallationFormFieldName.CompanyAddress} />
            <Field component={() => null} name={ChimneyInstallationFormFieldName.CompanyOrgNr} />
            <Field component={() => null} name={ChimneyInstallationFormFieldName.CompanyPostalCode} />

            <ContentSectionContainer>
                <CaseNumberSelect />
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={6}>
                                <CompanyAutocomplete
                                    isNational={true}
                                    name={ChimneyInstallationFormFieldName.CompanyId}
                                    valueChanged={(company) => {
                                        handleCompanyChange(company)
                                    }}
                                    valueSetter={(value) => value.id}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {company && (
                        <Grid item xs={12}>
                            <CompanyInfo company={company} />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={12} md={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Field
                                    name={ChimneyInstallationFormFieldName.ContactEmail}
                                    label={tr(TEXT_CONTACT_EMAIL)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name={ChimneyInstallationFormFieldName.ContactPhoneNumber}
                                    label={tr(TEXT_CONTACT_PHONE)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ContentSectionContainer>

            <ContentSectionHeading>{tr(TEXT_LIABILITY_DOCUMENTATION)}</ContentSectionHeading>

            <ContentSectionContainer>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <CubitFileUpload name={ChimneyInstallationFormFieldName.LiabilityFiles} />
                        </Grid>
                    </Grid>
                </Grid>
            </ContentSectionContainer>
        </form>
    )
}

export const ChimneyInstallationForm1 = reduxForm<{}, ChimneyInstallationFormProps>({
    form: FireFormName.ChimneyInstallation,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
