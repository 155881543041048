import React, { useEffect } from 'react'
import { reduxForm, Field, formValueSelector, InjectedFormProps, change } from 'redux-form'
import { tr } from 'translations/translate'
import {
    TEXT_WHICH_CHIMNEY_IS_THE_FIREPLACE_ASSOCIATED_WITH,
    TEXT_CHIMNEY,
    TEXT_OTHER_CHIMNEY_UNCERTAIN,
    TEXT_DESCRIPTION,
    TEXT_CHIMNEY_ASSOCIATION_TEXT,
    TEXT_ARE_FIREPLACES_REMOVED,
    TEXT_WHICH_ADDRESS_APPLIES_TO_THE_INSTALLATION_SITE,
    TEXT_ASSOCIATED_FIREPLACE,
    TEXT_EMAIL,
} from 'translations/keys'
import { Grid, FormControlLabel } from '@mui/material'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'app/app-store'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { find, filter } from 'lodash'
import { HousingUnit } from 'tax/types/housing-unit.type'
import { CubitCheckboxAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-checkbox-adapter'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { CubitCheckboxGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-checkbox-group-adapter'
import { Fireplace } from 'tax/types/fireplace.type'
import { HousingUnitSelect } from 'fire/form-components/housing-unit-select'
import { FireplaceInstallationFormFieldName } from './fireplace-installation-form-field-name.enum'
import { FormFieldName } from '../form-field-name.enum'
import { Chimney } from 'fire/types/chimney.type'
import { FireFormName } from 'fire/enums/form-name.enum'
import { actionFireGetCadastreUnit } from 'fire/fire-actions'
import { useFireCadastreUnit } from 'fire/fire-selectors'
import { OwnerSelect } from 'fire/form-components/owner-select'
import { CaseNumberSelect } from '../../form-components/case-number-select'
import { GridContainerOverride } from 'app/app'

const Form: React.FC<InjectedFormProps> = (props) => {
    const { handleSubmit } = props
    const dispatch = useDispatch()

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.FireplaceInstallation)
    const ownerId = selector(state, FireplaceInstallationFormFieldName.OwnerId)

    useEffect(() => {
        ownerId && dispatch(actionFireGetCadastreUnit(ownerId))
    }, [dispatch, ownerId])

    const cadastreUnit = useFireCadastreUnit()

    const chimneys = cadastreUnit ? cadastreUnit.chimneys : []

    const housingUnits = (cadastreUnit && cadastreUnit.housingUnits) || []

    const isOtherChimneySelected = selector(state, FireplaceInstallationFormFieldName.OtherChimney)
    const selectedAssociatedChimneyId = selector(state, FireplaceInstallationFormFieldName.AssociatedChimneyId)
    const selectedHousingUnitId: any = selector(state, FormFieldName.HousingUnitId)
    const selectedHousingUnit: HousingUnit | undefined = find(housingUnits, { id: selectedHousingUnitId })

    const selectedChimneyFireplaces =
        (selectedHousingUnit &&
            filter(selectedHousingUnit.fireplaces, (f) => f.chimneyId === selectedAssociatedChimneyId)) ||
        []

    const selectedChimneyFireplacesOptions = selectedChimneyFireplaces
        ? selectedChimneyFireplaces.map((f) => ({
              label: `${f.description || 'ildsted'} ${f.location || ''} ${f.type || ''}`,
              value: f.id,
          }))
        : []

    const selectedHousingUnitChimneyOptions = selectedHousingUnit
        ? chimneys
              .filter((c) => selectedHousingUnit.chimneyIds.indexOf(c.id) !== -1)
              .map((c) => ({
                  label: getChimneyLabel(selectedHousingUnit, c),
                  value: c.id,
              }))
        : []

    const handleOtherChimneyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selected = e.target.checked

        if (selected) {
            dispatch(
                change(
                    FireFormName.FireplaceInstallation,
                    FireplaceInstallationFormFieldName.AssociatedChimneyId,
                    undefined,
                ),
            )
            dispatch(
                change(
                    FireFormName.FireplaceInstallation,
                    FireplaceInstallationFormFieldName.RemovedFireplacesIds,
                    undefined,
                ),
            )
        } else {
            dispatch(
                change(
                    FireFormName.FireplaceInstallation,
                    FireplaceInstallationFormFieldName.OtherChimneyDescription,
                    undefined,
                ),
            )
        }
    }

    return (
        <form onSubmit={handleSubmit}>
            <CaseNumberSelect />
            <OwnerSelect
                name={FireplaceInstallationFormFieldName.OwnerId}
                formName={FireFormName.FireplaceInstallation}
            />
            <Grid container spacing={4} style={GridContainerOverride}>
                <Grid item xs={12} sm={12} md={6}>
                    <Field
                        name={FormFieldName.ContactEmail}
                        label={tr(TEXT_EMAIL)}
                        placeholder={tr(TEXT_EMAIL)}
                        component={CubitTextFieldAdapter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4} style={GridContainerOverride}>
                <Grid item xs={12} sm={12} md={6}>
                    <HousingUnitSelect
                        disabled={!cadastreUnit || !housingUnits}
                        housingUnits={housingUnits}
                        formName={FireFormName.FireplaceInstallation}
                        label={tr(TEXT_WHICH_ADDRESS_APPLIES_TO_THE_INSTALLATION_SITE)}
                    />
                </Grid>

                {selectedHousingUnit && (
                    <Grid item xs={12}>
                        <label>{tr(TEXT_WHICH_CHIMNEY_IS_THE_FIREPLACE_ASSOCIATED_WITH)}</label>
                        {!isOtherChimneySelected && (
                            <Field
                                name={FireplaceInstallationFormFieldName.AssociatedChimneyId}
                                component={CubitRadioGroupAdapter}
                                options={selectedHousingUnitChimneyOptions}
                                validate={ValidatorRequired}
                                required
                            />
                        )}
                        <div>
                            <FormControlLabel
                                label={tr(TEXT_OTHER_CHIMNEY_UNCERTAIN)}
                                control={
                                    <Field
                                        name={FireplaceInstallationFormFieldName.OtherChimney}
                                        component={CubitCheckboxAdapter}
                                        onChange={handleOtherChimneyChange}
                                    />
                                }
                            />
                        </div>
                        {isOtherChimneySelected && (
                            <Field
                                name={FireplaceInstallationFormFieldName.OtherChimneyDescription}
                                label={tr(TEXT_DESCRIPTION)}
                                placeholder={tr(TEXT_CHIMNEY_ASSOCIATION_TEXT)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={ValidatorRequired}
                                required
                            />
                        )}
                    </Grid>
                )}

                {selectedAssociatedChimneyId && (
                    <>
                        <Grid item xs={12}>
                            <label>{tr(TEXT_ARE_FIREPLACES_REMOVED)}</label>
                            <Field
                                name={FireplaceInstallationFormFieldName.RemovedFireplacesIds}
                                component={CubitCheckboxGroupAdapter}
                                valueIsObject={false}
                                options={selectedChimneyFireplacesOptions}
                                row={false}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </form>
    )
}

export const FireplaceInstallationForm1 = reduxForm({
    form: FireFormName.FireplaceInstallation,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)

export const getChimneyLabel = (
    housingUnit: HousingUnit | undefined,
    c: Chimney | undefined,
    fireplace: boolean = true,
): React.ReactNode => {
    return (
        <Grid container style={{ padding: '8px 0' }}>
            {c && (
                <>
                    <Grid item xs={12}>
                        {tr(TEXT_CHIMNEY)}: {c.type} {c.location && `, ${c.location}`}
                    </Grid>
                    {fireplace &&
                        getChimneyFireplaces(housingUnit, c.id).map((f, i) => (
                            <Grid key={i} item xs={12}>
                                {tr(TEXT_ASSOCIATED_FIREPLACE)} : {f.description}
                            </Grid>
                        ))}
                </>
            )}
        </Grid>
    )
}

export const getChimneyFireplaces = (housingUnit: HousingUnit | undefined, chimneyId: string): Fireplace[] => {
    if (housingUnit) {
        return filter(housingUnit.fireplaces, (f) => f.chimneyId === chimneyId)
    } else {
        return []
    }
}
