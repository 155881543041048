import React from 'react'
import { reduxForm } from 'redux-form'
import { styled } from '@mui/material'
import { FormBuilderFields } from '../mapping-case/FormBuilderFields'

type Props = {
    formData: any
    form: string
}
const FormContainer = styled('div')(({ theme }) => ({
    padding: '16px 40px',
}))
export const Form: React.FC<Props> = ({ formData, form }) => {
    let data = { formId: form, formTitle: formData.name, fieldGroups: JSON.parse(formData.formBuilderData) }
    return (
        <FormContainer>
            <form onSubmit={(v) => console.log(v)}>{data && <FormBuilderFields data={data} />}</form>
        </FormContainer>
    )
}

export const PostalForm = reduxForm<{}, Props>({
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
