import React, { useState } from 'react'
import { change, Field, InjectedFormProps, reduxForm } from 'redux-form'
import { Grid } from '@mui/material'
import { FireFormName } from 'fire/enums/form-name.enum'
import { FireHydrantFormFieldName } from './fire-hydrant-form-field-name.enum'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { CompanyAutocomplete } from '../../form-components/company-autocomplete'
import { PyrotechnicsSaleFormFieldName } from '../pyrotechnics-sale/pyrotechnics-sale-form-field-name.enum'
import { CompanyInfo, getCompanyFullAddress } from '../../components/company-info'
import { Company } from '../../../tax/types/company.type'
import { useDispatch } from 'react-redux'
import { tr } from '../../../translations/translate'
import { TEXT_CONTACT_EMAIL, TEXT_CONTACT_PHONE } from '../../../translations/keys'
import { ScrapValueFormFieldName } from '../scrap-value/scrap-value-form-field-name.enum'
import { ChimneyInstallationFormFieldName } from '../chimney-installation/chimney-installation-form-field-name.enum'

const Form: React.FC<InjectedFormProps<any>> = () => {
    const [company, setCompany] = useState<Company | null>(null)
    //FireHydrantFormFieldName.OrganizationName
    // name={FireHydrantFormFieldName.OrganizationNumber}
    const dispatch = useDispatch()
    const handleCompanyChange = (company: Company) => {
        setCompany(company)
        dispatch(change(FireFormName.FireHydrantControl, FireHydrantFormFieldName.OrganizationName, company.name))
        dispatch(
            change(
                FireFormName.FireHydrantControl,
                FireHydrantFormFieldName.OrganizationNumber,
                company.organizationNumber.toString(),
            ),
        )
        dispatch(
            change(
                FireFormName.FireHydrantControl,
                FireHydrantFormFieldName.OrganizationAddress,
                getCompanyFullAddress(company),
            ),
        )
    }
    return (
        <form>
            <Field
                component={() => null}
                name={FireHydrantFormFieldName.OrganizationName}
                validate={ValidatorRequired}
                required
                autoFocus
            />
            <Field
                component={() => null}
                name={FireHydrantFormFieldName.OrganizationNumber}
                validate={ValidatorRequired}
                required
            />
            <Field
                component={() => null}
                name={FireHydrantFormFieldName.OrganizationAddress}
                validate={ValidatorRequired}
                required
            />
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <CompanyAutocomplete
                        isNational={true}
                        name={PyrotechnicsSaleFormFieldName.OrganizationNumber}
                        valueChanged={(company) => handleCompanyChange(company)}
                        valueSetter={(value) => {
                            return value.organizationNumber
                        }}
                    />
                </Grid>
                {company && (
                    <Grid item xs={12}>
                        <CompanyInfo company={company} />
                    </Grid>
                )}

                <Grid item xs={12}></Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field
                                name={FireHydrantFormFieldName.ContactEmail}
                                label={tr(TEXT_CONTACT_EMAIL)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={ValidatorRequired}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                name={FireHydrantFormFieldName.ContactPhone}
                                label={tr(TEXT_CONTACT_PHONE)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={ValidatorRequired}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                name={FireHydrantFormFieldName.InvoiceAddress}
                                label="Faktura adresse, hvis forskjellig fra postadresse"
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                name={FireHydrantFormFieldName.InvoiceNotes}
                                label="Eventuelle merknader faktura"
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export const FireHydrantControlForm2 = reduxForm<{}>({
    form: FireFormName.FireHydrantControl,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
