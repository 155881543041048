import React from 'react'
import { InjectedFormProps, reduxForm } from 'redux-form'
import { OtherFormName } from '../../enums/form-name.enum'
import { Grid } from '@mui/material'
import { CubitFileUpload } from '../../../common/cubit-file-upload/cubit-file-upload'
import { PostalAuditFormFieldName } from './postal-audit-form-field-name.enum'
import { useSelector } from 'react-redux'
import { ContentSectionHeading } from '../../../common/content-section-heading/content-section-heading'
import { haveDocRequest } from './postal-audit-helper'
import { AppState } from 'app/app-store'

const Form: React.FC<InjectedFormProps> = (props) => {
    const { handleSubmit } = props
    const audit = useSelector((state: AppState) => state.other.postalAudit)

    return (
        <form onSubmit={handleSubmit}>
            {haveDocRequest(audit) && (
                <>
                    <ContentSectionHeading>Dokumentasjon</ContentSectionHeading>
                    <Grid item xs={12}>
                        <CubitFileUpload name={PostalAuditFormFieldName.Documentation} />
                    </Grid>
                </>
            )}
        </form>
    )
}

export const PostalAuditForm = reduxForm({
    form: OtherFormName.PostalAudit,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
