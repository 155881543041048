import React from 'react'
import { styled, Typography } from '@mui/material'

const Row = styled('div')({
    maxWidth: 350,
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
})

const Label = styled(Typography)({
    width: 120,
    fontWeight: 600,
})
type Props = {
    label: string
    value: any
}
export const DisplayValue: React.FC<Props> = ({ label, value }) => {
    return (
        <Row>
            <Label variant="body1">{label}</Label>
            <div>{value}</div>
        </Row>
    )
}
