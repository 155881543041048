import React, {useState} from 'react'
import {change, Field, InjectedFormProps, reduxForm} from 'redux-form'
import {Grid} from '@mui/material'
import {FireFormName} from 'fire/enums/form-name.enum'
import {FireHydrantFormFieldName} from './fire-hydrant-form-field-name.enum'
import {CubitTextFieldAdapter} from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import {ValidatorRequired} from 'utils/validators/validator-required'
import {HousingUnitAutocomplete} from "../../form-components/housing-unit-autocomplete";
import {HousingUnitInfo} from "../../components/housing-unit-info";
import {HousingUnitAutocompleteObject} from "../../types/housing-unit-autocomplete-object.type";
import {useDispatch} from "react-redux";

const Form: React.FC<InjectedFormProps<any>> = () => {
    const dispatch = useDispatch()
    const [
        housingUnitAutocompleteObject,
        setHousingUnitAutocompleteObject,
    ] = useState<HousingUnitAutocompleteObject | null>(null)
    const handleHousingUnitChange = (value: HousingUnitAutocompleteObject) => {
        setHousingUnitAutocompleteObject(value)
        if (value) {
            dispatch(change(FireFormName.FireHydrantControl, FireHydrantFormFieldName.MatrikkelUnitId, value.cadastreUnit.id))
            dispatch(change(FireFormName.FireHydrantControl, FireHydrantFormFieldName.AddressText, value.housingUnit.address.addressText))
        } else {
            dispatch(change(FireFormName.FireHydrantControl, FireHydrantFormFieldName.MatrikkelUnitId, undefined))
            dispatch(change(FireFormName.FireHydrantControl, FireHydrantFormFieldName.HousingUnitId, undefined))
        }
    }
    return (
        <form>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <HousingUnitAutocomplete
                        name={FireHydrantFormFieldName.HousingUnitId}
                        valueChanged={value => {
                            handleHousingUnitChange(value)
                        }}
                        valueSetter={value => value.housingUnit.id}
                        autoFocus
                    />
                </Grid>

                {housingUnitAutocompleteObject && (
                    <Grid item xs={12}>
                        <HousingUnitInfo housingUnitObject={housingUnitAutocompleteObject} />
                    </Grid>
                )}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Field
                                name={FireHydrantFormFieldName.WaterOutletName}
                                label="Navn på slukkevannsuttak"
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={ValidatorRequired}
                                required
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}

export const FireHydrantControlForm3 = reduxForm<{}>({
    form: FireFormName.FireHydrantControl,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
