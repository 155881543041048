import React from 'react'
import LayoutDefault from 'common/layout-default/layout-default'
import { ContentContainer } from 'common/content-container/content-container'
import { tr } from 'translations/translate'
import { TEXT_USER_NOT_FOUND } from 'translations/keys'
import { Grid, styled } from '@mui/material'
import { FireLogo } from 'fire/fire-logo/fire-logo'
import { FireSignOut } from '../fire/fire-sign-out/fire-sign-out'

const Heading = styled(Grid)(({ theme }) => ({
    fontSize: theme.typography.h4.fontSize,
    padding: theme.spacing(4, 2),
    letterSpacing: '1.1px',
    fontWeight: 300,
}))

export const UserNotFound: React.FC<{}> = (props) => {
    return (
        <LayoutDefault logo={<FireLogo />} rightElement={<FireSignOut />}>
            <ContentContainer>
                <Grid container alignItems="center" direction="column">
                    <Heading item>{tr(TEXT_USER_NOT_FOUND)}</Heading>
                </Grid>
            </ContentContainer>
        </LayoutDefault>
    )
}
