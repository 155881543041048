import React, { useEffect } from 'react'
import { ContentSection } from 'common/content-section/content-section'
import { ConcernCaseForm } from './form'
import { useDispatch, useSelector } from 'react-redux'
import { actionFireSubmitForm, actionFireClearCadastreUnit } from 'fire/fire-actions'
import { destroy, startSubmit } from 'redux-form'
import { ContentActions } from 'common/content-actions/content-actions'
import { FireFormName } from 'fire/enums/form-name.enum'
import { FireFormPageProps } from 'fire/types/fire-form-page-props.type'
import { convertObjectToFormData } from 'utils'
import { useParams } from 'react-router-dom'
import { actionStoreTenantId } from '../../../app/app-actions'
import LayoutDefault from 'common/layout-default/layout-default'
import { FireLogo } from 'fire/fire-logo/fire-logo'
import { ContentContainer } from 'common/content-container/content-container'
import { CircularProgress, Grid } from '@mui/material'
import { ButtonContained } from '../../../common/button-contained/button-contained'
import { isSubmitting, isValid, submit } from 'redux-form'
import { tr } from 'translations/translate'
import { TEXT_SUBMIT, TEXT_CONCERN_CASE } from 'translations/keys'
import { OtherFormName } from '../../enums/form-name.enum'
import { ConcernCaseFormFieldName as FieldName } from './form-field-name.enum'
import { ContentDivider } from 'common/content-divider/content-divider'
import { ContentHeading } from 'common/content-heading/content-heading'
interface RouteParams {
    tenantId: string
}
export const ConcernCase: React.FC<FireFormPageProps> = (props) => {
    const dispatch = useDispatch()

    const { tenantId } = useParams<RouteParams>()

    const isFormSubmitting = useSelector(isSubmitting(OtherFormName.CreateConcern))
    const isFormValid = useSelector(isValid(OtherFormName.CreateConcern))

    useEffect(() => {
        if (tenantId) {
            dispatch(actionStoreTenantId(encodeURIComponent(tenantId)))
        }
    }, [dispatch, tenantId])

    const handleSubmit = (formValue: any) => {
        const formData = convertObjectToFormData(formValue, {})
        dispatch(startSubmit(OtherFormName.CreateConcern))
        dispatch(actionFireSubmitForm(OtherFormName.CreateConcern, formData))
    }
    return (
        <LayoutDefault logo={<FireLogo />}>
            <ContentContainer>
                <ContentHeading>{tr(TEXT_CONCERN_CASE)}</ContentHeading>
                <ContentDivider />
                <ContentSection>
                    <ConcernCaseForm
                        onSubmit={handleSubmit}
                        initialValues={{
                            type: 'FIRE_CONCERN_CASE',
                            [FieldName.IsAnonymous]: true,
                            Description: '\n\n',
                        }}
                    />
                    <ContentSection>
                        <Grid item>
                            <Grid container spacing={2} justifyContent="flex-end">
                                <Grid item>
                                    <ButtonContained
                                        color="secondary"
                                        onClick={() => dispatch(submit(OtherFormName.CreateConcern))}
                                        disabled={!isFormValid || isFormSubmitting}
                                    >
                                        {isFormSubmitting && (
                                            <CircularProgress
                                                size="16px"
                                                style={{ position: 'absolute', left: 0, right: 0, margin: 'auto' }}
                                            />
                                        )}
                                        <span style={isFormSubmitting ? { opacity: 0.5 } : {}}>{tr(TEXT_SUBMIT)}</span>
                                    </ButtonContained>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ContentSection>
                </ContentSection>
            </ContentContainer>
        </LayoutDefault>
    )
}
