import React from 'react'
import { Tank } from 'fire/types/tank.type'
import { Field } from 'redux-form'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { Grid } from '@mui/material'
import { tr } from 'translations/translate'
import {TEXT_LOCATION, TEXT_TYPE, TEXT_CONTENTS, TEXT_VOLUME, TEXT_ADDRESS} from 'translations/keys'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { HousingUnit } from '../../tax/types/housing-unit.type'

export const HousingUnitTankSelect: React.FC<{ name: string; housingUnit: HousingUnit; tanks: Tank[] }> = props => {
    const { name, tanks, housingUnit } = props
    const housinUnitAddress = housingUnit.address.addressText ? housingUnit.address.addressText.trim().toLowerCase() : ''
    const sameAddress = (tank: Tank) => tank.address.addressText.trim().toLowerCase() === housinUnitAddress

    const sameMattrikelUnit = (tank: Tank) => tank.matrikkelUnitId === housingUnit.matrikkelUnitId
    const tankOptions = housingUnit
        ? tanks
              .filter(sameMattrikelUnit)
              .map(t => ({
                  label: getTankLabel(t),
                  value: t.id,
              }))
        : []

    return (
        <>
            <Field
                name={name}
                component={CubitRadioGroupAdapter}
                options={tankOptions}
                validate={ValidatorRequired}
                required
            />
        </>
    )
}

const getTankLabel = (tank: Tank): React.ReactNode => {
    return (
        <Grid container style={{ padding: '8px 0' }}>
            <Grid item xs={12}>
                <strong>{tr(TEXT_ADDRESS)}:</strong> {tank.address.addressText}
            </Grid>
            <Grid item xs={12}>
                <strong>{tr(TEXT_LOCATION)}:</strong> {tank.positionDescription ? tank.positionDescription : tank.position}
            </Grid>
            <Grid item xs={12}>
                <strong>{tr(TEXT_TYPE)}:</strong> {tank.tankType}
            </Grid>
            <Grid item xs={12}>
                <strong>{tr(TEXT_CONTENTS)}:</strong> {tank.content}
            </Grid>
            <Grid item xs={12}>
                <strong>{tr(TEXT_VOLUME)}:</strong> {tank.volume}
            </Grid>
        </Grid>
    )
}
