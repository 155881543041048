export const TEXT_SUBMIT = 'TEXT_SUBMIT'
export const TEXT_COMMENT = 'TEXT_COMMENT'
export const TEXT_COMMENT_DESCRIPTION = 'TEXT_COMMENT_DESCRIPTION'
export const TEXT_DESCRIPTION = 'TEXT_DESCRIPTION'
export const TEXT_DESCRIBE_REASON_FOR_APPLICATION = 'TEXT_DESCRIBE_REASON_FOR_APPLICATION'
export const TEXT_UPLOAD = 'TEXT_UPLOAD'
export const TEXT_UPLOAD_DOCUMENTATION = 'TEXT_UPLOAD_DOCUMENTATION'
export const TEXT_NEXT = 'TEXT_NEXT'
export const TEXT_BACK = 'TEXT_BACK'
export const TEXT_CANCEL = 'TEXT_CANCEL'
export const TEXT_YES = 'TEXT_YES'
export const TEXT_NO = 'TEXT_NO'
export const TEXT_NAME = 'TEXT_NAME'
export const TEXT_BUSINESS = 'TEXT_BUSINESS'
export const TEXT_COMPANY = 'TEXT_COMPANY'
export const TEXT_EXECUTIVE_COMPANY = 'TEXT_EXECUTIVE_COMPANY'
export const TEXT_ADDRESS = 'TEXT_ADDRESS'
export const TEXT_ZIP_CODE = 'TEXT_ZIP_CODE'
export const TEXT_DATE_FROM = 'TEXT_DATE_FROM'
export const TEXT_DATE_TO = 'TEXT_DATE_TO'
export const TEXT_DATE_TIME = 'TEXT_DATE_TIME'
export const TEXT_EMAIL = 'TEXT_EMAIL'
export const TEXT_NO_RESULTS = 'TEXT_NO_RESULTS'
export const TEXT_LOADING = 'TEXT_LOADING'
export const TEXT_OWNER = 'TEXT_OWNER'
export const TEXT_REMOVE = 'TEXT_REMOVE'

//#region TAX
export const TEXT_RESIDENTIAL_PROPERTY = 'TEXT_RESIDENTIAL_PROPERTY'
export const TEXT_LEISURE_PROPERTY = 'TEXT_LEISURE_PROPERTY'
export const TEXT_FARMING_FORESTRY_WITH_HOUSING = 'TEXT_FARMING_FORESTRY_WITH_HOUSING'
export const TEXT_FARM_USE_FORESTRY_WITH_LEISURE = 'TEXT_FARM_USE_FORESTRY_WITH_LEISURE'
export const TEXT_FARMS_OR_FORESTRY = 'TEXT_FARMS_OR_FORESTRY'
export const TEXT_NOT_SELECTED = 'TEXT_NOT_SELECTED'
export const TEXT_COMBINED_BUILT_PLOT = 'TEXT_COMBINED_BUILT_PLOT'
export const TEXT_COMMERCIAL = 'TEXT_COMMERCIAL'
export const TEXT_UNDEVELOPED_PLOT_HOUSING = 'TEXT_UNDEVELOPED_PLOT_HOUSING'
export const TEXT_UNDEVELOPED_PLOT_LEISURE = 'TEXT_UNDEVELOPED_PLOT_LEISURE'
export const TEXT_UNDEVELOPED_PLOT_INDUSTRY = 'TEXT_UNDEVELOPED_PLOT_INDUSTRY'
export const TEXT_UNDEVELOPED_PLOT = 'TEXT_UNDEVELOPED_PLOT'
export const TEXT_CORRECTION_OF_FACTS_AND_SUBMISSION_OF_APPEAL_AGAINST_PROPERTY_TAX =
    'TEXT_CORRECTION_OF_FACTS_AND_SUBMISSION_OF_APPEAL_AGAINST_PROPERTY_TAX'
export const TEXT_TAX_COMPLAINT_CAUSE_1 = 'TEXT_TAX_COMPLAINT_CAUSE_1'
export const TEXT_TAX_COMPLAINT_CAUSE_2 = 'TEXT_TAX_COMPLAINT_CAUSE_2'
export const TEXT_TAX_COMPLAINT_CAUSE_3 = 'TEXT_TAX_COMPLAINT_CAUSE_3'
export const TEXT_TAX_COMPLAINT_CAUSE_4 = 'TEXT_TAX_COMPLAINT_CAUSE_4'
export const TEXT_TAX_COMPLAINT_CAUSE_5 = 'TEXT_TAX_COMPLAINT_CAUSE_5'
export const TEXT_TAX_COMPLAINT_CAUSE_6 = 'TEXT_TAX_COMPLAINT_CAUSE_6'
export const TEXT_TAX_COMPLAINT_CAUSE_7 = 'TEXT_TAX_COMPLAINT_CAUSE_7'
export const TEXT_REGISTERED_SITE_USE = 'TEXT_REGISTERED_SITE_USE'
export const TEXT_PROPER_SITE_USE = 'TEXT_PROPER_SITE_USE'
export const TEXT_REGISTERED_AREA = 'TEXT_REGISTERED_AREA'
export const TEXT_CORRECT_AREA = 'TEXT_CORRECT_AREA'
export const TEXT_CHOOSE_BUILDING = 'TEXT_CHOOSE_BUILDING'
export const TEXT_ADD_BUILDING = 'TEXT_ADD_BUILDING'
export const TEXT_LOOK_AT_MAP = 'TEXT_LOOK_AT_MAP'
export const TEXT_SELECT_PHOTOS_THAT_ARE_FROM_WRONG_PROPERTY = 'TEXT_SELECT_PHOTOS_THAT_ARE_FROM_WRONG_PROPERTY'
export const TEXT_FLOOR = 'TEXT_FLOOR'
export const TEXT_USE = 'TEXT_USE'
export const TEXT_AREA = 'TEXT_AREA'
export const TEXT_REQUIRED = 'TEXT_REQUIRED'
export const TEXT_VALID_EMAIL = 'TEXT_VALID_EMAIL'
export const TEXT_VALID_PHONE = 'TEXT_VALID_PHONE'

//#endregion

//#region FIRE
export const TEXT_FIREPLACE_INSTALLATION_NOTICE = 'TEXT_FIREPLACE_INSTALLATION_NOTIFICATION'
export const TEXT_FIREPLACE_REMOVAL_NOTICE = 'TEXT_FIREPLACE_REMOVAL_NOTICE'
export const TEXT_APPLICATION_FOR_SCRAP_VALUE = 'TEXT_APPLICATION_FOR_SCRAP_VALUE'
export const TEXT_NOTICE_OF_REHABILITATION_NEW_CHIMNEY = 'TEXT_NOTICE_OF_REHABILITATION_OF_CHIMNEY_NEW_CHIMNEY'
export const TEXT_APPLICATION_FOR_TANK_REMOVAL_EXEMPTION = 'TEXT_APPLICATION_FOR_TANK_REMOVAL_EXEMPTION'
export const TEXT_NOTICE_OF_TANK_REMOVAL = 'TEXT_NOTICE_OF_TANK_REMOVAL'
export const TEXT_NOTICE_OF_ACCOMMODATION = 'TEXT_NOTICE_OF_ACCOMMODATION'
export const TEXT_NOTICE_OF_EVENT = 'TEXT_NOTICE_OF_EVENT'
export const TEXT_APPLICATION_FOR_USE_OF_PYROTECHNICS = 'TEXT_APPLICATION_FOR_USE_OF_PYROTECHNICS'
export const TEXT_APPLICATION_FOR_SALE_OF_PYROTECHNICS = 'TEXT_APPLICATION_FOR_SALE_OF_PYROTECHNICS'
export const TEXT_APPLICATION_FOR_STORAGE_OF_SMOKELESS_GUNPOWDER = 'TEXT_APPLICATION_FOR_STORAGE_OF_SMOKELESS_GUNPOWDER'
export const TEXT_APPLICATION_FOR_CONTROL_OF_FIREHYDRANT = 'TEXT_APPLICATION_FOR_CONTROL_OF_FIREHYDRANT'

export const TEXT_INSTALLATION_LOCATION = 'TEXT_INSTALLATION_LOCATION'
export const TEXT_REMOVAL_LOCATION = 'TEXT_REMOVAL_LOCATION'
export const TEXT_THE_NEW_FIREPLACE = 'TEXT_THE_NEW_FIREPLACE'
export const TEXT_THE_INSTALLATION = 'TEXT_THE_INSTALLATION'

export const TEXT_PRODUCT_NAME = 'TEXT_PRODUCT_NAME'
export const TEXT_PRODUCT_NAME_IS_THE_MAKE_AND_MODEL_OF_THE_FIREPLACE =
    'TEXT_PRODUCT_NAME_IS_THE_MAKE_AND_MODEL_OF_THE_FIREPLACE'
export const TEXT_FUEL_TYPE = 'TEXT_FUEL_TYPE'
export const TEXT_WOOD = 'TEXT_WOOD'
export const TEXT_INSTALLATION_DATE = 'TEXT_INSTALLATION_DATE'
export const TEXT_WHICH_ADDRESS_APPLIES_TO_THE_INSTALLATION_SITE = 'TEXT_WHICH_ADDRESS_APPLIES_TO_THE_INSTALLATION_SITE'
export const TEXT_ADDRESS_WHERE_FIREPLACE_IS_REMOVED = 'TEXT_ADDRESS_WHERE_FIREPLACE_IS_REMOVED'
export const TEXT_WHICH_CHIMNEY_IS_THE_FIREPLACE_ASSOCIATED_WITH = 'TEXT_WHICH_CHIMNEY_IS_THE_FIREPLACE_ASSOCIATED_WITH'
export const TEXT_ASSOCIATED_FIREPLACE = 'TEXT_ASSOCIATED_FIREPLACE'
export const TEXT_OTHER_CHIMNEY_UNCERTAIN = 'TEXT_OTHER_CHIMNEY_UNCERTAIN'
export const TEXT_ARE_FIREPLACES_REMOVED = 'TEXT_ARE_FIREPLACES_REMOVED'
export const TEXT_HAVE_YOU_INSTALLED_THE_FIREPLACE_YOURSELF = 'TEXT_HAVE_YOU_INSTALLED_THE_FIREPLACE_YOURSELF'
export const TEXT_NEW_FIREPLACE_INSTALLATION_2 = 'TEXT_NEW_FIREPLACE_INSTALLATION_2'
export const TEXT_CHIMNEY = 'TEXT_CHIMNEY'
export const TEXT_FIREPLACE_NAME = 'TEXT_FIREPLACE_NAME'
export const TEXT_CHIMNEY_ASSOCIATION_TEXT = 'TEXT_CHIMNEY_ASSOCIATION_TEXT'
export const TEXT_LIQUID_AND_GAS = 'TEXT_LIQUID_AND_GAS'

export const TEXT_WE_HAVE_FOLLOWING_FIREPLACES = 'TEXT_WE_HAVE_FOLLOWING_FIREPLACES'
export const TEXT_WHICH_FIREPLACE_IS_REMOVED = 'TEXT_WHICH_FIREPLACE_IS_REMOVED'
export const TEXT_ASSOCIATED_CHIMNEY = 'TEXT_ASSOCIATED_CHIMNEY'
export const TEXT_WHICH_FIREPLACE_APPLIES_TO_WRECKAGE = 'TEXT_WHICH_FIREPLACE_APPLIES_TO_WRECKAGE'
export const TEXT_NEW_HEATING_SOURCE = 'TEXT_NEW_HEATING_SOURCE'
export const TEXT_DOCUMENTATION = 'TEXT_DOCUMENTATION'
export const TEXT_SELECT_THE_HEATING_SOURCE = 'TEXT_SELECT_THE_HEATING_SOURCE'

export const TEXT_CLEAN_BURNING_FIREPLACE = 'TEXT_CLEAN_BURNING_FIREPLACE'
export const TEXT_HEAT_PUMP = 'TEXT_HEAT_PUMP'
export const TEXT_SOLAR = 'TEXT_SOLAR'
export const TEXT_DISTRICT_HEATING = 'TEXT_DISTRICT_HEATING'

export const TEXT_INSTALLER = 'TEXT_INSTALLER'
export const TEXT_MOBILE_NUMBER = 'TEXT_MOBILE_NUMBER'
export const TEXT_INSTALLERS_ADDRESS = 'TEXT_INSTALLERS_ADDRESS'
export const TEXT_NORWEGIAN_ADDRESS = 'TEXT_NORWEGIAN_ADDRESS'
export const TEXT_FOREIGN_ADDRESS = 'TEXT_FOREIGN_ADDRESS'

export const TEXT_PROOF_OF_REFUND = 'TEXT_PROOF_OF_REFUND'
export const TEXT_CHECKLIST_AND_VERIFICATION_STATEMENT_BY_QUALIFIED_INSPECTOR =
    'TEXT_CHECKLIST_AND_VERIFICATION_STATEMENT_BY_QUALIFIED_INSPECTOR'

export const TEXT_LOCATION = 'TEXT_LOCATION'
export const TEXT_ABOUT_EVENT = 'TEXT_ABOUT_EVENT'
export const TEXT_RISK_ASSESSMENT = 'TEXT_RISK_ASSESSMENT'
export const TEXT_EVENT_1 = 'TEXT_EVENT_1'
export const TEXT_EVENT_1_2 = 'TEXT_EVENT_1_2'
export const TEXT_EVENT_2 = 'TEXT_EVENT_2'
export const TEXT_EVENT_2_1 = 'TEXT_EVENT_2_1'
export const TEXT_EVENT_2_3 = 'TEXT_EVENT_2_3'
export const TEXT_EVENT_3 = 'TEXT_EVENT_3'
export const TEXT_ORGANIZER = 'TEXT_ORGANIZER'
export const TEXT_COMPANY_NAME = 'TEXT_COMPANY_NAME'
export const TEXT_SEARCH_BY_ADDRESS_OR_BUILDING = 'TEXT_SEARCH_BY_ADDRESS_OR_BUILDING'
export const TEXT_DESCRIBE_LOCATION = 'TEXT_DESCRIBE_LOCATION'
export const TEXT_DESCRIBE_ARRANGEMENT = 'TEXT_DESCRIBE_ARRANGEMENT'
export const TEXT_IS_PYROTECHNICS_WILL_BE_USED = 'TEXT_IS_PYROTECHNICS_WILL_BE_USED'
export const TEXT_IS_FIRE_SERVICE_SAFEGUARDED = 'TEXT_IS_FIRE_SERVICE_SAFEGUARDED'
export const TEXT_APPLICATION_FOR_FIREWORKS_PYROTECHNICS = 'TEXT_APPLICATION_FOR_FIREWORKS_PYROTECHNICS'
export const TEXT_RISK_ASSESSMENT_FOR_EVENT = 'TEXT_RISK_ASSESSMENT_FOR_EVENT'

export const TEXT_PYROTECHNICS_USE_STEP2 = 'TEXT_PYROTECHNICS_USE_STEP2'
export const TEXT_PYROTECHNICS_USE_FORM1_DESCRIPTION = 'TEXT_PYROTECHNICS_USE_FORM1_DESCRIPTION'
export const TEXT_PYROTECHNICS_USE_IS_SENDER_RESPONSIBLE = 'TEXT_PYROTECHNICS_USE_IS_SENDER_RESPONSIBLE'
export const TEXT_PYROTECHNICS_USE_IS_TYPE_FIREWORKS = 'TEXT_PYROTECHNICS_USE_IS_TYPE_FIREWORKS'
export const TEXT_PYROTECHNICS_USE_IS_CONSUMER_TYPE_FIREWORKS = 'TEXT_PYROTECHNICS_USE_IS_CONSUMER_TYPE_FIREWORKS'
export const TEXT_PYROTECHNICS_USE_IS_PROFESSIONAL_TYPE_FIREWORKS =
    'TEXT_PYROTECHNICS_USE_IS_PROFESSIONAL_TYPE_FIREWORKS'
export const TEXT_PYROTECHNICS_USE_IS_STAGE_TYPE_FIREWORKS = 'TEXT_PYROTECHNICS_USE_IS_STAGE_TYPE_FIREWORKS'
export const TEXT_PYROTECHNICS_USE_CERTIFICATE_INFO = 'TEXT_PYROTECHNICS_USE_CERTIFICATE_INFO'
export const TEXT_PYROTECHNICS_USE_CERTIFICATE_NUMBER = 'TEXT_PYROTECHNICS_USE_CERTIFICATE_NUMBER'
export const TEXT_PYROTECHNICS_USE_HAS_WRITTEN_SAFETY_INSTRUCTIONS =
    'TEXT_PYROTECHNICS_USE_HAS_WRITTEN_SAFETY_INSTRUCTIONS'
export const TEXT_PYROTECHNICS_LOCATION_DESCRIPTION = 'TEXT_PYROTECHNICS_LOCATION_DESCRIPTION'
export const TEXT_PYROTECHNICS_DESCRIPTION = 'TEXT_PYROTECHNICS_DESCRIPTION'
export const TEXT_PYROTECHNICS_ATTACHMENTS = 'TEXT_PYROTECHNICS_ATTACHMENTS'
export const TEXT_PYROTECHNICS_ATTACHMENTS_BUTTON = 'TEXT_PYROTECHNICS_ATTACHMENTS_BUTTON'
export const TEXT_PYROTECHNICS_DATE = 'TEXT_PYROTECHNICS_DATE'
export const TEXT_PYROTECHNICS_PLACE = 'TEXT_PYROTECHNICS_PLACE'

export const TEXT_ACCOMMODATION = 'TEXT_ACCOMMODATION'
export const TEXT_ABOUT_ACCOMMODATION = 'TEXT_ABOUT_ACCOMMODATION'
export const TEXT_FIRE_SAFETY = 'TEXT_FIRE_SAFETY'
export const TEXT_IS_SOMEONE_RESPONSIBLE_FOR_REPORTING = 'TEXT_IS_SOMEONE_RESPONSIBLE_FOR_REPORTING'
export const TEXT_NUMBER_OF_PEOPLE = 'TEXT_NUMBER_OF_PEOPLE'
export const TEXT_ACCOMMODATION_1 = 'TEXT_ACCOMMODATION_1'
export const TEXT_ACCOMMODATION_2 = 'TEXT_ACCOMMODATION_2'
export const TEXT_ACCOMMODATION_CONFIRM = 'TEXT_ACCOMMODATION_CONFIRM'
export const TEXT_OWNER_CONSENT = 'TEXT_OWNER_CONSENT'
export const TEXT_RESPONSIBLE_CONSENT = 'TEXT_RESPONSIBLE_CONSENT'
export const TEXT_RESPONSIBLE_CAN_FIREGUARD = 'TEXT_RESPONSIBLE_CAN_FIREGUARD'
export const TEXT_AWAKE_NIGHT_WATCH = 'TEXT_AWAKE_NIGHT_WATCH'
export const TEXT_SUFFICIENT_NUMBER_OF_GUARDS = 'TEXT_SUFFICIENT_NUMBER_OF_GUARDS'
export const TEXT_NO_FLAMMABLE_ACTIVITY = 'TEXT_NO_FLAMMABLE_ACTIVITY'
export const TEXT_EVERYONE_KNOWS_ESCAPE_ROUTES = 'TEXT_EVERYONE_KNOWS_ESCAPE_ROUTES'
export const TEXT_ESCAPE_ROUTES_TIDY_AND_ACCESSIBLE = 'TEXT_ESCAPE_ROUTES_TIDY_AND_ACCESSIBLE'
export const TEXT_FIRE_ALARM_SYSTEMS_CHECKED_AND_FUNCTIONING = 'TEXT_FIRE_ALARM_SYSTEMS_CHECKED_AND_FUNCTIONING'
export const TEXT_EXTINGUISHING_EQUIPMENT_CHECKED_AND_FUNCTIONING =
    'TEXT_EXTINGUISHING_EQUIPMENT_CHECKED_AND_FUNCTIONING'
export const TEXT_CONTROL_SYSTEM_CHECKED_AND_FUNCTIONING = 'TEXT_CONTROL_SYSTEM_CHECKED_AND_FUNCTIONING'
export const TEXT_SPRINKLER_SYSTEMS_CHECKED_AND_FUNCTIONING = 'TEXT_SPRINKLER_SYSTEMS_CHECKED_AND_FUNCTIONING'
export const TEXT_ACCOMMODATION_LIMITED_TO_AGREED_BUILDING_PART = 'TEXT_ACCOMMODATION_LIMITED_TO_AGREED_BUILDING_PART'

export const TEXT_APPLICATION_RECEIVED = 'TEXT_APPLICATION_RECEIVED'
export const TEXT_MESSAGE_RECEIVED = 'TEXT_MESSAGE_RECEIVED'
export const TEXT_FIREPLACE_REMOVED = 'TEXT_FIREPLACE_REMOVED'
export const TEXT_APPLY_FOR_PYROTECHNICS = 'TEXT_APPLY_FOR_PYROTECHNICS'
export const TEXT_FIREPLACE_INSTALL_SUCCESS_1 = 'TEXT_FIREPLACE_INSTALL_SUCCESS_1'
export const TEXT_ACCOMMODATION_SUCCESS_1 = 'TEXT_ACCOMMODATION_SUCCESS_1'
export const TEXT_EVENT_SUCCESS_1 = 'TEXT_EVENT_SUCCESS_1'
export const TEXT_SUCCESS_1 = 'TEXT_SUCCESS_1'
export const TEXT_SUCCESS_2 = 'TEXT_SUCCESS_2'
export const TEXT_THANK_YOU_FOR_USING_OUR_SERVICES = 'TEXT_THANK_YOU_FOR_USING_OUR_SERVICES'

export const TEXT_WHAT_IS_THE_ADDRESS = 'TEXT_WHAT_IS_THE_ADDRESS'
export const TEXT_ACCOUNT_NUMBER = 'TEXT_ACCOUNT_NUMBER'
export const TEXT_PAYOUT_ACCOUNT = 'TEXT_PAYOUT_ACCOUNT'

export const TEXT_TYPE = 'TEXT_TYPE'
export const TEXT_CONTENTS = 'TEXT_CONTENTS'
export const TEXT_VOLUME = 'TEXT_VOLUME'

export const TEXT_TANK_EXEMPTION_1 = 'TEXT_TANK_EXEMPTION_1'
export const TEXT_TANK_EXEMPTION_2 = 'TEXT_TANK_EXEMPTION_2'
export const TEXT_TANK_EXEMPTION_REASON = 'TEXT_TANK_EXEMPTION_REASON'

export const TEXT_TANK_DID_LEAK = 'TEXT_TANK_DID_LEAK'
export const TEXT_TANK_PIPES_REMOVED = 'TEXT_TANK_PIPES_REMOVED'
export const TEXT_TANK_REMOVAL_DOCUMENTATION = 'TEXT_TANK_REMOVAL_DOCUMENTATION'

export const TEXT_NOTIFICATION_OF_TANK_REMEDIATION = 'TEXT_NOTIFICATION_OF_TANK_REMEDIATION'
export const TEXT_TANK_REMEDIATION_DESCRIPTION = 'TEXT_TANK_REMEDIATION_DESCRIPTION'
export const TEXT_TANK_REMEDIATION_DOCUMENTATION = 'TEXT_TANK_REMEDIATION_DOCUMENTATION'

export const TEXT_NO_REGISTERED_TANKS = 'TEXT_NO_REGISTERED_TANKS'

export const TEXT_REQUIRED_INFORMATION = 'TEXT_REQUIRED_INFORMATION'
export const TEXT_CONTACT_INFORMATION = 'TEXT_CONTACT_INFORMATION'
export const TEXT_VA_PLANT = 'TEXT_VA_PLANT'
export const TEXT_VA_IS_CONFIRMED = 'TEXT_VA_IS_CONFIRMED'
export const TEXT_VA_LID_AVAILABLE = 'TEXT_VA_LID_AVAILABLE'
export const TEXT_VA_CORRECT_COVER_FITTED = 'TEXT_VA_CORRECT_COVER_FITTED'
export const TEXT_VA_VALVE_PROTECTION_REMOVED = 'TEXT_VA_VALVE_PROTECTION_REMOVED'
export const TEXT_VA_WATER_ON_VALVE = 'TEXT_VA_WATER_ON_VALVE'
export const TEXT_VA_DISTANCE = 'TEXT_VA_DISTANCE'
export const TEXT_VA_WIRED_CAP = 'TEXT_VA_WIRED_CAP'
export const TEXT_ATTACH_MAP_SECTION = 'TEXT_ATTACH_MAP_SECTION'
export const TEXT_MAP_SECTIONS = 'TEXT_MAP_SECTIONS'
export const TEXT_AS_BUILT_DRAWINGS = 'TEXT_AS_BUILT_DRAWINGS'
export const TEXT_DRAWINGS = 'TEXT_DRAWINGS'
export const TEXT_VA_OUTLET_NAME = 'TEXT_VA_OUTLET_NAME'

export const TEXT_CHOOSE_ORGANIZATION = 'TEXT_CHOOSE_ORGANIZATION'
export const TEXT_COMPANY_ORG_NUMBER = 'TEXT_COMPANY_ORG_NUMBER'
export const TEXT_CONTACT_EMAIL = 'TEXT_CONTACT_EMAIL'
export const TEXT_CONTACT_PHONE = 'TEXT_CONTACT_PHONE'
export const TEXT_INVOICE_ADDRESS_IF_DIFFERENT = 'TEXT_INVOICE_ADDRESS_IF_DIFFERENT'
export const TEXT_EVENTUAL_NOTES_INVOICE = 'TEXT_EVENTUAL_NOTES_INVOICE'

export const TEXT_RESPONSIBLE = 'TEXT_RESPONSIBLE'
export const TEXT_PLACE_OF_SALE = 'TEXT_PLACE_OF_SALE'
export const TEXT_HMS_TRAINING = 'TEXT_HMS_TRAINING'
export const TEXT_CONTACT_PERSON = 'TEXT_CONTACT_PERSON'
export const TEXT_SEARCH_BY_ORG_ADDRESS_OR_NAME = 'TEXT_SEARCH_BY_ORG_ADDRESS_OR_NAME'
export const TEXT_PYRO_SALE_1 = 'TEXT_PYRO_SALE_1'
export const TEXT_PYRO_SALE_2 = 'TEXT_PYRO_SALE_2'
export const TEXT_PYRO_SALE_3 = 'TEXT_PYRO_SALE_3'
export const TEXT_PYRO_SALE_PLACE = 'TEXT_PYRO_SALE_PLACE'
export const TEXT_PYRO_SALE_INDOORS = 'TEXT_PYRO_SALE_INDOORS'
export const TEXT_PYRO_SALE_OUTDOORS = 'TEXT_PYRO_SALE_OUTDOORS'
export const TEXT_PYRO_SALE_4 = 'TEXT_PYRO_SALE_4'
export const TEXT_PYRO_SALE_5 = 'TEXT_PYRO_SALE_5'
export const TEXT_OUTLET_LOCATION_SKETCH = 'TEXT_OUTLET_LOCATION_SKETCH'
export const TEXT_SKETCH = 'TEXT_SKETCH'
export const TEXT_PYRO_KEPT = 'TEXT_PYRO_KEPT'
export const TEXT_PYRO_APPROVED_CONTAINER = 'TEXT_PYRO_APPROVED_CONTAINER'
export const TEXT_PYRO_OWN_WAREHOUSE = 'TEXT_PYRO_OWN_WAREHOUSE'
export const TEXT_PYRO_SALE_6 = 'TEXT_PYRO_SALE_6'
export const TEXT_PYRO_SALE_7 = 'TEXT_PYRO_SALE_7'
export const TEXT_SKETCH_OF_CONTAINER_LOCATION = 'TEXT_SKETCH_OF_CONTAINER_LOCATION'
export const TEXT_PYRO_COMPANY_TEST = 'TEXT_PYRO_COMPANY_TEST'
export const TEXT_HEREBY_CONFIRMED = 'TEXT_HEREBY_CONFIRMED'
export const TEXT_DATE = 'TEXT_DATE'
export const TEXT_LAST_INTERNAL_CONTROL_DATE = 'TEXT_LAST_INTERNAL_CONTROL_DATE'
export const TEXT_PYRO_SALE_8 = 'TEXT_PYRO_SALE_8'
export const TEXT_PYRO_SALE_9 = 'TEXT_PYRO_SALE_9'
export const TEXT_PYRO_SALE_10 = 'TEXT_PYRO_SALE_10'
export const TEXT_PYRO_SALE_11 = 'TEXT_PYRO_SALE_11'
export const TEXT_PYRO_SALE_12 = 'TEXT_PYRO_SALE_12'
export const TEXT_PYRO_SALE_13 = 'TEXT_PYRO_SALE_13'
export const TEXT_PYRO_SALE_14 = 'TEXT_PYRO_SALE_14'
export const TEXT_PYRO_SALE_15 = 'TEXT_PYRO_SALE_15'

export const TEXT_PYRO_USE_PLACE = 'TEXT_PYRO_USE_PLACE'

export const TEXT_WE_WILL_CONTACT_YOU = 'TEXT_WE_WILL_CONTACT_YOU'
export const TEXT_WE_WILL_CONTACT_YOU_INSTALLATION = 'TEXT_WE_WILL_CONTACT_YOU_INSTALLATION'

export const TEXT_APPLICANT = 'TEXT_APPLICANT'
export const TEXT_CONSTRUCTION_SITE = 'TEXT_CONSTRUCTION_SITE'
export const TEXT_APPLICATION_FOR_CHIMNEY_CONSTRUCTION = 'TEXT_APPLICATION_FOR_CHIMNEY_CONSTRUCTION'
export const TEXT_LIABILITY_DOCUMENTATION = 'TEXT_LIABILITY_DOCUMENTATION'

export const TEXT_CASE_NUMBER = 'TEXT_CASE_NUMBER'
export const TEXT_HAVE_CASE_NUMBER = 'TEXT_HAVE_CASE_NUMBER'
export const TEXT_FIREPLACE_ALREADY_REPROTED = 'TEXT_FIREPLACE_ALREADY_REPROTED'
export const TEXT_INSTALLED_FIREPLACE = 'TEXT_INSTALLED_FIREPLACE'
export const TEXT_OTHER_FIREPLACE_UNCERTAIN = 'TEXT_OTHER_FIREPLACE_UNCERTAIN'
export const TEXT_CLEAN_BURNING = 'TEXT_CLEAN_BURNING'
export const TEXT_NON_CLEAN_BURNING = 'TEXT_NON_CLEAN_BURNING'

export const TEXT_LOGIN_WITH_ID_PORTEN = 'TEXT_LOGIN_WITH_ID_PORTEN'
export const TEXT_AUTHENTICATION_REQUIRED = 'TEXT_AUTHENTICATION_REQUIRED'
export const TEXT_PERSONAL_NR = 'TEXT_PERSONAL_NR'
export const TEXT_USER_NOT_FOUND = 'TEXT_USER_NOT_FOUND'
export const TEXT_LOGGED_OUT = 'TEXT_LOGGED_OUT'

export const TEXT_JOB_APPLIES = 'TEXT_JOB_APPLIES'
export const TEXT_REHABILITATION_OF_EXISTING_CHIMNEY = 'TEXT_REHABILITATION_OF_EXISTING_CHIMNEY'
export const TEXT_REHABILITATION_CONSTRUCTION_OF_CHIMNEY = 'TEXT_REHABILITATION_CONSTRUCTION_OF_CHIMNEY'

export const TEXT_TANK_REMOVAL_EXEMPTION_APPROVED_ON = 'TEXT_TANK_REMOVAL_EXEMPTION_APPROVED_ON'
export const TEXT_SEARCH_BY_ADDRESS_GNR_BNR = 'TEXT_SEARCH_BY_ADDRESS_GNR_BNR'

export const TEXT_ROOF_TILE = 'TEXT_ROOF_TILE'
export const TEXT_ELEMENT = 'TEXT_ELEMENT'
export const TEXT_STEEL = 'TEXT_STEEL'

export const TEXT_CHIMNEY_BEFORE_REHAB = 'TEXT_CHIMNEY_BEFORE_REHAB'
export const TEXT_CHIMNEY_AFTER_REHAB = 'TEXT_CHIMNEY_AFTER_REHAB'

export const TEXT_NUMBER_OF_RACES = 'TEXT_NUMBER_OF_RACES'
export const TEXT_SINGLE_RACES = 'TEXT_SINGE_RACES'
export const TEXT_DOUBLE_RACES = 'TEXT_DOUBLE_RACES'

export const TEXT_CROSS_SECTION = 'TEXT_CROSS_SECTION'
export const TEXT_CROSS_SECTION_CM = 'TEXT_CROSS_SECTION_CM'
export const TEXT_OLD_CROSS_SECTION_CM = 'TEXT_OLD_CROSS_SECTION_CM'
export const TEXT_NEW_CROSS_SECTION_CM = 'TEXT_NEW_CROSS_SECTION_CM'
export const TEXT_NUMBER_OF_RUNNING_METERS = 'TEXT_NUMBER_OF_RUNNING_METERS'
export const TEXT_BY_THE_METER = 'TEXT_BY_THE_METER'
export const TEXT_PLACEMENT = 'TEXT_PLACEMENT'
export const TEXT_FOR_EXAMPLE_RIGHT_LEFT_NORTH_SOUTH = 'TEXT_FOR_EXAMPLE_RIGHT_LEFT_NORTH_SOUTH'
export const TEXT_TECHNICAL_APPROVAL_NUMBER_CE_MARKING = 'TEXT_TECHNICAL_APPROVAL_NUMBER_CE_MARKING'
export const TEXT_APPROVAL = 'TEXT_APPROVAL'
export const TEXT_TERMS_OF_INSTALLATION = 'TEXT_TERMS_OF_INSTALLATION'
export const TEXT_CM_10_DISTANCE = 'TEXT_CM_10_DISTANCE'
export const TEXT_DIRECTLY_THROUGH_FLOOR_DIVIDERS = 'TEXT_DIRECTLY_THROUGH_FLOOR_DIVIDERS'
export const TEXT_DIRECTLY_ON_COMBUSTIBLE_WALL = 'TEXT_DIRECTLY_ON_COMBUSTIBLE_WALL'

export const TEXT_HOME_OWNER_CONTACT_PERSON = 'TEXT_HOME_OWNER_CONTACT_PERSON'
export const TEXT_WHICH_CHIMNEY_REHAB = 'TEXT_WHICH_CHIMNEY_REHAB'
export const TEXT_NEWLY_CONSTRUCTED_CHIMNEY = 'TEXT_NEWLY_CONSTRUCTED_CHIMNEY'

export const TEXT_TARGETED_SKETCH = 'TEXT_TARGETED_SKETCH'
export const TEXT_MOUNTING_DESCRIPTION = 'TEXT_MOUNTING_DESCRIPTION'
export const TEXT_NOTIFICATION_TO_NEIGHBORS = 'TEXT_NOTIFICATION_TO_NEIGHBORS'
export const TEXT_CENTRAL_APPROVAL = 'TEXT_CENTRAL_APPROVAL'
export const TEXT_CENTRAL_APPROVAL_MUST_BE_UPLOADED = 'TEXT_CENTRAL_APPROVAL_MUST_BE_UPLOADED'
export const TEXT_CENTRAL_APPROVAL_UPLOADS = 'TEXT_CENTRAL_APPROVAL_UPLOADS'
export const TEXT_IS_APPLICANT_OWNER = 'TEXT_IS_APPLICANT_OWNER'

export const TEXT_STEP = 'TEXT_STEP'

export const ERROR_ONLY_ONE_APPLICATION_PER_HOUSING_UNIT = 'ERROR: Only one application per housing unit'
export const ERROR_NO_HOUSING_UNITS = 'ERROR_NO_HOUSING_UNITS'
export const TEXT_FUEL_TYPE_SOLID = 'TEXT_FUEL_TYPE_SOLID'
export const TEXT_FUEL_TYPE_COMBO = 'TEXT_FUEL_TYPE_COMBO'
export const TEXT_FUEL_TYPE_BIO_OIL = 'TEXT_FUEL_TYPE_BIO_OIL'

export const TEXT_CONCERN_CASE = 'TEXT_CONCERN_CASE'
export const TEXT_SENDER = 'TEXT_SENDER'
export const TEXT_SEND_AS_ANONYMOUS = 'TEXT_SEND_AS_ANONYMOUS'
export const TEXT_FILES = 'TEXT_FILES'
export const TEXT_CONCERN_DESCRIPTION = 'TEXT_CONCERN_DESCRIPTION'
export const TEXT_SEARCH_ORG_NR = 'TEXT_SEARCH_ORG_NR'

//#endregion FIRE
