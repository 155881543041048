import React from 'react'
import { Typography, styled } from '@mui/material'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
}))

const Heading = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black,
    padding: theme.spacing(2, 5),
    [theme.breakpoints.down('md')]: {
        padding: theme.spacing(1.5, 3),
    },
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 2),
    },
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        opacity: 0.8,
    },
}))

export const ContentLink: React.FC<{ to: string }> = (props) => {
    const { to, children } = props

    return (
        <StyledLink to={to}>
            <Heading variant="h5">{children}</Heading>
        </StyledLink>
    )
}
