import React, { useEffect } from 'react'
import { reduxForm, formValueSelector, InjectedFormProps } from 'redux-form'
import { tr } from 'translations/translate'
import {
    TEXT_WHAT_IS_THE_ADDRESS,
    TEXT_TANK_REMEDIATION_DESCRIPTION,
    TEXT_TANK_REMEDIATION_DOCUMENTATION,
    TEXT_NO_REGISTERED_TANKS,
    TEXT_TANK_REMOVAL_EXEMPTION_APPROVED_ON,
} from 'translations/keys'
import { Grid } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'app/app-store'
import { find, filter } from 'lodash'
import { HousingUnit } from 'tax/types/housing-unit.type'
import { HousingUnitSelect } from 'fire/form-components/housing-unit-select'
import { FormFieldName } from '../form-field-name.enum'
import { HousingUnitTankSelect } from 'fire/form-components/housing-unit-tank-select'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'
import { CubitTextarea } from 'common/cubit-textarea/cubit-textarea'
import { TankRemediationFormFieldName } from './tank-remediation-form-field-name.enum'
import { TankStatus } from 'fire/enums/tank-status.enum'
import { FireFormName } from 'fire/enums/form-name.enum'
import { actionFireGetCadastreUnit } from 'fire/fire-actions'
import { useFireCadastreUnit } from 'fire/fire-selectors'
import { OwnerSelect } from '../../form-components/owner-select'
import { FireplaceInstallationFormFieldName } from '../fireplace-installation/fireplace-installation-form-field-name.enum'
import { Tank } from '../../types/tank.type'
import { DateTime } from 'luxon'
import { CaseNumberSelect } from '../../form-components/case-number-select'
import { IS_APPLICANT_OWNER, IsApplicantOwnerSelect } from '../../form-components/applicant-select'
import { CompanyContactSelect } from '../../form-components/company-contact-select'
import { GridContainerOverride } from 'app/app'

const Form: React.FC<InjectedFormProps> = (props) => {
    const { handleSubmit } = props
    const dispatch = useDispatch()

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.TankRemediation)
    const ownerId = selector(state, TankRemediationFormFieldName.OwnerId)

    useEffect(() => {
        ownerId && dispatch(actionFireGetCadastreUnit(ownerId))
    }, [dispatch, ownerId])

    const cadastreUnit = useFireCadastreUnit()
    const isApplicantOwner = selector(state, IS_APPLICANT_OWNER)
    const housingUnits = cadastreUnit?.housingUnits ?? []
    const tanks = cadastreUnit?.tanks ?? []
    const tanksApprovedForRemediation = tanks
        ? filter(tanks, (t) => t.status === TankStatus.ApprovedForRemediation)
        : []

    const selectedHousingUnitId: any = selector(state, FormFieldName.HousingUnitId)
    const selectedTankId: any = selector(state, TankRemediationFormFieldName.TankId)
    const selectedHousingUnit: HousingUnit | undefined = find(housingUnits, { id: selectedHousingUnitId })
    const selectedTank: Tank | undefined = find(tanksApprovedForRemediation, { id: selectedTankId })
    return (
        <form onSubmit={handleSubmit}>
            <CaseNumberSelect />
            <IsApplicantOwnerSelect />
            {isApplicantOwner === false && <CompanyContactSelect />}
            <OwnerSelect
                showSearch={isApplicantOwner === false}
                name={FireplaceInstallationFormFieldName.OwnerId}
                formName={FireFormName.TankRemediation}
            />

            <Grid container spacing={4} style={GridContainerOverride}>
                <Grid item xs={12} sm={12} md={6}>
                    <HousingUnitSelect
                        disabled={!cadastreUnit || !housingUnits.length || !tanks.length}
                        housingUnits={housingUnits}
                        label={tr(TEXT_WHAT_IS_THE_ADDRESS)}
                    />
                </Grid>

                {selectedHousingUnit && tanks.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <HousingUnitTankSelect
                                housingUnit={selectedHousingUnit}
                                name={TankRemediationFormFieldName.TankId}
                                tanks={tanksApprovedForRemediation}
                            />
                        </Grid>

                        {selectedTank && selectedTank.statusDate && (
                            <Grid item xs={12}>
                                {`${tr(TEXT_TANK_REMOVAL_EXEMPTION_APPROVED_ON)} ${DateTime.fromISO(
                                    selectedTank.statusDate.toString(),
                                ).toFormat('dd.MM.yyyy')}`}
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <CubitTextarea
                                name={TankRemediationFormFieldName.RemediationDescription}
                                label={tr(TEXT_TANK_REMEDIATION_DESCRIPTION)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {tr(TEXT_TANK_REMEDIATION_DOCUMENTATION)}
                        </Grid>

                        <Grid item xs={12}>
                            <CubitFileUpload name={TankRemediationFormFieldName.RemediationDocumentation} />
                        </Grid>
                    </>
                )}
            </Grid>
            {!!housingUnits.length && !tanksApprovedForRemediation?.length && (
                <Grid container spacing={4} style={GridContainerOverride}>
                    <Grid item xs={12}>
                        {tr(TEXT_NO_REGISTERED_TANKS)}
                    </Grid>
                </Grid>
            )}
        </form>
    )
}

export const TankRemediationForm = reduxForm({
    form: FireFormName.TankRemediation,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
