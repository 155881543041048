import React, { useState } from 'react'
import { Grid, styled } from '@mui/material'
import { CompanyAutocomplete } from './company-autocomplete'
import { Company } from '../../tax/types/company.type'
import { Field } from 'redux-form'
import { tr } from '../../translations/translate'
import { TEXT_ADDRESS, TEXT_CONTACT_EMAIL, TEXT_CONTACT_PHONE, TEXT_NAME, TEXT_ZIP_CODE } from '../../translations/keys'
import { CubitTextFieldAdapter } from '../../common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { ValidatorRequired } from '../../utils/validators/validator-required'
import { CubitHiddenFieldAdapter } from '../../common/cubit-inputs/react-form-adapters/cubit-hidden-field-adapter'

export const COMPANY_ORG_NR = 'companyOrgNr'
export const COMPANY_EMAIL = 'companyEmail'
export const COMPANY_NAME = 'companyName'
export const COMPANY_PHONE = 'companyPhone'

const Wrapper = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
}))
const CompanyInfoLabel = styled('div')(({ theme }) => ({
    fontSize: 16,
    marginBottom: theme.spacing(1),
}))
const CompanyInfoValue = styled('div')(({ theme }) => ({
    fontSize: 18,
}))
const CompanyInfo: React.FC<{ company: Company }> = ({ company }) => {
    const address = company.postalAddress.addressText ? company.postalAddress : company.visitingAddress
    //
    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={4} md={4}>
                    <Field name={COMPANY_NAME} inputValue={company.name} component={CubitHiddenFieldAdapter} />
                    <CompanyInfoLabel>{tr(TEXT_NAME)}</CompanyInfoLabel>
                    <CompanyInfoValue>{company.name}</CompanyInfoValue>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <CompanyInfoLabel>{tr(TEXT_ADDRESS)}</CompanyInfoLabel>
                    <CompanyInfoValue>{address.addressText}</CompanyInfoValue>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <CompanyInfoLabel>{tr(TEXT_ZIP_CODE)}</CompanyInfoLabel>
                    <CompanyInfoValue>{address.postalCode}</CompanyInfoValue>
                </Grid>
            </Grid>
        </div>
    )
}
export const CompanyContactSelect: React.FC = () => {
    //const dispatch = useDispatch()
    const [company, setCompany] = useState<Company | null>(null)
    const handleCompanyChange = (company: Company) => {
        setCompany(company)
    }

    return (
        <Wrapper>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6}>
                    <CompanyAutocomplete
                        validate={ValidatorRequired}
                        isNational={true}
                        name={COMPANY_ORG_NR}
                        valueChanged={handleCompanyChange}
                        valueSetter={(c) => c.organizationNumber}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {company && <CompanyInfo company={company} />}
                </Grid>
            </Grid>
            {company && (
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={12} md={6}>
                        <Field
                            name={COMPANY_EMAIL}
                            label={tr(TEXT_CONTACT_EMAIL)}
                            component={CubitTextFieldAdapter}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                            validate={ValidatorRequired}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <Field
                            name={COMPANY_PHONE}
                            label={tr(TEXT_CONTACT_PHONE)}
                            component={CubitTextFieldAdapter}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="filled"
                            validate={ValidatorRequired}
                        />
                    </Grid>
                </Grid>
            )}
        </Wrapper>
    )
}
