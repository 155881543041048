import React, { useEffect } from 'react'
import { change, Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'
import { tr } from 'translations/translate'
import {
    TEXT_ACCOUNT_NUMBER,
    TEXT_CONTACT_PERSON,
    TEXT_DESCRIPTION,
    TEXT_EMAIL,
    TEXT_INSTALLATION_LOCATION,
    TEXT_NAME,
    TEXT_NO,
    TEXT_OTHER_FIREPLACE_UNCERTAIN,
    TEXT_PAYOUT_ACCOUNT,
    TEXT_PERSONAL_NR,
    TEXT_WHICH_ADDRESS_APPLIES_TO_THE_INSTALLATION_SITE,
    TEXT_WHICH_FIREPLACE_APPLIES_TO_WRECKAGE,
    TEXT_YES,
} from 'translations/keys'
import { Grid } from '@mui/material'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'app/app-store'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { find } from 'lodash'
import { HousingUnit } from 'tax/types/housing-unit.type'
import { HousingUnitSelect } from 'fire/form-components/housing-unit-select'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { ScrapValueFormFieldName } from './scrap-value-form-field-name.enum'
import { ValidatorRequiredIfOthersEmpty } from 'utils/validators/validator-required-if-others-empty'
import { FormFieldName } from '../form-field-name.enum'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import { FireFormName } from 'fire/enums/form-name.enum'
import { actionFireGetCadastreUnit } from 'fire/fire-actions'
import { useFireCadastreUnit } from 'fire/fire-selectors'
import { DateTime } from 'luxon'
import { getChimneyLabel } from '../fireplace-installation/fireplace-installation-form-1'
import { OwnerSelect } from '../../form-components/owner-select'
import { FireplaceInstallationFormFieldName } from '../fireplace-installation/fireplace-installation-form-field-name.enum'
import { CaseNumberSelect } from '../../form-components/case-number-select'
import { GridContainerOverride } from 'app/app'

const validateRequiredIfFireplaceMissing = ValidatorRequiredIfOthersEmpty([
    ScrapValueFormFieldName.AssociatedFireplaceId,
])
const validateRequiredIfOtherFireplaceMissing = ValidatorRequiredIfOthersEmpty([ScrapValueFormFieldName.OtherFireplace])

const Form: React.FC<InjectedFormProps> = (props) => {
    const { handleSubmit } = props
    const dispatch = useDispatch()

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.ScrapValue)
    const ownerId = selector(state, ScrapValueFormFieldName.OwnerId)

    useEffect(() => {
        ownerId && dispatch(actionFireGetCadastreUnit(ownerId))
    }, [dispatch, ownerId])

    const cadastreUnit = useFireCadastreUnit()

    const housingUnits = (cadastreUnit && cadastreUnit.housingUnits) || []
    const owner = cadastreUnit && cadastreUnit.owner
    const selectedHousingUnitId: any = selector(state, FormFieldName.HousingUnitId)
    const otherFireplaceValue: any = selector(state, ScrapValueFormFieldName.OtherFireplace)

    const selectedHousingUnit: HousingUnit | undefined = find(housingUnits, { id: selectedHousingUnitId })

    const selectedHousingUnitFireplaceOptions =
        selectedHousingUnit &&
        selectedHousingUnit.fireplaces.map((f) => {
            return {
                label: (
                    <div>
                        {f.description && <div>Beskrivelse: {f.description}</div>}
                        {f.type && <div>Type: {f.type.indexOf('_') !== -1 ? tr(`TEXT_${f.type}`) : f.type}</div>}
                        {f.location && <div>Posisjon: {f.location}</div>}
                        {f.fuelType && <div>Type brensel: {f.fuelType}</div>}
                        <div>Rentbrennede: {f.cleanBurning ? tr(TEXT_YES) : tr(TEXT_NO)}</div>

                        {f.installed && (
                            <div>Installert: {DateTime.fromISO(f.installed.toString()).toFormat('dd.MM.yyyy')}</div>
                        )}
                        {f.chimneyId && (
                            <div>
                                {getChimneyLabel(
                                    selectedHousingUnit,
                                    find(cadastreUnit?.chimneys, { id: f.chimneyId }),
                                    false,
                                )}
                            </div>
                        )}
                    </div>
                ),
                value: f.id,
            }
        })

    const handleFireplaceChange = (v: any) => {
        if (v !== undefined) {
            dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.OtherFireplaceDescription, undefined))
            dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.OtherFireplace, undefined))
        }
    }

    const handleOtherFireplaceChange = (v: any) => {
        if (v !== undefined) {
            dispatch(change(FireFormName.ScrapValue, ScrapValueFormFieldName.AssociatedFireplaceId, undefined))
        }
    }

    return (
        <form onSubmit={handleSubmit} autoComplete="off">
            <CaseNumberSelect />
            <OwnerSelect name={FireplaceInstallationFormFieldName.OwnerId} formName={FireFormName.ScrapValue} />

            <Grid container spacing={4} style={GridContainerOverride}>
                <Grid item xs={12} sm={12} md={6}>
                    <Field
                        name={ScrapValueFormFieldName.AccountNumber}
                        label={tr(TEXT_ACCOUNT_NUMBER)}
                        placeholder={tr(TEXT_PAYOUT_ACCOUNT)}
                        component={CubitTextFieldAdapter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                        validate={ValidatorRequired}
                    />
                </Grid>
            </Grid>
            {owner && owner.type === 'CompanyOwner' && (
                <>
                    <br />
                    <ContentSectionHeading>{tr(TEXT_CONTACT_PERSON)}</ContentSectionHeading>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={ScrapValueFormFieldName.ContactName}
                                label={tr(TEXT_NAME)}
                                placeholder={tr(TEXT_NAME)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={ValidatorRequired}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Field
                                name={ScrapValueFormFieldName.ContactNumber}
                                label={tr(TEXT_PERSONAL_NR)}
                                placeholder={tr(TEXT_PERSONAL_NR)}
                                component={CubitTextFieldAdapter}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="filled"
                                validate={ValidatorRequired}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
            <Grid container spacing={4} style={GridContainerOverride}>
                <Grid item xs={12} sm={12} md={6}>
                    <Field
                        name={ScrapValueFormFieldName.ContactEmail}
                        label={tr(TEXT_EMAIL)}
                        placeholder={tr(TEXT_EMAIL)}
                        component={CubitTextFieldAdapter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                    />
                </Grid>
            </Grid>

            <br />

            <ContentSectionHeading>{tr(TEXT_INSTALLATION_LOCATION)}</ContentSectionHeading>

            <Grid container spacing={4} style={GridContainerOverride}>
                <Grid item xs={12} sm={12} md={6}>
                    <HousingUnitSelect
                        disabled={!cadastreUnit || !housingUnits.length}
                        housingUnits={housingUnits}
                        formName={FireFormName.ScrapValue}
                        label={tr(TEXT_WHICH_ADDRESS_APPLIES_TO_THE_INSTALLATION_SITE)}
                    />
                </Grid>

                {selectedHousingUnit && (
                    <>
                        <Grid item xs={12}>
                            <label>{tr(TEXT_WHICH_FIREPLACE_APPLIES_TO_WRECKAGE)}</label>

                            <Field
                                name={ScrapValueFormFieldName.AssociatedFireplaceId}
                                component={CubitRadioGroupAdapter}
                                options={selectedHousingUnitFireplaceOptions}
                                onChange={handleFireplaceChange}
                                validate={validateRequiredIfOtherFireplaceMissing}
                            />

                            <Field
                                name={ScrapValueFormFieldName.OtherFireplace}
                                component={CubitRadioGroupAdapter}
                                options={[
                                    {
                                        label: tr(TEXT_OTHER_FIREPLACE_UNCERTAIN),
                                        value: 'true',
                                    },
                                ]}
                                onChange={handleOtherFireplaceChange}
                                validate={validateRequiredIfFireplaceMissing}
                            />

                            {otherFireplaceValue && (
                                <Field
                                    name={ScrapValueFormFieldName.OtherFireplaceDescription}
                                    label={tr(TEXT_DESCRIPTION)}
                                    placeholder={tr(TEXT_DESCRIPTION)}
                                    component={CubitTextFieldAdapter}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="filled"
                                    validate={ValidatorRequired}
                                />
                            )}
                        </Grid>
                    </>
                )}
            </Grid>
        </form>
    )
}

export const ScrapValueForm1 = reduxForm({
    form: FireFormName.ScrapValue,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
