import React from 'react'
import { change, Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { FireFormName } from 'fire/enums/form-name.enum'
import { ScrapValueFormFieldName } from './scrap-value-form-field-name.enum'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { HeatingSource } from './heating-source.enum'
import { tr } from 'translations/translate'
import {
    TEXT_ADDRESS,
    TEXT_BUSINESS,
    TEXT_CHECKLIST_AND_VERIFICATION_STATEMENT_BY_QUALIFIED_INSPECTOR,
    TEXT_COMMENT,
    TEXT_DOCUMENTATION,
    TEXT_INSTALLER,
    TEXT_MOBILE_NUMBER,
    TEXT_NAME,
    TEXT_PROOF_OF_REFUND,
    TEXT_ZIP_CODE,
} from 'translations/keys'
import { CubitTextFieldAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { AppState } from 'app/app-store'
import { ContentSectionHeading } from 'common/content-section-heading/content-section-heading'
import { CubitFileUpload } from 'common/cubit-file-upload/cubit-file-upload'
import { CompanyAutocomplete } from '../../form-components/company-autocomplete'
import { Company } from 'tax/types/company.type'
import { GridContainerOverride } from 'app/app'

const Form: React.FC<InjectedFormProps<any>> = (props) => {
    const { handleSubmit } = props
    const dispatch = useDispatch()
    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.ScrapValue)
    const heatingSource = selector(state, ScrapValueFormFieldName.HeatingSource)

    const cleanBurningFireplaceSelected = heatingSource === HeatingSource.CleanBurningFireplace

    const handleCompanyChange = (company: Company) => {
        dispatch(
            change(
                FireFormName.ScrapValue,
                ScrapValueFormFieldName.InstallerNameOrCompanyName,
                company ? company.name : '',
            ),
        )
        dispatch(
            change(FireFormName.ScrapValue, ScrapValueFormFieldName.InstallerPhoneNumber, company ? company.phone : ''),
        )
        dispatch(
            change(
                FireFormName.ScrapValue,
                ScrapValueFormFieldName.InstallerAddress,
                company ? company.postalAddress.addressText ?? company.visitingAddress.addressText : '',
            ),
        )
        dispatch(
            change(
                FireFormName.ScrapValue,
                ScrapValueFormFieldName.InstallerZipCode,
                company ? company.postalAddress.postalCode ?? company.visitingAddress.postalCode : '',
            ),
        )
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                {cleanBurningFireplaceSelected && (
                    <ContentSectionHeading tabIndex={0}>abc {tr(TEXT_INSTALLER)}</ContentSectionHeading>
                )}
                {cleanBurningFireplaceSelected && (
                    <Grid container spacing={4} style={GridContainerOverride}>
                        <Grid item xs={12} sm={12} md={6}>
                            <CompanyAutocomplete
                                isNational={true}
                                name={ScrapValueFormFieldName.CompanyId}
                                valueChanged={handleCompanyChange}
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                )}
                {cleanBurningFireplaceSelected && (
                    <Grid container spacing={4} style={GridContainerOverride} alignItems="center">
                        <>
                            <Grid item xs={12} sm={12} md={6}>
                                <Grid container spacing={4} alignItems="center">
                                    <Grid item xs={12}>
                                        <Field
                                            name={ScrapValueFormFieldName.InstallerNameOrCompanyName}
                                            label={`${tr(TEXT_NAME)} / ${tr(TEXT_BUSINESS)}`}
                                            component={CubitTextFieldAdapter}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            validate={ValidatorRequired}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name={ScrapValueFormFieldName.InstallerPhoneNumber}
                                            label={tr(TEXT_MOBILE_NUMBER)}
                                            component={CubitTextFieldAdapter}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name={ScrapValueFormFieldName.InstallerAddress}
                                            label={tr(TEXT_ADDRESS)}
                                            component={CubitTextFieldAdapter}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            validate={ValidatorRequired}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name={ScrapValueFormFieldName.InstallerZipCode}
                                            label={tr(TEXT_ZIP_CODE)}
                                            component={CubitTextFieldAdapter}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="filled"
                                            validate={ValidatorRequired}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    </Grid>
                )}

                <ContentSectionHeading>{tr(TEXT_DOCUMENTATION)}</ContentSectionHeading>

                <Grid container spacing={4} style={GridContainerOverride} alignItems="center">
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <label>{tr(TEXT_PROOF_OF_REFUND)}</label>
                            </Grid>
                            <Grid item xs={12}>
                                <CubitFileUpload name={ScrapValueFormFieldName.ProofOfRefundFiles} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <label>{tr(TEXT_CHECKLIST_AND_VERIFICATION_STATEMENT_BY_QUALIFIED_INSPECTOR)}</label>
                            </Grid>
                            <Grid item xs={12}>
                                <CubitFileUpload name={ScrapValueFormFieldName.InspectorVerificationFiles} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={4} style={GridContainerOverride} alignItems="center">
                    <Grid item xs={12}>
                        <Field
                            name={ScrapValueFormFieldName.Comment}
                            label={tr(TEXT_COMMENT)}
                            component={CubitTextFieldAdapter}
                            variant="filled"
                        />
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export const ScrapValueForm3 = reduxForm({
    form: FireFormName.ScrapValue,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
