import React from 'react'
import { tr } from 'translations/translate'
import { TEXT_THANK_YOU_FOR_USING_OUR_SERVICES, TEXT_APPLICATION_RECEIVED } from 'translations/keys'
import { Grid, styled } from '@mui/material'

const Heading = styled('h1')(({ theme }) => ({
    fontSize: theme.typography.h4.fontSize,
    padding: theme.spacing(4, 2),
    letterSpacing: '1.1px',
    fontWeight: 300,
}))

const TextItem = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(1, 2),
}))

const ThankYouText = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(4, 2),
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 300,
}))

export const FireFormSubmitSuccess: React.FC<{ title?: string; content?: string[] }> = (props) => {
    const { title, content, children } = props
    return (
        <Grid container alignItems="center" direction="column">
            <Grid item>
                <Heading role="alert">{title || tr(TEXT_APPLICATION_RECEIVED)}</Heading>
            </Grid>

            {content?.map((c, i) => (
                <TextItem key={i} item>
                    {c}
                </TextItem>
            ))}

            {children && <TextItem item>{children}</TextItem>}

            <ThankYouText item>{tr(TEXT_THANK_YOU_FOR_USING_OUR_SERVICES)}</ThankYouText>
        </Grid>
    )
}
