import React, { useEffect } from 'react'
import { destroy } from 'redux-form'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../../app/app-store'
import { actionLoadProject } from '../../other-actions'
import { OtherFormName } from '../../enums/form-name.enum'
import { useParams } from 'react-router-dom'
import { CircularProgress, styled } from '@mui/material'
import { ContentHeading } from '../../../common/content-heading/content-heading'
import { ContentDivider } from '../../../common/content-divider/content-divider'
interface RouteParams {
    tenantId: string
    projectId: string
}
type Props = {}
const Header = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
}))
export const ProjectInfo: React.FC<Props> = (props) => {
    const project = useSelector((state: AppState) => state.other.project)
    const dispatch = useDispatch()
    const { tenantId, projectId } = useParams<RouteParams>()
    useEffect(() => {
        if (tenantId && projectId) {
            dispatch(actionLoadProject(tenantId, projectId))
        }
        dispatch(destroy(OtherFormName.ProjectForm))
    }, [])
    if (!project) {
        return (
            <ContentHeading>
                <CircularProgress color={'primary'} />
            </ContentHeading>
        )
    }
    return (
        <div>
            <ContentHeading>
                <Header>
                    <span>{project.name}</span>
                </Header>
            </ContentHeading>
            <ContentDivider />
        </div>
    )
}
