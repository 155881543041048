import React from 'react'
import { ExtendButtonBase } from '@mui/material/ButtonBase'
import Button, { ButtonTypeMap } from '@mui/material/Button'

export const ButtonOutlined: ExtendButtonBase<ButtonTypeMap> = (props: any) => {
    const { children, className, ...rest } = props

    const defaultClassNameText = 'noRadius  noShadow'
    const classNameText = className ? `${defaultClassNameText}  ${className}` : defaultClassNameText

    return (
        <Button variant="outlined" className={classNameText} {...rest}>
            {children}
        </Button>
    )
}
