import React, { useEffect } from 'react'
import { reduxForm, Field, formValueSelector, InjectedFormProps } from 'redux-form'
import { tr } from 'translations/translate'
import {
    TEXT_WE_HAVE_FOLLOWING_FIREPLACES,
    TEXT_WHICH_FIREPLACE_IS_REMOVED,
    TEXT_ADDRESS_WHERE_FIREPLACE_IS_REMOVED,
    TEXT_YES,
    TEXT_NO,
    TEXT_EMAIL,
} from 'translations/keys'
import { Grid, Typography } from '@mui/material'
import { ValidatorRequired } from 'utils/validators/validator-required'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'app/app-store'
import { CubitRadioGroupAdapter } from 'common/cubit-inputs/react-form-adapters/cubit-radio-group-adapter'
import { find } from 'lodash'
import { HousingUnit } from 'tax/types/housing-unit.type'
import { HousingUnitSelect } from 'fire/form-components/housing-unit-select'
import { FormFieldName } from '../form-field-name.enum'
import { FireplaceRemovalFormFieldName } from './fireplace-removal-form-field-name.enum'
import { FireFormName } from 'fire/enums/form-name.enum'
import { actionFireGetCadastreUnit } from 'fire/fire-actions'
import { useFireCadastreUnit } from 'fire/fire-selectors'
import { DateTime } from 'luxon'
import { OwnerSelect } from '../../form-components/owner-select'
import { FireplaceInstallationFormFieldName } from '../fireplace-installation/fireplace-installation-form-field-name.enum'
import { getChimneyLabel } from '../fireplace-installation/fireplace-installation-form-1'
import { CaseNumberSelect } from '../../form-components/case-number-select'
import { ScrapValueFormFieldName } from '../scrap-value/scrap-value-form-field-name.enum'
import { CubitTextFieldAdapter } from '../../../common/cubit-inputs/react-form-adapters/cubit-text-field-adapter'
import { GridContainerOverride } from 'app/app'

const Form: React.FC<InjectedFormProps> = (props) => {
    const { handleSubmit } = props
    const dispatch = useDispatch()

    const state = useSelector((state: AppState) => state)
    const selector = formValueSelector(FireFormName.FireplaceRemoval)
    const ownerId = selector(state, FireplaceRemovalFormFieldName.OwnerId)

    useEffect(() => {
        ownerId && dispatch(actionFireGetCadastreUnit(ownerId))
    }, [dispatch, ownerId])

    const cadastreUnit = useFireCadastreUnit()

    const housingUnits = (cadastreUnit && cadastreUnit.housingUnits) || []

    const selectedHousingUnitId: any = selector(state, FormFieldName.HousingUnitId)
    const selectedHousingUnit: HousingUnit | undefined = find(housingUnits, { id: selectedHousingUnitId })

    const selectedHousingUnitFireplaceOptions =
        selectedHousingUnit &&
        selectedHousingUnit.fireplaces.map((f) => {
            return {
                label: (
                    <div>
                        {f.description && <div>Beskrivelse: {f.description}</div>}
                        {f.type && <div>Type: {f.type.indexOf('_') !== -1 ? tr(`TEXT_${f.type}`) : f.type}</div>}
                        {f.location && <div>Posisjon: {f.location}</div>}
                        {f.fuelType && <div>Type brensel: {f.fuelType}</div>}
                        <div>Rentbrennede: {f.cleanBurning ? tr(TEXT_YES) : tr(TEXT_NO)}</div>

                        {f.installed && (
                            <div>Installert: {DateTime.fromISO(f.installed.toString()).toFormat('dd.MM.yyyy')}</div>
                        )}
                        {f.chimneyId && (
                            <div>
                                {getChimneyLabel(
                                    selectedHousingUnit,
                                    find(cadastreUnit?.chimneys, { id: f.chimneyId }),
                                    false,
                                )}
                            </div>
                        )}
                    </div>
                ),
                value: f.id,
            }
        })

    return (
        <form onSubmit={handleSubmit}>
            <CaseNumberSelect />
            <OwnerSelect name={FireplaceInstallationFormFieldName.OwnerId} formName={FireFormName.FireplaceRemoval} />
            <Grid container spacing={4} style={GridContainerOverride}>
                <Grid item xs={12} sm={12} md={6}>
                    <Field
                        name={FormFieldName.ContactEmail}
                        label={tr(TEXT_EMAIL)}
                        placeholder={tr(TEXT_EMAIL)}
                        component={CubitTextFieldAdapter}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="filled"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={4} style={GridContainerOverride}>
                <Grid item xs={12} sm={12} md={6}>
                    <HousingUnitSelect
                        disabled={!cadastreUnit || !housingUnits.length}
                        housingUnits={housingUnits}
                        formName={FireFormName.FireplaceRemoval}
                        label={tr(TEXT_ADDRESS_WHERE_FIREPLACE_IS_REMOVED)}
                    />
                </Grid>

                {selectedHousingUnit && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1">{tr(TEXT_WE_HAVE_FOLLOWING_FIREPLACES)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <label>{tr(TEXT_WHICH_FIREPLACE_IS_REMOVED)}</label>

                            <Field
                                name={FireplaceRemovalFormFieldName.FireplaceId}
                                component={CubitRadioGroupAdapter}
                                options={selectedHousingUnitFireplaceOptions}
                                validate={ValidatorRequired}
                                required
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </form>
    )
}

export const FireplaceRemovalForm = reduxForm({
    form: FireFormName.FireplaceRemoval,
    enableReinitialize: false,
    destroyOnUnmount: false,
})(Form)
