import React from 'react'
import { FireFormSubmitSuccess } from '../fire-form-submit-success/fire-form-submit-success'
import { tr } from 'translations/translate'
import {
    TEXT_MESSAGE_RECEIVED,
    TEXT_EVENT_SUCCESS_1,
    TEXT_APPLY_FOR_PYROTECHNICS,
    TEXT_APPLICATION_FOR_FIREWORKS_PYROTECHNICS,
} from 'translations/keys'
import { Link, useParams } from 'react-router-dom'
import { Grid } from '@mui/material'
import { EventPyrotechnics } from 'fire/enums/event-pyrotechnics.enum'
import { FireFormDescriptorsMap } from '../fire-form-descriptors-map'
import { FireFormName } from 'fire/enums/form-name.enum'
interface RouteParams {
    eventPyrotechnics: string
}
export const EventNotificationFormSubmitSuccess: React.FC = () => {
    const { eventPyrotechnics } = useParams<RouteParams>()
    const isPyrotechnicsUsed = eventPyrotechnics === EventPyrotechnics.PyrotechnicsUsed
    const pyrotechnicsUseRoute = FireFormDescriptorsMap[FireFormName.PyrotechnicsUse].route

    return (
        <FireFormSubmitSuccess title={tr(TEXT_MESSAGE_RECEIVED)} content={[tr(TEXT_EVENT_SUCCESS_1)]}>
            {isPyrotechnicsUsed && (
                <Grid container direction="column" alignItems="center">
                    <Grid item>{tr(TEXT_APPLY_FOR_PYROTECHNICS)}:</Grid>
                    <Grid item>
                        <Link to={pyrotechnicsUseRoute}>{tr(TEXT_APPLICATION_FOR_FIREWORKS_PYROTECHNICS)}</Link>
                    </Grid>
                </Grid>
            )}
        </FireFormSubmitSuccess>
    )
}
