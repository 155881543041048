import React, { useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { actionSetStateTenantId, actionStoreSessionKey } from 'app/app-actions'

type LoginPageRouteProps = { sessionKey: string; pathToProject: string; pathToForm: string; id?: string }
interface RouteParams {
    sessionKey: string
    pathToProject: string
    pathToForm: string
    tenantId: string
    id: string
}
export const LoginPage: React.FC = () => {
    const { sessionKey, pathToProject, pathToForm, tenantId, id } = useParams<RouteParams>()
    const dispatch = useDispatch()

    useEffect(() => {
        if (tenantId) {
            //in login we just set to state, while action inside the app will take it from there and do network call with epic
            dispatch(actionSetStateTenantId(encodeURIComponent(tenantId)))
        }
    }, [dispatch, tenantId])
    useEffect(() => {
        dispatch(actionStoreSessionKey(sessionKey ? encodeURIComponent(sessionKey) : ''))
    }, [dispatch, sessionKey])
    if (pathToProject === 'tax') {
        return <Redirect to={`/${pathToProject}/complaint/${pathToForm}`} />
    }
    if (pathToProject === 'project') {
        return <Redirect to={`/${pathToProject}/${tenantId}/${pathToForm}`} />
    }
    return <Redirect to={`/${pathToProject}/${pathToForm}/${id ? id : ''}`} />
}
